<template>
  <v-form :readonly="readonly">
    <tfoms-spacer title="ОБЩИЕ ДАННЫЕ ТФОМС ПО ОРГАНИЗАЦИИ"></tfoms-spacer>
    <v-row>
      <v-col cols="7">
        <tfoms-panel small>
          <tfoms-table :data="dataWithTotal"> </tfoms-table>
        </tfoms-panel>
      </v-col>
      <v-col cols="5">
        <tfoms-bar
          title="Принято к оплате"
          :data="percentFinances"
          icon="mdi-currency-rub"
          factColor="#0CA85D"
          :year="queryParams.params.year"
        ></tfoms-bar>

        <tfoms-finance
          class="mt-5"
          :data="data.map((e) => [e.SanctionValue, e.FineValue])"
          @clickMore="tabMore = 1"
          :subTitle="queryParams.params.year + ' год'"
        >
        </tfoms-finance>
        <tfoms-lost-income
          v-if="false"
          class="mt-5"
          :value="sum"
        ></tfoms-lost-income>
      </v-col>
    </v-row>
    <tfoms-spacer
      title="ДАННЫЕ В РАЗРЕЗЕ УСЛОВИЙ ОКАЗАНИЯ МЕДИЦИНСКОЙ ПОМОЩИ"
    ></tfoms-spacer>

    <div v-if="aggregatedInfo" class="d-flex" style="position: relative">
      <wrapper-loader
        v-if="loading || startLoading != endLoading"
      ></wrapper-loader>
      <tfoms-conditions
        v-if="dataSource.HealthCares"
        v-model="healthCare"
        :HealthCares="dataSource.HealthCares"
      ></tfoms-conditions>

      <v-row class="ml-5">
        <v-col>
          <tfoms-bar
            v-if="aggregatedInfo && aggregatedInfo.VolumeValues.length"
            title="ОБЪЕМЫ"
            :data="getValues(aggregatedInfo.VolumeValues)"
            icon="mdi-currency-rub"
            :values="[
              {
                name: 'План',
                value: aggregatedInfo.VolumeTotalPlanValue,
                label: this.provideUnits.unit.text,
              },
              {
                name: 'Факт',
                value: aggregatedInfo.VolumeTotalValue,
                label: this.provideUnits.unit.text,
              },
              {
                name: 'Выполнение плана',
                value: aggregatedInfo.VolumePercent + '%',
                type: 'percent',
              },
            ]"
            :labels="[queryParams.params.year + ' год', currentCare.Name]"
          ></tfoms-bar>
          <tfoms-finance
            title="КОЛИЧЕСТВО САНКЦИЙ"
            class="mt-5"
            :data="
              aggregatedInfo.SanctionCountValues.map((e) => [
                e.FineVolumeValue,
                e.NonPaymentVolumeValue,
              ])
            "
            :values="[
              {
                value: aggregatedInfo.SanctionVolumeCount,
                label: provideUnits.unit.text,
              },
            ]"
            :subTitle="queryParams.params.year + ' год'"
            :labels="[currentCare.Name]"
          ></tfoms-finance>
        </v-col>
        <v-col>
          <tfoms-bar
            title="ФИНАНСОВОЕ ОБЕСПЕЧЕНИЕ"
            :data="
              getValues(
                aggregatedInfo.FinanceValues,
                'FinancialPlanValue',
                'FinancialValue'
              )
            "
            :values="[
              {
                name: 'План',
                value: aggregatedInfo.FinanceTotalPlanValue,
                label: this.provideUnits.rub.text,
              },
              {
                name: 'Факт',
                value: aggregatedInfo.FinanceTotalValue,
                label: this.provideUnits.rub.text,
              },
              {
                name: 'Выполнение плана',
                value: aggregatedInfo.FinancePercent + '%',
                type: 'percent',
              },
            ]"
            icon="mdi-currency-rub"
            factColor="#0CA85D"
            :labels="[queryParams.params.year + ' год', currentCare.Name]"
          ></tfoms-bar>
          <tfoms-finance
            title="СУММА САНКЦИЙ"
            class="mt-5"
            :values="[
              {
                value: aggregatedInfo.SanctionTotalValue,
                label: this.provideUnits.rub.text,
              },
            ]"
            :data="
              aggregatedInfo.SanctionValues.map((e) => [
                e.SanctionValue,
                e.FineValue,
              ])
            "
            :labels="[currentCare.Name]"
            :subTitle="queryParams.params.year + ' год'"
          ></tfoms-finance>
        </v-col>
      </v-row>
    </div>

    <tfoms-spacer
      title="ДЕТАЛЬНАЯ ИНФОРМАЦИЯ В РАЗРЕЗЕ УСЛОВИЙ И ВИДОВ ОКАЗАНИЯ МП, ПОДРАЗДЕЛЕНИЙ И СОТРУДНИКОВ"
    ></tfoms-spacer>

    <div
      class="d-flex mt-5"
      style="position: relative"
      v-if="dataSource.HealthCares"
    >
      <wrapper-loader
        v-if="
          $refs.tfomsParams &&
          ($refs.tfomsParams.loading ||
            $refs.tfomsParams.startLoading != $refs.tfomsParams.endLoading)
        "
      ></wrapper-loader>
      <tfoms-params
        :currentCare="currentCare"
        :healthCares="dataSource.HealthCares"
        :groupId.sync="groupId"
        :tfomsMore="$refs.tfomsMore"
        :year="queryParams.params.year"
        @updateParams="$emit('updateParams', $event)"
        @updatedetailParams="$emit('updatedetailParams', $event)"
        ref="tfomsParams"
      ></tfoms-params>
      <tfoms-more
        style="flex: 1"
        class="ml-5"
        v-model="tabMore"
        :groupId.sync="groupId"
        ref="tfomsMore"
        :tfomsParams="$refs.tfomsParams"
        @updateParams="$emit('updateParams', $event)"
        @updatedetailParams="$emit('updatedetailParams', $event)"
      >
      </tfoms-more>
    </div>
  </v-form>
</template>
<script>
import { debounce } from "lodash";
import { mapActions } from "vuex";

import UnsavedMixin from "@/mixins/UnsavedMixin.js";
import CreateAndEditMixin from "@/mixins/CreateAndEditMixin.js";
import tfomsTab from "../mixins/tfomsTab";
import TFormService from "@/services/TFormService";

import tfomsTable from "../components/tfomsTable";
import tfomsPanel from "@/components/analysis/panel.vue";

import tfomsBar from "../components/tfomsBar.vue";
import tfomsLostIncome from "../components/tfomsLostIncome.vue";
import tfomsFinance from "../components/tfomsFinance.vue";
import tfomsMore from "../components/tfomsMore.vue";

export default {
  name: "view-AnalysisTform-tfoms1",
  components: {
    wrapperLoader: () => import("@/components/base/WrapperLoader.vue"),
    tfomsTable,

    tfomsPanel,

    tfomsBar,
    tfomsLostIncome,
    tfomsFinance,

    tfomsMore,
    tfomsSpacer: () => import("../components/tfomsSpacer.vue"),
    tfomsConditions: () => import("../components/tfomsConditions.vue"),
    tfomsParams: () => import("../components/tfomsParams.vue"),
  },
  mixins: [CreateAndEditMixin, UnsavedMixin, tfomsTab],
  data: function () {
    return {
      // Api
      apiService: TFormService.tfoms1,
      getDefaultObject: {},

      loading: true,
      startLoading: 0,
      endLoading: 0,
      dataSource: {},

      active: 0,
      data: [],

      percentValues: [],
      percentFinances: [],

      aggregatedInfo: null,
      healthCare: 1,
      groupId: null,

      sum: {
        month: "Итог",
        VolumePlanValue: 0,
        VolumeValue: 0,
        FinancialPlanValue: 0,
        FinancialValue: 0,
        FineValue: 0,
        SanctionValue: 0,
      },
      tabMore: 0,
    };
  },
  computed: {
    currentCare() {
      if (this.healthCare) {
        return this.dataSource?.HealthCares.find(
          (e) => e.Id === this.healthCare
        );
      }
      return this.dataSource?.HealthCares[0];
    },
    dataWithTotal() {
      return [...this.data, this.sum];
    },
    readonly() {
      return false;
    },
  },
  watch: {
    active() {
      // this.initChartBar();
    },
    healthCare(val) {
      this.updateAggregated();
    },
  },

  methods: {
    ...mapActions([]),

    aggregatedNormalize(data) {
      data.FinanceTotalPlanValue = this.getRub(data.FinanceTotalPlanValue);
      data.FinanceTotalValue = this.getRub(data.FinanceTotalValue);
      data.SanctionTotalValue = this.getRub(data.SanctionTotalValue);
      data.FineVolumeValue = this.getUnit(data.FineVolumeValue);
      data.NonPaymentVolumeValue = this.getUnit(data.NonPaymentVolumeValue);

      data.VolumeTotalPlanValue = this.getUnit(data.VolumeTotalPlanValue);
      data.VolumeTotalValue = this.getUnit(data.VolumeTotalValue);
      data.SanctionVolumeCount = this.getUnit(data.SanctionVolumeCount);

      for (let i = 0; i < data.FinanceValues.length; i++) {
        const item = data.FinanceValues[i];
        item.FinancialPlanValue = this.getRub(item.FinancialPlanValue);
        item.FinancialValue = this.getRub(item.FinancialValue);
      }

      for (let i = 0; i < data.SanctionValues.length; i++) {
        const item = data.SanctionValues[i];
        item.FineValue = this.getRub(item.FineValue);
        item.NonPaymentValue = this.getRub(item.NonPaymentValue);
      }

      for (let i = 0; i < data.VolumeValues.length; i++) {
        const item = data.VolumeValues[i];
        item.VolumePlanValue = this.getUnit(item.VolumePlanValue);
        item.VolumeValue = this.getUnit(item.VolumeValue);
      }

      for (let i = 0; i < data.SanctionCountValues.length; i++) {
        const item = data.SanctionCountValues[i];
        item.FineVolumeValue = this.getUnit(item.FineVolumeValue);
        item.NonPaymentVolumeValue = this.getUnit(item.NonPaymentVolumeValue);
      }

      return data;
    },

    DataFromServerNormalize(data) {
      data.FinancePlanValue = this.getRub(data.FinancePlanValue);
      data.FinanceValue = this.getRub(data.FinanceValue);
      data.FineValue = this.getRub(data.FineValue);
      data.NonPaymentValue = this.getRub(data.NonPaymentValue);

      this.data = [];
      for (let i = 0; i < data.Values.length; i++) {
        const item = data.Values[i];

        this.data.push({
          Id: item.Id,
          Name: item.Name,
          VolumePlanValue: this.getUnit(item.VolumePlanValue),
          VolumeValue: this.getUnit(item.VolumeValue),
          FinancialPlanValue: this.getRub(item.FinancialPlanValue),
          FinancialValue: this.getRub(item.FinancialValue),
          FineValue: this.getRub(item.FineValue),
          SanctionValue: this.getRub(item.NonPaymentValue + item.FineValue),
          FinancialValuePercent: item.FinancialValuePercent,
          NonPaymentValue: this.getRub(item.NonPaymentValue),
        });
      }

      // Проценты
      this.percentValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.percentFinances = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      this.sum = {
        Name: "Итог",
        VolumePlanValue: 0,
        VolumeValue: 0,
        FinancialPlanValue: 0,
        FinancialValue: 0,
        FineValue: 0,
        SanctionValue: 0,
        NonPaymentValue: 0,
        FinancialValuePercent: 0,
      };

      // Проценты
      this.percentValues = this.getValues(this.data);
      this.percentFinances = this.getValues(
        this.data,
        "FinancialPlanValue",
        "FinancialValue"
      );

      for (let i = 0; i < this.data.length; i++) {
        // Сумма
        this.sum.VolumePlanValue += parseFloat(this.data[i].VolumePlanValue);
        this.sum.VolumeValue += parseFloat(this.data[i].VolumeValue);
        this.sum.FinancialPlanValue += parseFloat(
          this.data[i].FinancialPlanValue
        );
        this.sum.FinancialValue += parseFloat(this.data[i].FinancialValue);
        this.sum.FineValue += parseFloat(this.data[i].FineValue);
        this.sum.SanctionValue += parseFloat(this.data[i].SanctionValue);
        this.sum.NonPaymentValue += parseFloat(this.data[i].NonPaymentValue);
        this.sum.FinancialValuePercent += this.data[i].FinancialValuePercent;
      }

      // Нормализация суммы
      this.sum.VolumePlanValue = parseFloat(
        this.sum.VolumePlanValue.toFixed(2)
      );
      this.sum.VolumeValue = parseFloat(this.sum.VolumeValue.toFixed(2));
      this.sum.FinancialPlanValue = parseFloat(
        this.sum.FinancialPlanValue.toFixed(2)
      );
      this.sum.FinancialValue = parseFloat(this.sum.FinancialValue.toFixed(2));
      this.sum.FineValue = parseFloat(this.sum.FineValue.toFixed(2));
      this.sum.SanctionValue = parseFloat(this.sum.SanctionValue.toFixed(2));
      this.sum.NonPaymentValue = parseFloat(
        this.sum.NonPaymentValue.toFixed(2)
      );
      this.sum.FinancialValuePercent = Math.round(
        this.sum.FinancialValuePercent / this.data.length
      );

      return data;
    },
    updateAggregated: debounce(async function () {
      this.loading = true;
      this.startLoading++;
      try {
        this.aggregatedInfo = this.aggregatedNormalize(
          (
            await TFormService.tfoms1.getAggregatedInfo({
              ...this.queryParams.params,
              groupId: this.healthCare,
            })
          ).data
        );
      } catch (error) {
        console.log(error);
      }
      this.endLoading++;
      this.loading = false;
    }, 500),
    async init(params) {
      await this.baseInit(params);
      this.healthCare = this.dataSource.HealthCares[0].Id;
      await this.updateAggregated();
      if (this.$refs?.tfomsParams) await this.$refs.tfomsParams.init();
    },

    async save() {
      await this.baseSave();
    },
  },
};
</script>
