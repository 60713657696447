<template>
  <div>
    <base-table-full
      :dataSource="data"
      :headers="headers"
      notShowSelect
      hide-default-footer
      hide-default-header
      :showSettings="false"
      :sort-by="['Value']"
      :sort-desc="[true]"
      :itemsPerPage="itemsPerPage"
      not-filter
    >
      <template v-if="data.length > 0" v-slot:body.append="{ headers }">
        <tr>
          <td :colspan="headers.length">
            <v-btn
              v-if="itemsPerPage !== -1"
              class="my-1"
              block
              depressed
              @click="itemsPerPage = -1"
            >
              Показать все объекты ({{ data.length }})
            </v-btn>
            <v-btn
              v-else
              class="my-1"
              block
              depressed
              @click="itemsPerPage = 0"
            >
              Скрыть все объекты
            </v-btn>
          </td>
        </tr>
      </template>
    </base-table-full>
  </div>
</template>
<script>
import StatisticIndicatorHelper from "../../../statisticIndicator/StatisticIndicatorHelper";

export default {
  components: {
    BaseTableFull: () => import("@/components/base/BaseTableFull.vue"),
  },
  props: {
    data: { type: Array, default: () => [] },
    statisticIndicator: Object,
  },
  data() {
    return {
      headers: [
        { value: "Name", text: "" },
        {
          value: "Value",
          text: "",
          dataType: "numberBar",
          options: {
            background: (head, value, item) => {
              return (
                StatisticIndicatorHelper.getColorByRefs(
                  [
                    this.statisticIndicator.RefValue1,
                    this.statisticIndicator.RefValue2,
                    this.statisticIndicator.RefValue3,
                  ],
                  value,
                  this.statisticIndicator.RatingType
                ) || "primary"
              );
            },
          },
        },
        { value: "Date", text: "" },
        { value: "Diff", text: "" },
      ],
      itemsPerPage: 0,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {},
  },
};
</script>
<style lang="scss"></style>
