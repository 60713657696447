<template>
  <base-detail-view
    :loading="loading"
    title="Профиль сотрудника"
    :isUnsaved="isUnsaved && valid"
    @onRefresh="init"
    @onSave="save"
  >
    <template #action>
      <v-btn
        @click="dialogChangePassword = true"
        dark
        color="primary"
        class="ml-4"
      >
        Изменить пароль
      </v-btn>
    </template>
    <v-form v-model="valid" class="block__content">
      <v-container>
        <v-row>
          <v-col>
            <div class="d-flex justify-center align-center">
              <v-list-item-avatar
                color="blue-grey lighten-3"
                style="width: 200px; height: 200px; margin: 0"
              >
                <v-img v-if="pictureSrc" :src="pictureSrc"></v-img>
                <span v-else style="font-size: 40px; user-select: none">
                  {{ $store.state.user.Employee | PersonInitials }}
                </span>
              </v-list-item-avatar>
            </div>
            <input-load-file
              :files="picture"
              accept="image/*"
              label="Фото"
              prepend-icon=""
              class="mt-1 mb-4"
            ></input-load-file>
          </v-col>
          <v-col>
            <v-text-field
              v-model="dataSource.LastName"
              label="Фамилия"
              :rules="[rules.required]"
              required
              maxlength="100"
              :readonly="readonlyFIO"
              :clearable="!readonlyFIO"
            >
            </v-text-field>
            <v-text-field
              v-model="dataSource.FirstName"
              label="Имя"
              :rules="[rules.required]"
              required
              maxlength="100"
              :readonly="readonlyFIO"
              :clearable="!readonlyFIO"
            ></v-text-field>
            <v-text-field
              v-model="dataSource.MiddleName"
              label="Отчество"
              maxlength="100"
              :readonly="readonlyFIO"
              :clearable="!readonlyFIO"
            ></v-text-field>
            <base-date-picker
              v-model="dataSource.BirthDate"
              label="Дата рождения"
              clearable
            ></base-date-picker>
          </v-col>
          <v-col>
            <v-text-field
              v-model="dataSource.User.PhoneNum"
              @input="formatNumber"
              @keypress="restrictInput"
              label="Номер телефона"
              :rules="[rules.requiredNum]"
              placeholder="+7 ("
              maxlength="18"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <workplaces
      :workplaces="dataSource.WorkPlaces"
      :readonly="readonlyWP"
      @edit="editWorkplaces"
      class="mt-2"
    ></workplaces>
    <base-popup
      v-model="dialogChangePassword"
      title="Изменение пароля"
      :icon="UtilGetIcon('view-Profile')"
      :disabledSave="!validCP"
      :width="'40vw'"
      :height="'auto'"
      @save="changePassword()"
      @cancel="reset"
    >
      <v-form ref="changePasswordForm" v-model="validCP" class="my-5">
        <v-text-field
          ref="oldPasswordField"
          v-model="oldPassword"
          :type="showOldPassword ? 'text' : 'password'"
          :rules="[rules.required, rules.spaceCheck]"
          :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
          required
          label="Старый пароль"
          clearable
          autofocus
          tabindex="1"
          @click:append="showOldPassword = !showOldPassword"
        />
        <v-text-field
          ref="newPasswordField"
          v-model="newPassword"
          :type="showNewPassword ? 'text' : 'password'"
          :rules="[rules.required, rules.diffFromOld, rules.spaceCheck]"
          :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          required
          label="Новый пароль"
          maxlength="20"
          counter
          clearable
          tabindex="2"
          @input="repeatedPassword = null"
          @click:append="showNewPassword = !showNewPassword"
        />
        <v-text-field
          ref="repeatedPasswordField"
          v-model="repeatedPassword"
          :type="showRepeatedPassword ? 'text' : 'password'"
          :rules="[rules.required, rules.equalToNew, rules.spaceCheck]"
          :append-icon="showRepeatedPassword ? 'mdi-eye' : 'mdi-eye-off'"
          required
          label="Повторите новый пароль"
          maxlength="20"
          counter
          clearable
          tabindex="3"
          @click:append="showRepeatedPassword = !showRepeatedPassword"
        />
      </v-form>
    </base-popup>
  </base-detail-view>
</template>

<script>
import ProfileService from "../../services/ProfileService";
import { defaultEmployee } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import BaseDatePicker from "../../components/base/BaseDatePicker.vue";
import BasePopup from "../../layouts/BasePopup.vue";
import InputLoadFile from "../../components/loadFiles/InputLoadFile.vue";
import Workplaces from "../../components/organization/Workplaces.vue";

export default {
  name: "view-Profile",
  components: {
    BaseDetailView,
    BaseDatePicker,
    BasePopup,
    InputLoadFile,
    Workplaces,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: function () {
    return {
      apiService: ProfileService,
      loading: true,
      dataSource: defaultEmployee(),
      picture: [],
      pictureSrc: "",
      baseUrl: process.env.VUE_APP_SERVER_URL,
      dialogChangePassword: false,
      oldPassword: null,
      newPassword: null,
      repeatedPassword: null,
      showOldPassword: false,
      showNewPassword: false,
      showRepeatedPassword: false,
      valid: false,
      validCP: false,
      rules: {
        required: (value) => !!value || "Укажите значение!",
        diffFromOld: (value) =>
          value !== this.oldPassword ||
          "Новый пароль не должен совпадать со старым",
        equalToNew: (value) =>
          value === this.newPassword || "Пароли не совпадают!",
        requiredNum: (value) => {
          if (!value) return true;
          return value.length === 18 || "Введите номер полностью";
        },

        spaceCheck: (val) => {
          const regexp = /[\s]/i;
          return !regexp.test(val) || "Запрещен ввод пробела!";
        },
      },
      readonlyFIO:
        !this.hasPermission(Permissions.EmployeesEdit) &&
        !this.hasPermission(Permissions.UserProfileFIOEdit),
      readonlyWP:
        !this.hasPermission(Permissions.EmployeesEdit) &&
        !this.hasPermission(Permissions.UserProfileWorkPlacesEdit),
    };
  },
  watch: {
    picture(value) {
      if (this.dataSource.User) {
        this.dataSource.User.Picture = value.length ? value[0] : null;
        if (!value.length || !value[0].ExternalId) this.pictureSrc = "";
      }
    },
    // Проверка валидации поля нового пароля "Новый пароль не должен совпадать со старым"
    oldPassword: {
      handler(value) {
        if (value == null || this.newPassword == null) return;
        if (
          value !== this.newPassword &&
          !this.$refs.newPasswordField.validate()
        ) {
          this.$refs.newPasswordField.validate(true);
        }
        if (value === this.newPassword) {
          this.$refs.newPasswordField.validate(false);
        }
      },
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async save() {
      const { data } = await this.baseSave();
      this.$store.commit("SET_PROFILE", data);

      const user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;

      if (data.User) {
        user.Employee.User.Picture = data.User.Picture;
        user.Picture = data.User.Picture;
      }

      localStorage.setItem("user", JSON.stringify(user));
    },
    DataFromServerNormalize(data) {
      if (data.User && data.User.Picture) {
        this.picture.splice(0, 1);
        this.picture.push(data.User.Picture);
        this.pictureSrc = `${this.baseUrl}common/GetFile?externalId=${data.User.Picture.ExternalId}`;
      }
      if (data.User && data.User.PhoneNum) {
        const number = data.User.PhoneNum;
        data.User.PhoneNum = `+7 (${number.slice(1, 4)}) ${number.slice(
          4,
          7
        )}-${number.slice(7, 9)}-${number.slice(9, 11)}`;
      }
      return data;
    },
    editWorkplaces(value) {
      if (!value) return;
      if (value.Id < 0) this.dataSource.WorkPlaces.push(value);
      else {
        const index = this.dataSource.WorkPlaces.findIndex(
          (item) => item.Id === value.Id
        );
        if (index !== -1) this.$set(this.dataSource.WorkPlaces, index, value);
      }
    },
    reset() {
      this.$refs.changePasswordForm.reset();
      this.showOldPassword = false;
      this.showNewPassword = false;
      this.showRepeatedPassword = false;
    },
    async changePassword() {
      try {
        await ProfileService.changePassword(this.oldPassword, this.newPassword);
        this.reset();
        this.dialogChangePassword = false;
        this.$alert("Пароль успешно изменен", { type: "success" });
      } catch (err) {
        console.debug(err);
        this.$refs.newPasswordField.reset();
        this.showNewPassword = false;
        this.$refs.repeatedPasswordField.reset();
        this.showRepeatedPassword = false;
      }
    },
    restrictInput(event) {
      const charCode = event.charCode;
      // Разрешаем только цифры (0-9)
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },
    formatNumber() {
      let input = this.dataSource.User.PhoneNum || "";

      // Удаляем все нецифровые символы
      input = input.replace(/\D/g, "");

      if (input.startsWith("9")) {
        input = "7" + input;
      }

      if (input.length !== 0) {
        // Форматируем номер
        let formattedNumber = "+7 (";
        if (input.length >= 1) {
          formattedNumber +=
            input.substring(1, 4) + (input.length >= 4 ? ") " : "");
        }
        if (input.length >= 5) {
          formattedNumber += input.substring(4, 7);
        }
        if (input.length >= 8) {
          formattedNumber += "-" + input.substring(7, 9);
        }
        if (input.length >= 10) {
          formattedNumber += "-" + input.substring(9, 11);
        }

        this.dataSource.User.PhoneNum = formattedNumber;
      } else {
        this.dataSource.User.PhoneNum = null;
      }
    },
  },
};
</script>
