<template>
  <base-list-view
    title="Документы"
    :dataSource="documents"
    :headers="headers"
    header-hidden
    isNested
    hide-default-footer
    show-actions
    editable
    text-custom-add="Добавить документ"
    :show-add-in-table="!readonly"
    :hideDelete="readonly"
    @customAdd="add"
    @customEdit="edit"
  >
    <template #item.Date="{ item }">
      {{ item.Date | DateShort }}
    </template>
    <template #item.DateOut="{ item }">
      {{ item.DateOut | DateShort }}
    </template>
    <applied-document-edit
      v-model="dialogEdit"
      :document="current"
      :objectType="objectType"
      :width="'80vw'"
      :height="'80vh'"
      @save="change"
      :readonly="readonly"
    ></applied-document-edit>
  </base-list-view>
</template>

<script>
import { defaultAppliedDocument } from "../../data/defaultObjects";
import BaseListView from "../../layouts/BaseListView.vue";
import AppliedDocumentEdit from "./AppliedDocumentEdit.vue";

export default {
  name: "view-AppliedDocuments",
  components: { BaseListView, AppliedDocumentEdit },
  props: {
    appliedHeaders: Array,
    documents: {
      type: Array,
      default: () => [],
    },
    objectType: Number,
    readonly: Boolean,
  },
  data: () => {
    return {
      dialogEdit: false,
      current: defaultAppliedDocument(),
    };
  },
  computed: {
    headers: {
      get() {
        return this.appliedHeaders || [];
      },
      set(newValue) {
        this.$emit("update:appliedHeaders", newValue);
      },
    },
  },
  methods: {
    add() {
      this.current = defaultAppliedDocument();
      this.dialogEdit = true;
    },
    edit(value) {
      if (value) {
        this.current = this._.cloneDeep(value);
        this.dialogEdit = true;
      }
    },
    change(value) {
      this.dialogEdit = false;
      this.$emit("edit", value);
    },
  },
};
</script>
