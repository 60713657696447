<template>
  <base-popup
    :title="
      'Замена аудитора - ' +
      (member ? $options.filters.PersonShortName(member.Employee) : '')
    "
    hideSave
    v-model="dialog"
  >
    <v-container>
      <div class="mb-2">
        <span class="caption">
          Данный аудитор ответил на один или несколько вопросов. Заменить его
          или удалить с очисткой результата ?
        </span>
      </div>
      <base-table
        :headers="headers"
        :items="checkLists"
        notShowSelect
        hide-default-footer
        notFilter
      >
        <template slot="item.TotalQuestionCount" slot-scope="{ item }">
          {{
            item.YesQuestionCount +
            item.NoQuestionCount +
            item.NotApplicableQuestionCount
          }}
          из {{ item.TotalQuestionCount }}
        </template>
        <template slot="item.YesQuestionCount" slot-scope="{ item }">
          <span class="success--text">{{ item.YesQuestionCount }}</span>
        </template>
        <template slot="item.NoQuestionCount" slot-scope="{ item }">
          <span class="error--text">{{ item.NoQuestionCount }}</span>
        </template>
        <template slot="item.NotApplicableQuestionCount" slot-scope="{ item }">
          <span class="warning--text">
            {{ item.NotApplicableQuestionCount }}
          </span>
        </template>
        <template slot="header.YesQuestionCount">
          <m-icon
            :icon="answerOptions[0].name"
            :class="answerOptions[0].name"
            :color="answerOptions[0].color"
            s24
          />
        </template>
        <template slot="header.NoQuestionCount">
          <m-icon
            :icon="answerOptions[1].name"
            :class="answerOptions[1].name"
            :color="answerOptions[1].color"
            s24
          />
        </template>
        <template slot="header.NotApplicableQuestionCount">
          <m-icon
            :icon="answerOptions[2].name"
            :class="answerOptions[2].name"
            :color="answerOptions[2].color"
            s24
          />
        </template>
      </base-table>
      <v-container class="px-5" fluid>
        <v-radio-group v-model="radioGroup">
          <v-radio :value="1"
            ><template v-slot:label>
              <div>
                <strong>Очистить результат</strong>
                <span v-show="radioGroup === 1" class="caption mx-3">
                  Сотрудник будет исключен из аудиторов, а его результат будет
                  очищен
                </span>
              </div>
            </template></v-radio
          >
          <v-radio :value="2">
            <template v-slot:label>
              <div class="d-flex">
                <div class="align-self-center mr-5">
                  <strong>
                    Заменить "{{
                      $options.filters.PersonShortName(member.Employee)
                    }}" на:
                  </strong>
                </div>
                <div class="align-self-center">
                  <div v-show="radioGroup === 2" class="caption mx-3">
                    Сотрудник будет исключен из аудиторов, а его результат будет
                    передан другому аудитору
                  </div>
                  <m-select
                    v-model="employeeSelect"
                    :disabled="radioGroup != 2"
                    dense
                    filled
                    :items="
                      employees.filter(
                        (e) =>
                          selectedMembers.find(
                            (e2) => e2.Employee.Id === e.Id && e2.Role <= 1
                          ) && member.Employee.Id !== e.Id
                      )
                    "
                    filter="EmployeeActive"
                    :dict="{ name: 'Employees', notItems: true }"
                    :rules="[(v) => !!v || 'Выберите сотрудника для замены']"
                    :isAddItem="false"
                  >
                  </m-select>
                </div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-container>
      <div class="mx-auto mt-5" style="width: 50%">
        <v-btn color="primary" block @click="handlerApply">Применить</v-btn>
      </div>
    </v-container>
  </base-popup>
</template>
<script>
import { mapActions } from "vuex";
import AuditOperationService from "@/services/AuditOperationService";
import BasePopup from "../../layouts/BasePopup.vue";
import BaseTable from "../base/BaseTable.vue";
import MSelect from "../base/inputs/mSelect.vue";
export default {
  name: "auditOperation-replace-auditor",
  components: { BasePopup, BaseTable, MSelect },
  props: {
    value: Boolean,
    checkLists: Array,
    member: {
      type: Object,
      default: null,
    },
    selectedMembers: {
      type: Array,
      default: () => [],
    },
  },
  data: () => {
    return {
      radioGroup: 1,
      employeeSelect: null,
      employees: [],
      headers: [
        { value: "CheckListName", text: "Чек-лист" },
        { value: "objectCheck", text: "Объект проверки" },
        {
          value: "YesQuestionCount",
          text: "Да",
          width: "1%",
          sortable: false,
          align: "center",
        },
        {
          value: "NoQuestionCount",
          text: "Нет",
          width: "1%",
          sortable: false,
          align: "center",
        },
        {
          value: "NotApplicableQuestionCount",
          text: "Не определено",
          width: "1%",
          sortable: false,
          align: "center",
        },
        {
          value: "TotalQuestionCount",
          text: "Всего вопросов",
          width: "1%",
          sortable: false,
          align: "center",
        },
      ],
      answerOptions: [
        {
          name: "check-a",
          nameOutline: "check-d",
          color: "#0CA85D",
          title: "Да",
          value: 1,
        },
        {
          name: "check-b",
          nameOutline: "check-e",
          color: "#F95050",
          title: "Нет",
          value: 2,
        },
        {
          name: "check-c",
          nameOutline: "check-f",
          color: "#FFC805",
          title: "Не применимо",
          value: 3,
        },
        {
          name: "mdi-close",
          nameOutline: "mdi-close",
          title: "Очистить",
          value: 0,
        },
      ],
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    dialog: function (val) {
      if (!val) {
        this.radioGroup = 1;
        this.employeeSelect = null;
      }
    },
  },
  async mounted() {
    this.employees = await this.LOAD_EMPLOYEES();
  },
  methods: {
    ...mapActions(["LOAD_EMPLOYEES", "LOAD_PARTITIONS"]),
    async handlerApply() {
      if (this.radioGroup === 1) {
        await AuditOperationService.updateAuditMember(this.member.Id);
        this.$emit("updateAuditor", {
          old: this.member.Employee.Id,
          new: null,
        });
      } else if (this.radioGroup === 2 && this.employeeSelect) {
        try {
          await AuditOperationService.updateAuditMember(
            this.member.Id,
            this.employeeSelect
          );
          this.$emit("updateAuditor", {
            old: this.member.Employee.Id,
            new: this.employeeSelect,
          });
        } catch (error) {
          console.log(error);
          return false;
        }
      }
      this.dialog = false;
    },
    transformedAuditors(scope) {
      return this.auditors.map((a) => {
        return scope.rowEditing.Auditors.findIndex(
          (e) => e?.Employee?.Id === a?.Id
        ) >= 0
          ? scope.rowEditing.Auditors.find((e) => e.Employee.Id === a.Id)
          : { Employee: { ...a }, Id: a.Id };
      });
    },
  },
};
</script>
