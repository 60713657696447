<template>
  <base-list-view
    :dataSource="value"
    :headers="headers"
    isNested
    header-hidden
    hide-default-footer
    :show-add-in-table="!readonly"
    :show-actions="!readonly"
    editable
    notShowSelect
    ref="listView"
    textCustomAdd="Добавить объект оценки"
    :default-object="
      () => {
        return {
          Id: -Math.round(Math.random() * 1000000),
          Partition: null,
          Responsibles: null,
          Employee: null,
        };
      }
    "
  >
    <template slot="item.Responsible" slot-scope="scope">
      <div
        @click.stop
        v-if="scope.rowEditing && scope.item.Id === scope.rowEditing.Id"
      >
        <m-autocomplete
          multiple
          v-model="scope.rowEditing.Responsibles"
          label="Выберите ответственного"
          :items="employees"
          return-object
          filter="EmployeeActive"
          dict="Employees"
          :textCustomAdd="'Создать нового сотрудника'"
          :readonly="readonly"
          :clearable="!readonly"
          v-focusInput.noFocus="scope.closeEdit"
        ></m-autocomplete>
      </div>
      <span v-else> {{ getResponsibleText(scope.item.Responsibles) }}</span>
    </template>
    <template slot="item.Partition" slot-scope="scope">
      <template v-if="type === 1">
        <div v-show="scope.rowEditing && scope.item.Id === scope.rowEditing.Id">
          <m-tree-select
            v-if="scope.rowEditing"
            v-model="scope.rowEditing.Partition"
            :items="partitions"
            @click.prevent.stop
            selection-type="independent"
            allowSelectParents
            label="Выберите структурное подразделение"
            :dict="{ name: 'Partition', notItems: true }"
            :readonly="readonly"
          ></m-tree-select>
        </div>
        <span
          v-show="!(scope.rowEditing && scope.item.Id === scope.rowEditing.Id)"
        >
          {{ scope.item.Partition ? scope.item.Partition.Name : "Не выбрано" }}
        </span>
      </template>
      <template v-else>
        <div
          @click.prevent.stop
          v-if="scope.rowEditing && scope.item.Id === scope.rowEditing.Id"
        >
          <m-autocomplete
            v-model="scope.rowEditing.Employee"
            label="Выберите сотрудника"
            :items="employees"
            return-object
            filter="EmployeeActive"
            dict="Employees"
            :textCustomAdd="'Создать нового сотрудника'"
            :readonly="readonly"
          ></m-autocomplete>
        </div>
        <span v-else>
          {{ $options.filters.PersonShortName(scope.item.Employee) }}
        </span>
      </template>
    </template>
  </base-list-view>
</template>
<script>
import { mapActions } from "vuex";
import BaseListView from "../../layouts/BaseListView.vue";
import mAutocomplete from "../base/inputs/mAutocomplete.vue";
import MTreeSelect from "../base/inputs/mTreeSelect.vue";
export default {
  components: { BaseListView, MTreeSelect, mAutocomplete },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      employees: [],
      partitions: [],
      headers: [
        {
          text:
            this.type === 1
              ? "Объект (структурное подразделение)"
              : "Объект (сотрудник)",
          value: "Partition",
          displayText: (e) => e.Name,
        },
        {
          text: "Ответственные",
          value: "Responsible",
          dataType: "array",
          displayText: this.$options.filters.PersonShortName,
        },
      ],
    };
  },
  watch: {
    "$store.state.partitions": function (val) {
      this.partitions = val;
    },
  },
  async created() {
    this.employees = await this.LOAD_EMPLOYEES();
    this.partitions = await this.LOAD_PARTITIONS();
  },
  methods: {
    ...mapActions(["LOAD_EMPLOYEES", "LOAD_PARTITIONS"]),
    getResponsibleText(arr) {
      return arr
        ?.map((e) => this.$options.filters.PersonShortName(e))
        .join(", ");
    },
  },
};
</script>
