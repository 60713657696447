<template>
  <base-list-view
    :selectedItems="selectedItems"
    :dataSource="value"
    :headers="headers"
    isNested
    :header-hidden="!isSelect"
    :hide-default-footer="!isSelect"
    v-on="$listeners"
    v-bind="$attrs"
    :notShowSelect="!isSelect"
    ref="listView"
    textCustomAdd="Добавить шаблон"
    sort-by="Date"
    :sort-desc="[true]"
    @customHandle="hadlerClick"
    @customAdd="showDialog = true"
    :item-class="
      (item) => {
        return item.Status === 6 ? 'disabled' : '';
      }
    "
    :custom-filter="customFilter"
  >
    <template slot="item.Date" slot-scope="{ item }">
      {{ item.Date | dateOnly }}
    </template>
    <popup-template
      v-if="$attrs['show-add-in-table']"
      v-model="showDialog"
      :show-add-in-table="false"
      @save="save"
      :selectedItems="value"
    ></popup-template>
  </base-list-view>
</template>
<script>
export default {
  components: {
    BaseListView: () => import("@/layouts/BaseListView.vue"),
    PopupTemplate: () => import("./PopupTemplate.vue"),
  },
  props: {
    selectedItems: { Array, default: () => [] },
    value: {
      type: Array,
      default: () => [],
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
    customFilter: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      showDialog: false,
      employees: [],
      headers: [{ text: "Наименование", value: "Name", notGroup: true }],
    };
  },
  methods: {
    save(items) {
      for (let i = 0; i < items.length; i++) {
        if (!this.value.find((e) => e.Id === items[i].Id))
          this.value.push({ ...items[i], isNew: true });
      }
      this.showDialog = false;
    },
    hadlerClick(e) {
      if (this.isSelect) return;
      this.$router.push({
        name: "DocumentTemplateEdit",
        params: { objectId: e.Id },
      });
    },
  },
};
</script>
