import Api from "./Api";

export default {
  async get({ partitionId = null } = {}) {
    return Api().get("organization/GetBuildings", { params: { partitionId } });
  },
  async show(id) {
    return Api().get("organization/GetBuilding", { params: { id } });
  },
  async create(building) {
    return Api().post("organization/CreateBuilding", building);
  },
  async update(building) {
    return Api().put("organization/UpdateBuilding", building);
  },
  async delete(id) {
    return Api().delete("organization/DeleteBuilding", { params: { id } });
  },
};
