<template>
  <v-list>
    <v-list-item>
      <v-list-item-avatar>
        <m-icon :icon="icon" large></m-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ title }}</v-list-item-title>
        <v-list-item-subtitle :title="pageName">
          {{ pageName }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-progress-circular
          :rotate="360"
          :size="44"
          :width="2"
          :value="progress"
          class="tour-manager__progress-circular"
          :class="buildStyleStatus(progress)"
        >
          {{ progress }}%
        </v-progress-circular>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: "medexpert-play",
    },
    title: {
      type: String,
      default: "Обучающие туры",
    },
    pageName: {
      type: String,
      default: "pageName",
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    buildStyleStatus(progress) {
      return {
        "tour-manager--in-progress": progress > 0 && progress < 100,
        "tour-manager--finished": progress === 100,
      };
    },
  },
};
</script>
