<template>
  <base-popup
    title="Импорт сотрудников из файла xls"
    :icon="UtilGetIcon('view-Employees')"
    :loadingFile="loadingFile"
    :disabledSave="disabledSaveImport"
    :width="'80vw'"
    :height="'500px'"
    @save="save"
    @cancel="resetLoad()"
    :value="dialog"
    @input="$emit('update:dialog', $event)"
    buttonSaveText="Загрузить"
  >
    <v-container class="container-space-between">
      <v-form v-model="valid">
        <v-file-input
          v-model="file"
          outlined
          dense
          placeholder="Выберите файл"
          @change="resetLoad()"
          autofocus
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsm"
          required
          :rules="[
            (value) => !!value || 'Выберите файл, в формате xls, xlsx или xlsm',
          ]"
        >
          <template v-slot:selection="{ text }">
            <v-chip small label color="primary">
              {{ text }}
            </v-chip>
          </template>
        </v-file-input>
      </v-form>
      <base-panel
        v-if="tasks.length"
        title="История импорта"
        headerTogether
        icon="medexpert-history-change"
      >
        <base-table-full
          :dataSource="tasks"
          :headers="headers"
          hide-default-footer
          :showSettings="false"
          notShowSelect
          class="mb-3 import-employees__table"
          :sort-by="['DateTime']"
          :sort-desc="[true]"
        >
          <template slot="item.progress" slot-scope="scope">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-progress-circular
                  v-if="scope.item.State === 0"
                  v-on="on"
                  indeterminate
                  color="primary"
                >
                </v-progress-circular>
                <v-progress-circular
                  v-else-if="scope.item.State === 1"
                  v-on="on"
                  color="success"
                  :value="100"
                >
                  {{ scope.item.EmployeeCount }}
                </v-progress-circular>

                <v-progress-circular
                  v-else-if="scope.item.State === 2"
                  v-on="on"
                  color="error"
                  :value="100"
                >
                  0
                </v-progress-circular>
              </template>
              <template v-if="scope.item.State === 0"> Загрузка... </template>
              <template v-else>
                Загружено {{ scope.item.EmployeeCount }} сотрудников
              </template>
            </v-tooltip>
          </template>
        </base-table-full>
      </base-panel>
      <div>
        <v-btn
          block
          dark
          color="primary"
          outlined
          large
          download
          :href="`${apiUrl}common/GetFileExample?type=1`"
        >
          Скачать файл шаблона импорта сотрудников и подразделений
        </v-btn>
      </div>
    </v-container>
  </base-popup>
</template>
<script>
import BaseTableFull from "@/components/base/BaseTableFull.vue";
import ExchangeService from "@/services/ExchangeService";
import BasePopup from "@/layouts/BasePopup.vue";
import { importEmployeesStatus } from "@/data/enums";
import BasePanel from "@/layouts/BasePanel";
export default {
  components: {
    BasePopup,
    BaseTableFull,
    BasePanel,
  },
  props: {
    dialog: { type: Boolean, default: false },
  },
  data() {
    return {
      valid: true,
      loadingFile: false,

      importErrorText: "",
      file: null,
      disabledSaveImport: false,
      tasks: [],
      value: 22,
      headers: [
        {
          value: "ErrorText",
          text: "Ошибка",
        },
        {
          value: "DateTime",
          text: "Дата",
        },
        {
          value: "State",
          text: "Статус",
          dataType: "enum",
          options: importEmployeesStatus,
        },
        {
          value: "progress",
          text: "",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    importEmployeesStatus() {
      return importEmployeesStatus;
    },
    apiUrl() {
      return process.env.VUE_APP_SERVER_URL;
    },
    getErrorImportColor() {
      return this.$vuetify.theme.themes.light.error.base;
    },
  },
  created() {
    this.updateTasks();
  },
  methods: {
    async updateTasks() {
      const { data } = await ExchangeService.tasks();
      this.tasks = data;

      if (data.find((e) => e.State === 0)) {
        setTimeout(() => {
          this.updateTasks();
        }, 5000);
      }
    },
    async save() {
      if (!this.valid) return;
      this.loadingFile = true;
      try {
        this.disabledSaveImport = true;
        await ExchangeService.import(this.file);
        this.updateTasks();

        // this.$emit("update:dialog", false);

        this.$emit("update");
      } catch (error) {
        this.disabledSaveImport = true;
        this.importErrorText = `Ошибка: ${error.response.data.Message}`;
        console.log(error);
      } finally {
        this.loadingFile = false;
      }
    },
    resetLoad() {
      this.disabledSaveImport = false;
    },
  },
};
</script>
<style lang="scss">
.import-employees {
  &__table {
    .v-data-table__wrapper {
      max-height: 200px;
    }

    .v-progress-circular__info {
      font-size: 12px;
    }

    thead {
      tr {
        height: 20px !important;
      }
    }
  }
}
</style>
