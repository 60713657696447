import DataHelper from "../utils/DataHelper";
import Api from "./Api";
import qs from "qs";

export default {
  get(
    auditIndicatorIds = [],
    types = [],
    useSystemData = false,
    questions = null,
    archive = false
  ) {
    return Api().get("auditOperation/getCheckLists", {
      params: {
        auditIndicatorIds,
        types,
        useSystemData,
        questions,
        archive,
      },
      paramsSerializer: function (params) {
        return qs.stringify(params);
      },
    });
  },
  getByIds(checkListIds = []) {
    return Api().post("auditOperation/GetCheckListsById", {
      ids: checkListIds,
    });
  },
  // archive(id) {
  //   return Api().put("auditOperation/UpdateCheckListState", {
  //     id,
  //   });
  // },
  archive(ids) {
    return Api().put("auditOperation/UpdateCheckListArchive", ids);
  },
  show(id) {
    return Api().get("auditOperation/getCheckList", {
      params: { id },
    });
  },
  update(checkList) {
    // Нормализация id для новых объектов
    DataHelper.traverse(checkList, function (key, val, obj) {
      if (key === "Id" && val > 0 && val < 1) {
        obj[key] = 0;
      }
    });

    return Api().put("auditOperation/UpdateOrCreateCheckList", checkList);
  },
  async create(checkList) {
    return Api().post("auditOperation/CreateCheckList", checkList);
  },
  async createES(partitionIds) {
    return Api().post("auditOperation/CreateCheckListsEquipmentStandart", {
      PartitionIds: partitionIds,
    });
  },
  createNewVersion(checkList) {
    DataHelper.traverse(checkList, function (key, val, obj) {
      if (key === "Id" && val > 0 && val < 1) {
        obj[key] = 0;
      }
    });

    return Api().post("auditOperation/CreateCheckListNewVersion", checkList);
  },
  async delete(id) {
    return Api().delete("auditOperation/DeleteCheckList", { params: { id } });
  },
  async getByAuditIndicator(auditIndicatorIds = [], archive = false) {
    return Api().get("auditOperation/getCheckLists", {
      params: { auditIndicatorIds, archive },
      paramsSerializer: function (params) {
        return qs.stringify(params);
      },
    });
  },
  GetCheckQuestions(checkListId) {
    return Api().get("auditOperation/GetCheckQuestions", {
      params: { checkListId },
    });
  },
  import(auditIndicatorId, file) {
    const data = new FormData();

    data.append("File", file);
    data.append("AuditIndicatorId", auditIndicatorId);

    return Api({ "Content-Type": "multipart/form-data" }).post(
      "exchange/ImportCheckList",
      data
    );
  },
  async updateMultiple(archived, objectIds) {
    return Api().put("auditOperation/UpdateCheckLists", {
      Archived: archived,
      ObjectIds: objectIds,
    });
  },
};
