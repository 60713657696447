<template>
  <v-layout>
    <base-detail-view
      :loading="loading"
      title="Шаблон чек-листа"
      @onRefresh="init"
      :showSave="false"
    >
      <v-form class="block__content">
        <v-container>
          <v-row>
            <v-col>
              <v-text-field :value="dataSource.Name" label="Название" readonly>
              </v-text-field>
              <v-text-field
                :value="dataSource.IndicatorsNames"
                label="Показатель оценки качества"
                readonly
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                :value="dataSource.TypeName"
                label="Тип"
                readonly
              ></v-text-field>
              <v-text-field
                :value="dataSource.Comment"
                clearable
                label="Примечание"
                readonly
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <div class="mt-5">
        <v-treeview
          :items="dataSource.Questions"
          item-key="Id"
          item-text="Content"
          item-children="Children"
          open-on-click
          return-object
          class="block__content tree-view"
          expand-icon="$ToolArrowDown"
        >
          <template v-slot:label="{ item }">
            {{ item.Num + " " + item.Content }}
          </template>
        </v-treeview>
      </div>
    </base-detail-view>
  </v-layout>
</template>

<script>
import CheckListService from "../../services/CheckListService";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import { CheckListObjectTypes } from "../../data/enums";
import DataHelper from "../../utils/DataHelper";

export default {
  name: "view-CheckListTemplateView",
  components: { BaseDetailView },
  mixins: [CreateAndEditMixin],
  data: () => {
    return {
      // Api
      apiService: CheckListService,
      getObjectText: (obj) => obj.Name,

      loading: true,
      dataSource: {
        Name: "",
        IndicatorsNames: "",
        TypeName: "",
        Questions: [],
      },
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    DataFromServerNormalize(data) {
      // Add parent Id
      const val = data.Questions;
      for (const i in val) {
        val[i].ParentId = null;
        if (val[i].Children?.length !== 0) {
          DataHelper.treeAddParentId(val[i].Children, val[i].Id);
        }
      }
      DataHelper.treeListTextNumeration(val, "Num", "NumText");

      // Названия ПОК через запятую
      data.IndicatorsNames = data.Indicators
        ? data.Indicators.map((ind) => ind.Name).join(", ")
        : null;

      // Название типа
      data.TypeName = CheckListObjectTypes.find(
        (el) => el.id === data.Type
      )?.value;

      return data;
    },
  },
};
</script>

<style></style>
