<template>
  <v-navigation-drawer
    floating
    class="floating"
    app
    color="var(--v-background-base)"
    permanent
    width="283"
    ref="navigationDrawer"
    :mini-variant="!GET_IS_FIXED"
    :expand-on-hover="!GET_IS_FIXED"
  >
    <v-list>
      <!-- Информация о пользователе -->
      <v-list-item
        class="px-2 pt-2"
        two-line
        @click="handlerClickProfile('/profile')"
      >
        <v-list-item-avatar color="blue-grey lighten-3">
          <v-img v-if="userPictureSrc" :src="userPictureSrc"></v-img>
          <span v-else>{{ $store.state.user.Employee | PersonInitials }}</span>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ $store.state.user.Employee | PersonShortName }}
          </v-list-item-title>
          <v-list-item-subtitle :title="$store.state.Organization.Name">
            {{ $store.state.Organization.Name }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <div class="navigation-drawer__actions">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-checkbox
                  v-model="stateFixed"
                  class="navigation-drawer__fix-menu"
                  on-icon="mdi-pin"
                  off-icon="mdi-pin-off"
                  dense
                  @click.stop.prevent
                ></v-checkbox>
              </span>
            </template>
            <span>
              {{ GET_IS_FIXED ? "Скрывать автоматически" : "Закрепить" }}
            </span>
          </v-tooltip>
        </div>
      </v-list-item>

      <notification-activator />

      <v-divider></v-divider>
      <navigation-group
        v-bind="{
          text: 'Избранное',
          view: 'view-Favorite',
          children: GET_FAVORITE_TAB,
        }"
      >
      </navigation-group>
      <v-divider></v-divider>

      <!-- Основное содержание -->
      <template v-for="(item, i) in GET_ROUTES_DATA">
        <navigation-item
          v-if="!item.children"
          :key="i"
          color="primary"
          v-bind="item"
        />
        <navigation-group v-else v-bind="item" :key="i" />
      </template>

      <v-divider v-if="true"></v-divider>
      <v-list-item v-if="true" link @click="handlerClickTourManager">
        <v-list-item-action>
          <m-icon icon="medexpert-help-tour"></m-icon>
        </v-list-item-action>
        <v-list-item-title>Помощь</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <!-- Выход -->
      <v-list-item link @click="logout">
        <v-list-item-action>
          <m-icon icon="medexpert-exit"></m-icon>
        </v-list-item-action>
        <v-list-item-title>Выход</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import notificationActivator from "./notification/notificationActivator";
import NavigationItem from "./NavigationItem";
import NavigationGroup from "./NavigationGroup";
import AuthService from "../services/AuthService";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    notificationActivator,
    NavigationItem,
    NavigationGroup,
  },
  computed: {
    ...mapGetters("routesData", ["GET_ROUTES_DATA", "GET_IS_FIXED"]),
    ...mapGetters("favoriteTab", ["GET_FAVORITE_TAB"]),

    userPictureSrc() {
      if (this.$store.state.user.Picture)
        return `${process.env.VUE_APP_SERVER_URL}common/GetFile?externalId=${this.$store.state.user.Picture.ExternalId}`;
      return null;
    },

    stateFixed: {
      get() {
        return this.GET_IS_FIXED;
      },
      set(value) {
        this.SET_FIXED(value);
      },
    },
  },
  mounted() {
    if (this.$refs.navigationDrawer) {
      const context = this;
      this.$refs.navigationDrawer.$el.addEventListener(
        "mouseenter",
        context.handlerHover.bind(null, true)
      );

      this.$refs.navigationDrawer.$el.addEventListener(
        "mouseleave",
        context.handlerHover.bind(null, false)
      );
    }
  },
  beforeDestroy() {
    this.$refs.navigationDrawer.$el.removeEventListener(
      "mouseenter",
      this.handlerHover
    );
    this.$refs.navigationDrawer.$el.removeEventListener(
      "mouseleave",
      this.handlerHover
    );
  },
  created() {
    this.INIT_ROUTES_DATA();
  },
  methods: {
    ...mapActions("routesData", ["INIT_ROUTES_DATA"]),
    ...mapMutations("routesData", ["SET_FIXED", "SET_IS_HOVER"]),

    handlerClickProfile(route) {
      if (route === this.$route.meta.normalizeFullPath) return;
      this.$store.commit("pageTabs/SET_FROM_NAVIGATION", true);
      this.$router.push({ path: route });
    },
    handlerClickTourManager() {
      window.tourManager?.showMenu();
    },
    handlerHover(value) {
      this.SET_IS_HOVER(value);
    },
    async logout() {
      let answer = true;
      if (this.$store.state.pageTabs.tabs.find((e) => e.isUnsaved))
        answer = await this.$confirm("Данные не сохранены! Вы хотите выйти?");

      if (answer) {
        await AuthService.logout();
        this.$store.commit("pageTabs/FORCE_BREAK_UNSAVE");
        document.location.href = "/login";
      }
    },
  },
};
</script>

<style lang="scss">
.floating {
  .v-list {
    padding: 0;
  }
}

.theme--light.v-list-item:hover {
  background-color: var(--v-blue-grey-lighten4);
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
.v-list-item__title {
  color: var(--v-text-base) !important;
}

.v-list-item__title {
  font-size: 0.875rem;
}

.v-list-item__subtitle {
  font-size: 0.75rem;
}

.navigation-drawer__fix-menu.v-input--selection-controls {
  padding-top: 0px !important;
}

.navigation-drawer__fix-menu.v-input--checkbox.v-input--dense {
  margin-top: 0px !important;
}

.navigation-drawer__fix-menu.v-input--dense
  .v-input--selection-controls__ripple {
  width: 20px !important;
  height: 20px !important;
  left: -5px !important;
}

.navigation-drawer__fix-menu.v-input--dense:not(.v-input--switch)
  .v-input--selection-controls__ripple {
  top: -5px !important;
}

.navigation-drawer__fix-menu.v-input--checkbox .v-icon.v-icon--dense {
  font-size: 16px;
}

.navigation-drawer__fix-menu .v-messages {
  display: none;
}

.navigation-drawer__actions {
  display: flex;
  flex-direction: column;
  align-items: center;

  .v-input__slot {
    justify-content: center !important;
  }

  .v-input--selection-controls__input {
    margin-right: 0 !important;
  }
}
</style>
