<template>
  <base-detail-view
    :loading="loading"
    title="Здание"
    :subTitle="this.type === 'create' ? 'Новое здание' : dataSource.Name"
    :isUnsaved="isUnsaved && valid"
    @onRefresh="init"
    @onSave="save"
  >
    <v-form v-model="valid" class="block__content">
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              v-model="dataSource.Name"
              label="Наименование"
              maxlength="100"
              :rules="[rules.required]"
              required
              :readonly="readonly"
              v-up-first-symbol
              :clearable="!readonly"
              :counter="!readonly"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="dataSource.Address"
              label="Адрес"
              maxlength="500"
              :readonly="readonly"
              v-up-first-symbol
              :clearable="!readonly"
              :counter="!readonly"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-form v-model="valid">
      <base-panel class="mt-2" headerHidden>
        <m-tabs v-model="tab" v-if="!loading" ref="mtabs">
          <v-tab key="Partitions">Подразделения</v-tab>
          <v-tab key="Equipments">Медицинские изделия</v-tab>
        </m-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="Partitions" class="mt-2">
            <partitions-list
              :dataSource="dataSource.Partitions"
              :readOnly="readonly"
              @change="changePartitions"
            ></partitions-list>
          </v-tab-item>
          <v-tab-item key="Equipments" class="mt-2">
            <partition-equipments
              :dataSource="dataSource.Equipments"
              :readOnly="readonly"
              @add="changeEquipments"
              show-add-only
            ></partition-equipments>
          </v-tab-item>
        </v-tabs-items>
      </base-panel>
    </v-form>
  </base-detail-view>
</template>

<script>
import BuildingService from "@/services/BuildingService";

import CreateAndEditMixin from "../../mixins/CreateAndEditMixin";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";

import { defaultBuilding } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";

import BaseDetailView from "../../layouts/BaseDetailView.vue";
import BasePanel from "../../layouts/BasePanel.vue";
import mTabs from "@/components/base/tabs/mTabs";
import PartitionsList from "../../components/organization/PartitionsList.vue";
import PartitionEquipments from "../../components/organization/PartitionEquipments.vue";

export default {
  name: "view-BuildingEdit",
  components: {
    BaseDetailView,
    BasePanel,
    PartitionsList,
    PartitionEquipments,
    mTabs,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data() {
    return {
      loading: false,
      valid: false,
      apiService: BuildingService,
      getDefaultObject: defaultBuilding,
      getObjectText: (obj, isNew) => (isNew ? "новое" : obj.Name),
      dataSource: {
        ...defaultBuilding(),
      },
      rules: {
        required: (value) => !!value || "Укажите значение.",
      },
      tab: null,
      readonly: !this.hasPermission(Permissions.PartitionsEdit),
    };
  },
  async created() {
    await this.init();
  },
  methods: {
    changePartitions(value) {
      this.dataSource.Partitions = value;
    },
    changeEquipments(value) {
      this.dataSource.Equipments = [...this.dataSource.Equipments, ...value];
    },
  },
};
</script>
