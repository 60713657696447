<template>
  <base-detail-view
    :loading="loading"
    title="Раздел деятельности"
    :subTitle="
      this.type === 'create' ? 'новый раздел деятельности' : dataSource.Name
    "
    :isUnsaved="isUnsaved && valid"
    @onRefresh="init"
    @onSave="save"
  >
    <v-form v-model="valid" class="block__content">
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              v-model="dataSource.Name"
              label="Название"
              :rules="[rules.required]"
              required
              maxlength="100"
              v-up-first-symbol
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <m-tabs v-model="tab" :slider-size="2" :with-route="false" class="mt-3">
      <v-tab key="Document">Документы</v-tab>
    </m-tabs>

    <v-window v-model="tab">
      <v-window-item>
        <list-document-with-template
          v-model="dataSource.Documents"
          class="mt-2"
          :show-add-in-table="true"
          :readonly="dataSource.OnlyRead"
        ></list-document-with-template>
      </v-window-item>
    </v-window>
  </base-detail-view>
</template>

<script>
import AuditIndicatorCategoryService from "../../services/AuditIndicatorCategoryService";
import { defaultAuditIndicatorCategoriesEdit } from "../../data/defaultObjects";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import BaseDetailView from "../../layouts/BaseDetailView.vue";

export default {
  name: "view-AuditIndicatorCategoriesEdit",
  components: {
    BaseDetailView,
    mTabs: () => import("@/components/base/tabs/mTabs"),
    listDocumentWithTemplate: () =>
      import("@/components/document/ListDocumentWithTemplate.vue"),
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: function () {
    return {
      apiService: AuditIndicatorCategoryService,
      getDefaultObject: defaultAuditIndicatorCategoriesEdit,
      getObjectText: (obj, isNew) => (isNew ? "новый" : obj.Name),
      loading: true,
      valid: false,
      dataSource: { ...defaultAuditIndicatorCategoriesEdit() },
      rules: {
        required: (value) => !!value || "Укажите значение.",
      },
      tab: 0,
    };
  },
  async mounted() {
    await this.init();
  },
};
</script>
