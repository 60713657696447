<template>
  <base-popup
    v-model="dialog"
    title="Добавить существующее здание"
    :icon="UtilGetIcon('view-Buildings')"
    @save="$emit('save', selected)"
    :width="width"
    :height="height"
  >
    <base-list-view
      :headers="headers"
      :dataSource="dataSource"
      :showDelete="false"
      :notShowActions="['add']"
      :search.sync="search"
      title="Здания"
      @input="(value) => (this.selected = value)"
      @onRefresh="init"
    ></base-list-view>
  </base-popup>
</template>

<script>
import BuildingService from "@/services/BuildingService";
import ListEditMixin from "@/mixins/ListEditMixin";
import BasePopup from "@/layouts/BasePopup.vue";
import BaseListView from "@/layouts/BaseListView.vue";

export default {
  name: "view-BuildingsSelect",
  components: {
    BasePopup,
    BaseListView,
  },
  mixins: [ListEditMixin],
  props: {
    value: Boolean,
    filter: Array,
    width: String,
    height: String,
  },
  data: () => {
    return {
      loading: true,
      headers: [
        { text: "Название", value: "Name" },
        {
          text: "Адрес",
          value: "Address",
        },
      ],
      apiService: BuildingService,
      source: null,
      dataSource: [],
      selected: [],
      search: "",
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        if (value) {
          this.init();
        }
        this.search = null;
        this.$emit("input", value);
      },
    },
  },
  watch: {
    filter: function () {
      if (this.source) this.setFilter();
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    DataFromServerNormalize(data) {
      this.source = data;
      this.setFilter();
      return this.dataSource;
    },
    setFilter() {
      if (this.filter && this.filter.length > 0)
        this.dataSource = this.source.filter(
          (item) => this.filter.indexOf(item.Id) === -1
        );
      else this.dataSource = this.source;
    },
  },
};
</script>
