<template>
  <base-popup
    title="Настройка отображения столбцов"
    :persistent="false"
    onlyClose
    v-model="dialog"
  >
    <template slot="actions">
      <v-btn dark color="primary" outlined @click="resetHeaders">
        Сбросить настройки
      </v-btn>
    </template>
    <template v-slot:activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>

    <base-table
      v-model="selected"
      item-key="value"
      hide-default-footer
      :headers="headers"
      :items="items.filter((e) => e.value !== 'actions')"
      class="mt-3"
      :showSettings="false"
      notShowSelect
    >
      <template v-slot:body="scope">
        <transition-group
          tag="tbody"
          ghost-class="ghost"
          handle=".capture-area"
          is="draggable"
          :name="!drag ? 'flip-list' : null"
          @start="drag = true"
          @end="onEnd"
          animation="200"
        >
          <tr v-for="item in scope.items" :key="item.value">
            <td>
              <v-checkbox
                v-model="selected"
                :value="item.value"
                style="margin: 0px; padding: 0px"
                hide-details
              />
            </td>
            <td>{{ item.text }}</td>
            <td>
              <v-btn
                class="capture-area"
                @click.stop.prevent
                icon
                color="blue-grey"
                title="Переместить"
              >
                <m-icon icon="mdi-menu" small></m-icon>
              </v-btn>
            </td>
          </tr>
        </transition-group>
      </template>
    </base-table>
  </base-popup>
</template>
<script>
import { findParent } from "@/utils/DomVueHelper";
import BasePopup from "@/layouts/BasePopup.vue";
import BaseTable from "@/components/base/BaseTable.vue";
import Draggable from "vuedraggable";

export default {
  name: "popup-edit-header",
  components: {
    BasePopup,
    BaseTable,
    Draggable,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drag: false,
      // isInit: false,
      headers: [
        {
          value: "select",
          text: "",
          sortable: false,
          width: "1%",
        },
        {
          value: "text",
          text: "Столбец",
          sortable: false,
        },
        {
          value: "action",
          text: "",
          width: "1%",
          sortable: false,
        },
      ],
      selected: [],
      list: [],
      defaultHeaders: null,
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    originHeaders() {
      return findParent(
        this,
        (e) => {
          return (
            e?.$options?.name !== "popup-edit-header" &&
            e?.headers &&
            !e.$options?.propsData?.headers
          );
        },
        20
      );
    },
    nameData() {
      if (this?.originHeaders) {
        const cName =
          this.originHeaders.$options._componentTag ??
          this.originHeaders.$options.name;
        return "table.headers." + cName;
      }
      return null;
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(val) {
        if (!this.defaultHeaders && val?.length) {
          this.defaultHeaders = this._.cloneDeep(
            val.map((e, i) => ({
              index: i,
              disabled: e.disabled,
              value: e.value,
            }))
          );
        }
        if (val?.length) {
          // this.updateHeaderData();
          this.init();
        }
      },
    },
    selected(val) {
      const item = this.originHeaders;

      for (let i = 0; i < item.headers.length; i++) {
        const index = val.findIndex((e) => e === item.headers[i].value);
        this.$set(item.headers[i], "disabled", index === -1);
      }
    },
  },
  created() {
    if (!this.originHeaders?.headers) return;
    this.originHeaders.headers = this.originHeaders.headers.sort(function (
      a,
      b
    ) {
      if (a.index > b.index) {
        return 1;
      }
      if (a.index < b.index) {
        return -1;
      }
      return 0;
    });
  },
  methods: {
    resetHeaders() {
      for (let i = 0; i < this.originHeaders.headers.length; i++) {
        const item = this.defaultHeaders.find(
          (e) => e.value === this.originHeaders.headers[i].value
        );
        if (item) {
          this.$set(
            this.originHeaders.headers[i],
            "disabled",
            item.disabled ?? false
          );
          this.$set(this.originHeaders.headers[i], "index", item.index);
        }
      }

      this.updateHeaderData();
      this.update();
    },
    update() {
      const item = this.originHeaders;
      item.headers = item.headers.sort(function (a, b) {
        if (a.index > b.index) {
          return 1;
        }
        if (a.index < b.index) {
          return -1;
        }
        return 0;
      });

      this.selected = [];
      for (let i = 0; i < item.headers.length; i++) {
        if (!item.headers[i].disabled)
          this.selected.push(item.headers[i].value);
      }
    },
    updateHeaderData() {
      const item = this.originHeaders;
      if (item?.$options?._componentTag || item.$options.name) {
        localStorage.setItem(
          this.nameData,
          JSON.stringify(
            item.headers.map((e) => ({
              disabled: e.disabled,
              index: e.index,
              value: e.value,
            }))
          )
        );
      }
    },
    array_move(arr, oldIndex, newIndex) {
      if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
      return arr; // for testing
    },
    onEnd(e) {
      this.drag = false;
      const tmp = this.array_move([...this.items], e.oldIndex, e.newIndex);

      const item = this.originHeaders;

      for (let i = 0; i < tmp.length; i++) {
        const index = item.headers.findIndex((e) => e.value === tmp[i].value);
        if (!item.headers[index]) continue;
        this.$set(item.headers[index], "index", i);
      }

      item.headers = item.headers.sort(function (a, b) {
        if (a.index > b.index) {
          return 1;
        }
        if (a.index < b.index) {
          return -1;
        }
        return 0;
      });
    },
    init() {
      // if (this.isInit) return;
      // this.isInit = true;

      this.selected = [];

      const tmp = JSON.parse(localStorage.getItem(this.nameData));

      const item = this.originHeaders;
      if (!item) return;

      for (let i = 0; i < item.headers.length; i++) {
        const data = tmp
          ? tmp.find((e) => e.value === item.headers[i].value)
          : {};

        this.$set(
          item.headers[i],
          "disabled",
          data?.disabled ?? item.headers[i].disabled
        );
        this.$set(item.headers[i], "index", data?.index ?? i);

        if (!item.headers[i].disabled)
          this.selected.push(item.headers[i].value);
      }
    },
  },
};
</script>
<style>
.ghost {
  opacity: 0.5;
  background: var(--v-blue-grey-lighten2);
}
</style>
