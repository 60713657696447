<template>
  <v-form class="mt-2">
    <v-row>
      <v-col>
        <tfoms-partition-table
          v-if="data && data.Divisions"
          :value="selectPartiton"
          :data="data.Divisions"
          :labels="labels"
          :type="3"
          :sub-title="subTitlePeriod"
          :values="[
            {
              label: [
                this.provideUnits.rub.text,
                this.provideUnits.unit.text,
              ].join(' | '),
            },
          ]"
          @input="
            (event) => {
              if (!loading) selectPartiton = event;
            }
          "
        ></tfoms-partition-table>
      </v-col>
      <v-col>
        <tfoms-employee-table
          v-if="data && data.Divisions"
          :value="selectEmployee"
          :selectPartiton="selectPartiton"
          :data="data.Divisions"
          :labels="labels"
          :type="3"
          :items="data.Employees"
          :sub-title="subTitlePeriod"
          :values="[
            {
              label: [
                this.provideUnits.rub.text,
                this.provideUnits.unit.text,
              ].join(' | '),
            },
          ]"
          @input="
            (event) => {
              if (!loading) selectEmployee = event;
            }
          "
        ></tfoms-employee-table>
      </v-col>
    </v-row>

    <div style="padding: 15px 0 12px" class="mt-5" v-if="selectEmployee">
      <div class="d-flex justify-space-between pl-4">
        <div class="d-flex mb-3" style="height: 40px">
          <div>
            <div style="font-weight: bold; line-height: 1.2">
              ДЕТАЛЬНАЯ ИНФОРМАЦИЯ ПО СОТРУДНИКУ
            </div>
            <div class="caption d-flex" style="line-height: 1.4">
              <div class="primary--text">{{ selectEmployee.Name }}</div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="loading" style="position: relative; height: 300px">
        <wrapper-loader></wrapper-loader>
      </div>
      <v-row v-if="details && !loading">
        <v-col>
          <tfoms-bar
            title="ОБЪЕМЫ"
            :data="getValues(details.VolumeValues)"
            icon="mdi-currency-rub"
            :labels="labelsFull"
            :sub-title="subTitlePeriod"
          ></tfoms-bar>
          <tfoms-finance
            title="КОЛИЧЕСТВО САНКЦИЙ"
            class="mt-5"
            :data="
              details.SanctionCountValues.map((e) => [
                e.NonPaymentVolumeValue,
                e.FineVolumeValue,
              ])
            "
            :labels="labelsFull"
            :values="[
              {
                value: details.SanctionVolumeCount,
                label: provideUnits.unit.text,
              },
            ]"
            :sub-title="subTitlePeriod"
          ></tfoms-finance>
        </v-col>
        <v-col>
          <tfoms-bar
            title="ДОХОДЫ"
            :data="
              getValues(
                details.FinanceValues,
                'FinancialPlanValue',
                'FinancialValue'
              )
            "
            icon="mdi-currency-rub"
            factColor="#0CA85D"
            :labels="labelsFull"
            :values="[
              {
                value: details.FinanceTotalValue,
                label: provideUnits.rub.text,
              },
            ]"
            :sub-title="subTitlePeriod"
          ></tfoms-bar>
          <tfoms-finance
            title="СУММА САНКЦИЙ"
            class="mt-5"
            :data="
              details.SanctionValues.map((e) => [e.SanctionValue, e.FineValue])
            "
            :labels="labelsFull"
            :values="[
              {
                value: details.SanctionTotalValue,
                label: provideUnits.rub.text,
              },
            ]"
            :sub-title="subTitlePeriod"
          ></tfoms-finance>
        </v-col>
      </v-row>

      <tfoms-expertise-table
        v-if="details && !loading"
        :data="details.ExpertiseResults"
        :labels="labelsFull"
        :sub-title="subTitlePeriod"
        class="mt-5"
      ></tfoms-expertise-table>
    </div>
  </v-form>
</template>
<script>
import tfomsTab from "../../mixins/tfomsTab";
import TFormService from "@/services/TFormService";

export default {
  name: "view-AnalysisTform-more4",

  components: {
    // tfomsProgress,
    wrapperLoader: () => import("@/components/base/WrapperLoader.vue"),
    tfomsBar: () => import("../../components/tfomsBar.vue"),
    tfomsFinance: () => import("../../components/tfomsFinance.vue"),
    tfomsPartitionTable: () => import("../../components/tfomsPartitionTable"),
    tfomsEmployeeTable: () => import("../../components/tfomsEmployeeTable.vue"),
    tfomsExpertiseTable: () =>
      import("../../components/tfomsExpertiseTable.vue"),
  },
  mixins: [tfomsTab],
  props: {
    data: { type: Object, default: null },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      selectPartiton: null,
      selectEmployee: null,
      details: null,
    };
  },
  computed: {
    labelsFull() {
      const arr = [...this.labels];
      if (this.selectEmployee) arr.unshift(this.selectEmployee.Name);
      if (this.selectPartiton) arr.unshift(this.selectPartiton.Name);

      return arr;
    },
  },
  watch: {
    data() {
      this.update();
    },
    selectEmployee() {
      this.update();
    },
  },
  methods: {
    normolize(data) {
      data.FinanceTotalValue = this.getRub(data.FinanceTotalValue);
      data.SanctionTotalValue = this.getRub(data.SanctionTotalValue);
      data.SanctionVolumeCount = this.getUnit(data.SanctionVolumeCount);

      for (let i = 0; i < data.FinanceValues.length; i++) {
        const item = data.FinanceValues[i];
        item.FinancialPlanValue = this.getRub(item.FinancialPlanValue);
        item.FinancialValue = this.getRub(item.FinancialValue);
        item.FineVolumeValue = this.getUnit(item.FineVolumeValue);
        item.NonPaymentVolumeValue = this.getUnit(item.NonPaymentVolumeValue);
      }
      for (let i = 0; i < data.VolumeValues.length; i++) {
        const item = data.VolumeValues[i];
        item.FineVolumeValue = this.getUnit(item.FineVolumeValue);
        item.NonPaymentVolumeValue = this.getUnit(item.NonPaymentVolumeValue);
        item.VolumePlanValue = this.getUnit(item.VolumePlanValue);
        item.VolumeValue = this.getUnit(item.VolumeValue);
      }

      for (let i = 0; i < data.SanctionValues.length; i++) {
        const item = data.SanctionValues[i];
        item.FineValue = this.getRub(item.FineValue);
        item.FineVolumeValue = this.getUnit(item.FineVolumeValue);
        item.NonPaymentValue = this.getRub(item.NonPaymentValue);
        item.NonPaymentVolumeValue = this.getUnit(item.NonPaymentVolumeValue);
        item.SanctionValue = this.getRub(item.SanctionValue);
      }

      for (let i = 0; i < data.ExpertiseResults.length; i++) {
        const item = data.ExpertiseResults[i];
        item.FineValue = this.getRub(item.FineValue);
        item.NonPaymentValue = this.getRub(item.NonPaymentValue);
      }

      return data;
    },
    async update() {
      if (this.selectEmployee) {
        this.loading = true;
        this.details = this.normolize(
          (
            await TFormService.tfoms1.GetEmployeeDetail({
              ...this.queryParams.params,
              treeId: this.selectPartiton?.TreeId,
              employeeId: this.selectEmployee?.Id,
            })
          ).data
        );
        this.loading = false;
      }
    },
  },
};
</script>
