<template>
  <v-layout>
    <base-list-view
      :dataSource="dataSource"
      :headers="headers"
      :loading="loading"
      @onRefresh="init"
      @onDelete="del"
      :disabledAdd="readonly"
      :showDelete="!readonly"
      :showCopy="!readonly"
      :selectedItems.sync="selected"
      pageEditName="EquipmentEdit"
      title="Медицинские изделия"
      label-text-btn-create="Добавить мед. изделие"
      label-text-search="Поиск мед. изделия"
      :showFilters="true"
      :multiple-save="
        hasImportPermissions
          ? [
              { value: 'new', text: 'Добавить мед. изделие' },
              { value: 'import', text: 'Загрузить из файла' },
            ]
          : null
      "
      @add="add"
    >
      <v-btn
        slot="action"
        v-if="!readonly"
        @click="dialog = true"
        :disabled="!isSelected"
        icon
        title="Изменить"
      >
        <m-icon icon="tool-edit" s16></m-icon>
      </v-btn>
      <report-activator
        slot="action"
        :report="[
          'EquipmentStandartExecutionReport',
          'EquipmentsReport',
          'EquipmentsValidationReport',
          'EquipmentsServiceReport',
        ]"
      />
      <template #item.ValidationNextDate="{ item }">
        <div :class="processDateClass(item.ValidationNextDate)">
          {{ item.ValidationNextDate | DateShort }}
        </div>
      </template>
      <template #item.ServiceNextDate="{ item }">
        <div :class="processDateClass(item.ServiceNextDate)">
          {{ item.ServiceNextDate | DateShort }}
        </div>
      </template>
    </base-list-view>
    <import-equipments :dialog.sync="dialogImportFile" @update="init" />
    <base-popup
      title="Установить"
      :icon="UtilGetIcon('view-Equipments')"
      :width="'40vw'"
      :height="'20vw'"
      @save="save"
      v-model="dialog"
      buttonSaveText="Применить"
    >
      <v-container>
        <v-row>
          <v-col>
            <base-date-picker
              v-model="validationDate"
              label="Дата последней поверки"
              clearable
            ></base-date-picker>
            <base-date-picker
              v-model="serviceDate"
              label="Дата последнего обслуживания"
              clearable
            ></base-date-picker>
          </v-col>
        </v-row>
      </v-container>
    </base-popup>
  </v-layout>
</template>

<script>
import EquipmentService from "../../services/EquipmentService";
import Permissions from "../../data/permissions";
import DataHelper from "../../utils/DataHelper";
import ListEditMixin from "../../mixins/ListEditMixin";
import BaseListView from "../../layouts/BaseListView.vue";
import ReportActivator from "../../components/report/ReportActivator.vue";
import BasePopup from "../../layouts/BasePopup.vue";
import BaseDatePicker from "../../components/base/BaseDatePicker.vue";
import ImportEquipments from "./components/ImportEquipments.vue";

export default {
  name: "view-Equipments",
  components: {
    BaseListView,
    ReportActivator,
    BasePopup,
    BaseDatePicker,
    ImportEquipments,
  },
  mixins: [ListEditMixin],
  data: function () {
    return {
      loading: true,
      apiService: EquipmentService,
      headers: [
        { text: "Название", value: "Name" },
        { text: "Рег. номер", value: "RegNum", notGroup: true },
        { text: "Инв. номер", value: "Num", notGroup: true },
        {
          text: "Места использования",
          value: "Partitions",
          dataType: "array",
          displayText: (p) => p.Name,
          width: "0%",
        },
        {
          text: "Подлежит поверке",
          value: "ValidationNextDate",
          dataType: "Date",
          align: "center",
        },
        {
          text: "Подлежит ТО",
          value: "ServiceNextDate",
          dataType: "Date",
          align: "center",
        },
        { text: "Примечание", value: "Comment", notGroup: true },
      ],
      dataSource: [],
      hasImportPermissions: this.hasPermission(Permissions.EquipmentsImport),
      readonly: !(
        this.hasPermission(Permissions.EquipmentsEditAll) ||
        this.hasPermission(Permissions.EquipmentsEdit)
      ),
      selected: [],
      dialog: false,
      validationDate: null,
      serviceDate: null,
      dialogImportFile: false,
    };
  },
  computed: {
    isSelected() {
      return this.selected.length > 0;
    },
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.serviceDate = null;
        this.validationDate = null;
      }
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    DataFromServerNormalize(data) {
      for (const equipment of data) {
        equipment.ValidationPeriod = parseInt(equipment.ValidationPeriod);
        equipment.ValidationNextDate = this.getNextDate(
          equipment.IsValidationRequired,
          equipment.ValidationDate,
          equipment.ValidationPeriod
        );
        equipment.ServiceNextDate = this.getNextDate(
          equipment.IsServiceRequired,
          equipment.ServiceDate,
          equipment.ServicePeriod
        );
      }
      return data;
    },
    getNextDate(required, date, period) {
      if (required) {
        const d = DataHelper.toDate(date);
        if (d) return DataHelper.AddMonths(d, period ?? 0);
      }
      return null;
    },
    processDateClass(date) {
      if (date && date < DataHelper.today()) return { "error--text": true };
      else {
        return { "success--text": true };
      }
    },
    async save() {
      await this.apiService.updateMultiple(
        this.validationDate,
        this.serviceDate,
        this.selected.map((s) => s.Id)
      );
      this.dialog = false;
    },
    add(item) {
      if (item.value === "import") {
        this.dialogImportFile = true;
      }
    },
  },
};
</script>
