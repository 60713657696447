<template>
  <base-list-view
    @onRefresh="init"
    @onDelete="del"
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    :disabledAdd="readonly"
    :showDelete="!readonly"
    show-actions
    pageEditName="UnwishedEventEdit"
    title="Нежелательные события"
    label-text-btn-create="Добавить событие"
    label-text-search="Поиск события"
    group-by="DateYear"
    group-desc
    groupOpenType="showLast"
    :sort-by="['Date']"
    sort-desc
    :showFilters="true"
  >
    <report-activator
      v-if="$store.state.Organization.OwnerType === 2"
      slot="action"
      report="ParentUnwishedEventsReport"
    />
    <report-activator v-else slot="action" report="UnwishedEventsReport" />
    <div row slot="action" class="align-center d-inline-flex">
      <label
        class="tasks__action-show-all v-label blue-grey--text mr-1"
        style="font-size: 14px"
      >
        Скрыть завершенные
      </label>
      <v-checkbox
        v-model="hideCompleted"
        @change="init"
        hide-details
      ></v-checkbox>
    </div>
    <template #group.header="{ group, items }">
      <span class="subtitle-2">{{ group }} ({{ items.length }})</span>
    </template>
    <template v-slot:item.actions="{ item }">
      <report-activator
        report="UnwishedEventCardReport"
        :params="{ objectId: { name: 'pUnwishedEvent', value: item.Id } }"
      />
    </template>
    <template slot="item.Owner" slot-scope="{ item }">
      {{
        item.Owner.Id !== $store.state.Organization.OwnerId
          ? item.Owner.Name
          : ""
      }}
    </template>
    <template slot="item.Done" slot-scope="{ item }">
      {{ item.Done ? item.Done.substr(0, 10) : "" }}
    </template>
    <template slot="item.Type.Owner" slot-scope="scope">
      <template v-if="scope.item.Shared">
        <span>
          {{
            scope.item.OwnerName !== scope.item.Type.Owner.Name
              ? scope.item.Type.Owner.Name
              : "-"
          }}
        </span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <m-icon
              v-bind="attrs"
              v-on="on"
              icon="mdi-check-circle-outline"
              small
              color="primary"
              style="cursor: pointer"
            ></m-icon>
          </template>
          <span v-html="'Опубликовано'"></span>
        </v-tooltip>
      </template>
      <span v-else>
        {{
          scope.item.OwnerName !== scope.item.Type.Owner.Name
            ? scope.item.Type.Owner.Name
            : "-"
        }}
      </span>
    </template>
  </base-list-view>
</template>

<script>
import UnwishedEventService from "../../services/UnwishedEventService";
import Permissions from "../../data/permissions";
import DataHelper from "../../utils/DataHelper";
import ListEditMixin from "../../mixins/ListEditMixin";
import BaseListView from "../../layouts/BaseListView.vue";
import ReportActivator from "../../components/report/ReportActivator";
import Vue from "vue";

export default {
  name: "view-UnwishedEvents",
  components: { BaseListView, ReportActivator },
  mixins: [ListEditMixin],
  data: function () {
    return {
      loading: true,
      apiService: UnwishedEventService,
      headers: [
        {
          text: "Дата",
          value: "Date",
          dataType: "Date",
          displayText: Vue.filter("DateShort"),
          width: "1%",
        },
        this.$store.state.Organization.OwnerType === 2
          ? {
              text: "Медицинская организация",
              value: "Owner",
              dataType: "object",
              displayText: (item) => item.Name,
            }
          : null,
        {
          text: "Подразделение / Место",
          value: "PlaceDisplay",
          displayText: (e) => (e !== null ? e : '"другое место"'),
          width: "1%",
        },
        {
          text: "Ф.И.О. пострадавшего",
          value: "InjuredPerson",
          width: "1%",
        },
        {
          text: "Вид нежелательного события",
          value: "Type",
          dataType: "object",
          displayText: (type) => type?.Name,
          width: "1%",
        },
        {
          text: "Что произошло",
          value: "Content",
          notGroup: true,
        },
        {
          text: "Последствия",
          value: "ConsequenceType",
          dataType: "object",
          displayText: (type) => type?.Description,
        },
        {
          text: "Результат разбора",
          value: "Result",
          notGroup: true,
        },
        {
          text: "Примечание",
          value: "Comment",
          notGroup: true,
        },
        {
          text: "Дата закрытия",
          value: "Done",
          dataType: "Date",
          align: "center",
          width: "1%",
        },
        this.$store.state.Organization.OwnerType === 1
          ? {
              text: "Головная организация",
              value: "Type.Owner",
              displayText: (e, item) => {
                return item?.OwnerName !== e?.Name ? e?.Name : "-";
              },
              align: "center",
            }
          : null,
      ].filter(Boolean),
      dataSource: [],
      hideCompleted: false,
    };
  },
  computed: {
    readonly() {
      return !this.hasPermission(Permissions.UnwishedEventEdit);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.loading = true;

        const res = await UnwishedEventService.get(
          this.hideCompleted === true ? false : null
        );
        this.dataSource = this.DataFromServerNormalize(res.data);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    DataFromServerNormalize(data) {
      for (const item of data) {
        item.DateYear =
          item.Date && DataHelper.toDateTime(item.Date).getFullYear();
        item.PlaceDisplay = item?.Partition?.Name || item?.Place;
      }
      return data;
    },
  },
};
</script>
