<template>
  <base-popup
    title="Параметры импорта чек-листов"
    :icon="UtilGetIcon('view-CheckLists')"
    :width="width"
    :height="'300px'"
    @save="save"
    v-model="dialog"
    buttonSaveText="Загрузить"
  >
    <v-container class="container-space-between">
      <v-form>
        <m-autocomplete
          v-model="indicator"
          item-text="Name"
          item-value="Id"
          dict="AuditIndicators"
          label="Показатель оценки качества"
          required
          clearable
        ></m-autocomplete>
        <v-file-input
          v-model="file"
          outlined
          dense
          hide-details
          placeholder="Выберите файл"
          autofocus
        >
          <template v-slot:selection="{ text }">
            <v-chip small label color="primary">
              {{ text }}
            </v-chip>
          </template>
        </v-file-input>
      </v-form>

      <div>
        <v-btn
          block
          dark
          color="primary"
          outlined
          large
          download
          :href="`${apiUrl}common/GetFileExample?type=0`"
          >Скачать файл шаблона чек-листа
        </v-btn>
      </div>
    </v-container>
  </base-popup>
</template>
<script>
import BasePopup from "../../layouts/BasePopup.vue";
import MAutocomplete from "../base/inputs/mAutocomplete.vue";
import CheckListService from "../../services/CheckListService";

export default {
  components: { BasePopup, MAutocomplete },
  props: {
    value: Boolean,
    width: String,
    height: String,
  },
  data: () => ({
    file: null,
    indicator: null,
  }),
  computed: {
    apiUrl() {
      return process.env.VUE_APP_SERVER_URL;
    },

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async save() {
      try {
        await CheckListService.import(this.indicator, this.file);
        this.file = null;
        this.indicator = null;
        this.$emit("afterLoadFile");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
