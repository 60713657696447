import Api from "./Api";

export default {
  async get(partitionId = 0) {
    return Api().get("organization/GetEquipments", {
      params: { partitionId },
    });
  },
  async show(id) {
    return Api().get("organization/getEquipment", { params: { id } });
  },
  async create(equipment) {
    return Api().post("organization/createEquipment", equipment);
  },
  async update(equipment) {
    return Api().put("organization/updateEquipment", equipment);
  },
  async delete(id) {
    return Api().delete("organization/deleteEquipment", { params: { id } });
  },
  async updateMultiple(validationDate, serviceDate, objectIds) {
    return Api().put("organization/UpdateEquipments", {
      ValidationDate: validationDate,
      ServiceDate: serviceDate,
      ObjectIds: objectIds,
    });
  },
  import(file) {
    const data = new FormData();

    data.append("File", file);

    return Api({ "Content-Type": "multipart/form-data" }).post(
      "exchange/ImportEquipments",
      data
    );
  },
  task(id) {
    return Api().get("exchange/GetImportEquipmentsTask", {
      params: { id },
    });
  },
  tasks() {
    return Api().get("exchange/GetImportEquipmentsTasks ");
  },
};
