<template>
  <base-detail-view
    :loading="loading"
    title="Стандарт оснащения"
    @onRefresh="init"
    :showSave="false"
  >
    <report-activator
      slot="action"
      report="EquipmentReport"
      :params="{
        objectId: { name: 'pId', value: $route.params.objectId },
      }"
    />
    <v-form class="block__content">
      <v-container>
        <v-row>
          <v-col>
            <v-text-field :value="dataSource.Name" label="Название" readonly>
            </v-text-field>
            <v-text-field
              :value="dataSource.NormativeDocument"
              label="Нормативный документ"
              readonly
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :value="dataSource.ProfileName"
              label="Профиль"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <base-list-view
      class="mt-2"
      title="Записи"
      :dataSource="dataSource.Records"
      :headers="recordsHeaders"
      header-hidden
      hide-default-footer
      isNested
    >
    </base-list-view>
  </base-detail-view>
</template>

<script>
import ReportActivator from "../../components/report/ReportActivator.vue";
import EquipmentStandartsService from "../../services/EquipmentStandartsService";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin.js";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import BaseListView from "../../layouts/BaseListView.vue";

export default {
  name: "view-EquipmentStandartView",
  components: { BaseDetailView, BaseListView, ReportActivator },
  mixins: [CreateAndEditMixin],
  data: () => {
    return {
      loading: true,
      apiService: EquipmentStandartsService,
      getObjectText: (obj) => obj.Name,
      dataSource: {
        Records: [],
      },
      recordsHeaders: [
        { text: "№", value: "Num", width: "8%", sortable: false },
        { text: "Название", value: "Name" },
        { text: "Количество", value: "Quantity" },
        { text: "Примечние", value: "Comment" },
      ],
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    DataFromServerNormalize(data) {
      data.ProfileName = data.Profile?.Name;
      return data;
    },
  },
};
</script>
