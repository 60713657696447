<template>
  <base-popup
    title="Редактировать вопрос"
    :icon="UtilGetIcon('view-CheckLists')"
    @save="save"
    v-model="dialog"
    :width="width"
    :height="height"
    :hideSave="readonly"
  >
    <v-form ref="questionForm" v-model="valid" class="pt-4">
      <v-textarea
        v-model="dataSource.Content"
        label="Название"
        name="Сontent"
        counter
        auto-grow
        rows="1"
        maxlength="2000"
        :readonly="readonly"
        v-up-first-symbol
        required
        :rules="[rules.required]"
      ></v-textarea>

      <v-textarea
        v-model="dataSource.Comment"
        label="Комментарий"
        name="Сomment"
        counter
        auto-grow
        rows="1"
        maxlength="1000"
        :readonly="readonly"
        v-up-first-symbol
        :clearable="!readonly"
      ></v-textarea>

      <v-checkbox
        v-model="dataSource.IsRequired"
        label="Обязательно"
        :readonly="readonly"
      ></v-checkbox>

      <m-tabs v-model="tab" :slider-size="2" :with-route="false" class="mt-3">
        <v-tab key="Violation">Несоответствия</v-tab>
        <v-tab key="Document">Документы</v-tab>
      </m-tabs>

      <v-window v-model="tab">
        <v-window-item>
          <typical-violation-table
            class="mt-2"
            :items="dataSource.TypicalViolations"
            simple
            :readOnly="readonly"
          >
          </typical-violation-table>
        </v-window-item>
        <v-window-item>
          <list-document-with-template
            v-model="dataSource.Documents"
            class="mt-2"
            :show-add-in-table="true"
            :readonly="readonly"
          ></list-document-with-template>
        </v-window-item>
      </v-window>
    </v-form>
  </base-popup>
</template>

<script>
import BasePopup from "../../../layouts/BasePopup.vue";
import TypicalViolationTable from "../../CheckList/Question/violation/TypicalViolationTable.vue";

export default {
  name: "view-TaskAdd",
  components: {
    mTabs: () => import("@/components/base/tabs/mTabs"),
    listDocumentWithTemplate: () =>
      import("@/components/document/ListDocumentWithTemplate.vue"),
    BasePopup,
    TypicalViolationTable,
  },
  provide() {
    return {
      editingItems: this.editingItems,
    };
  },
  props: {
    question: Object,
    value: Boolean,
    width: {
      type: String,
      default: "80vw",
    },
    height: {
      type: String,
      default: "80vh",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      tab: 0,
      dataSource: {},
      tasks: [],
      selectedTasks: [],
      search: "",
      content: "",
      comment: "",
      valid: false,
      editingItems: [],
      rules: {
        required: (value) => !!value || "Укажите значение.",
      },
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.dataSource = this._.cloneDeep(this.question);
      }
    },
  },
  methods: {
    save() {
      this.$refs.questionForm.validate();
      if (!this.valid) return;

      this.dialog = false;
      this.$emit("save", this.dataSource);
    },
  },
};
</script>

<style></style>
