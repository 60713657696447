<template>
  <base-list-view
    :selectedItems="selectedItems"
    :dataSource="value"
    :headers="headers"
    isNested
    :header-hidden="!isSelect"
    :hide-default-footer="!isSelect"
    v-on="$listeners"
    v-bind="$attrs"
    :notShowSelect="!isSelect"
    ref="listView"
    textCustomAdd="Добавить документ"
    sort-by="Date"
    :sort-desc="[true]"
    @customHandle="hadlerClick"
    @customAdd="showDialog = true"
    :item-class="
      (item) => {
        return item.Status === 6 ? 'disabled' : '';
      }
    "
    :custom-filter="customFilter"
  >
    <template slot="item.Date" slot-scope="{ item }">
      {{ item.Date | dateOnly }}
    </template>
    <popup-document
      v-if="$attrs['show-add-in-table']"
      v-model="showDialog"
      :show-add-in-table="false"
      @save="save"
      :selectedItems="value"
    ></popup-document>
  </base-list-view>
</template>
<script>
import { OrganizationDocumentStatus } from "@/data/enums";
export default {
  components: {
    BaseListView: () => import("@/layouts/BaseListView.vue"),
    PopupDocument: () => import("./PopupDocument.vue"),
  },
  props: {
    selectedItems: { Array, default: () => [] },
    value: {
      type: Array,
      default: () => [],
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
    customFilter: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      showDialog: false,
      employees: [],
      headers: [
        {
          text: "Тип документа",
          value: "Type.Name",
          width: 220,
        },
        { text: "Номер", value: "Num", width: "1%" },
        {
          text: "Дата",
          value: "Date",
          dataType: "Date",
          width: "1%",
          align: "center",
        },
        { text: "Наименование", value: "Name", notGroup: true },
        {
          text: "Статус",
          value: "Status",
          dataType: "enum",
          options: OrganizationDocumentStatus,
          align: "center",
          width: 180,
        },
      ],
    };
  },
  async mounted() {
    // this.employees = await this.LOAD_EMPLOYEES();
  },
  methods: {
    save(items) {
      for (let i = 0; i < items.length; i++) {
        if (!this.value.find((e) => e.Id === items[i].Id))
          this.value.push({ ...items[i], isNew: true });
      }
      this.showDialog = false;
    },
    hadlerClick(e) {
      this.$router.push({
        name: "LocalDocumentEdit",
        params: { objectId: e.Id },
      });
    },
  },
};
</script>
