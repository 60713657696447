<template>
  <div class="hero__meeting meeting">
    <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
    <div class="meeting__wrapper" v-else>
      <div class="meeting__content text-center">
        <h2 class="meeting__title blue-grey--text">
          {{ getTimesOfDay }},
          <span>
            {{ $store.state.user.Employee | PersonFirstAndMiddleName }}!
          </span>
        </h2>
        <div class="meeting__text">
          <template v-if="hasTodos">
            <p>Что запланировано на сегодня:</p>
            <span v-for="(todo, index) in filteredTodos" :key="todo.id">
              {{ todo.start }}
              <router-link :to="todo.link" class="meeting__link">
                {{ todo.count }}
                {{ getLinkEnd(todo.count, todo.ends) }}
              </router-link>
              <template v-if="index < filteredTodos.length - 1">,</template>
            </span>
          </template>
          <template v-else>
            <p>
              Ваш день открыт для любых начинаний — ничего срочного на сегодня
              не запланировано
            </p>
          </template>
          <p class="meeting__mission blue-grey--text">
            {{
              missionOfOrganization
                ? `Наша миссия: ${missionOfOrganization}`
                : "Хорошего рабочего дня!"
            }}
          </p>
        </div>
      </div>
      <div class="meeting__img">
        <img
          :src="require('@/assets/meeting.svg')"
          alt="Картинка приветствия"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MeetingCard",
  props: {
    mission: {
      type: Object,
      default: () => {},
    },
    todos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      todosList: [],
      missionOfOrganization: null,
      loading: true,
    };
  },
  computed: {
    getTimesOfDay() {
      const date = new Date().getHours();
      if (date < 12) return "Доброе утро";
      if (date < 18) return "Добрый день";
      if (date < 22) return "Добрый вечер";
      return "Доброй ночи";
    },
    hasTodos() {
      return Object.values(this.todosList).some((el) => el.count > 0) || false;
    },
    filteredTodos() {
      return this.todosList.filter((todo) => todo.count > 0);
    },
  },
  watch: {
    mission() {
      this.loading = true;
      this.init();
    },
    todos() {
      this.loading = true;
      this.init();
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      try {
        const { data: todos } = await this.todos.request;
        const { data: mission } = await this.mission.request;

        this.todosList = this.createTodosLinksWithCount(todos);
        this.missionOfOrganization = mission.Mission;

        this.loading = false;
      } catch (e) {
        console.debug(e);
      }
    },
    createTodosLinksWithCount(todos) {
      return [
        {
          id: 1,
          link: { name: "LocalDocuments", params: { type: "my" } },
          start: "работа с",
          count: todos.DocumentsCount,
          ends: ["документом", "документами", "документами"],
        },
        {
          id: 2,
          link: { name: "Tasks", params: { type: "my" } },
          start: "выполнение",
          count: todos.TasksCount,
          ends: ["задачи", "задач", "задач"],
        },
        {
          id: 3,
          link: { name: "Tasks", params: { type: "control" } },
          start: "на контроле",
          count: todos.TasksOnControlCount,
          ends: ["задача", "задачи", "задач"],
        },
        {
          id: 4,
          link: { name: "AuditOperations", params: { type: "my" } },
          start: "участие в",
          count: todos.AuditsCount,
          ends: ["проверке", "проверках", "проверках"],
        },
        {
          id: 5,
          link: { name: "KnowledgeBaseMy" },
          start: "прохождение",
          count: todos.CoursesCount,
          ends: ["курса", "курсов", "курсов"],
        },
        {
          id: 6,
          link: { name: "StatisticIndicators" },
          start: "ввод",
          count: todos.StatisticIndicatorCount,
          ends: [
            "значения стат.показателя",
            "значений стат.показателя",
            "значений стат.показателя",
          ],
        },
      ];
    },
    getLinkEnd(number, ends) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return ends[2];
      }
      n %= 10;
      if (n === 1) {
        return ends[0];
      }
      if (n >= 2 && n <= 4) {
        return ends[1];
      }
      return ends[2];
    },
  },
};
</script>

<style lang="scss">
.meeting {
  display: flex;
  flex: calc(100% * 2 / 3);
  height: 100%;
  flex-direction: column;
  position: relative;

  &__wrapper {
    background-color: var(--v-blue-grey-lighten2);
    border-radius: 10px;
    padding: 20px 50px;
  }

  &__content {
    width: calc(100% - 200px);
  }

  &__title {
    font-size: 24px;
    font-weight: 500;

    span {
      font-weight: 600;
    }
  }

  &__text,
  &__mission {
    font-size: 14px;
    margin-top: 15px;
  }

  &__text p {
    margin-bottom: 0;
  }

  .v-application &__link {
    text-decoration: unset;
    color: var(--v-anchor-base) !important;

    &:hover {
      text-decoration: underline;
    }
  }

  &__img {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 180px;
    height: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (width <= 1440px) {
      top: -5px;
      right: 5px;
    }
  }

  @media (width <= 1440px) {
    width: 100%;
    overflow: unset;
  }
}
</style>
