<template>
  <v-layout>
    <base-list-view
      :title="'Образцы документов'"
      label-text-btn-create="Создать образец"
      :notShowActions="
        !hasPermission(Permissions.DocumentSampleEdit) ? ['add', 'delete'] : []
      "
      :label-text-search="'Поиск образца документа'"
      @onRefresh="init"
      @onDelete="del"
      :dataSource="dataSource"
      :headers="headers"
      :loading="loading"
      pageEditName="DocumentTemplateEdit"
      :exclude-settings-actions="['group']"
      :selectedItems.sync="selected"
      @toggle-select-all="selectAllToggle"
      :notShowSelect="readonly"
      :sort-by.sync="sortBy"
      :item-class="
        (item) => {
          return {
            disabled: item.Attribute.Disabled,
          };
        }
      "
      :showFilters="true"
      :options.sync="options"
      :server-items-length="totalCount"
      group-by="ActivitySection.Name"
      server-options
      show-groups
      :paramsHandleClick="{ type: 'sample' }"
    >
      <v-btn
        v-if="!readonly"
        slot="action"
        @click="handlePublish"
        dark
        color="primary"
        class="ml-4"
        :title="Опубликовать"
        :disabled="!selected.length"
      >
        {{ !dataSource.Published ? "Опубликовать" : "Отменить публикацию" }}
      </v-btn>
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          :value="isSelected"
          :ripple="false"
          :disabled="item.IsSystem || item.Attribute.Disabled"
          @input="select($event)"
        ></v-simple-checkbox>
      </template>
    </base-list-view>
  </v-layout>
</template>

<script>
import Vue from "vue";
import ListEditMixin from "../../mixins/ListEditMixin";
import PaginationListMixin from "../../mixins/PaginationListMixin";
import DocumentTemplateService from "@/services/document/DocumentTemplateService";
import BaseListView from "../../layouts/BaseListView.vue";
import Permissions from "@/data/permissions";

export default {
  name: "view-DocumentSample",
  components: {
    BaseListView,
  },
  mixins: [ListEditMixin, PaginationListMixin],
  data: function () {
    return {
      apiService: DocumentTemplateService,
      showCompleted: false,
      loading: true,
      readonly: !this.hasPermission(Permissions.DocumentSampleEdit),
      sortBy: ["Name"],
      headers: [
        {
          text: "Название",
          value: "Name",
          notGroup: true,
        },
        {
          text: "Тип",
          value: "DocumentType.Name",
          displayText: (e) => e,
          dataType: "object",
          width: "20%",
          dict: { serverName: "DocumentTemplate.DocumentType.Name" }, // Для фильтра. Загрузка словаря с сервера, с пагинацией
        },
        {
          text: "Автор",
          value: "Owner.Name",
          displayText: (e) => e,
          dataType: "object",
          dict: { serverName: "DocumentTemplate.Owner.Name" }, // Для фильтра. Загрузка словаря с сервера, с пагинацией
        },
        {
          text: "Раздел деятельности",
          value: "ActivitySection.Name",
          displayText: (e) => e,
          dataType: "object",
          dict: { serverName: "DocumentTemplate.Section.Name" }, // Для фильтра. Загрузка словаря с сервера, с пагинацией
        },
        {
          text: "Опубликован",
          value: "Published",
          width: "1%",
          dataType: "boolean",
        },
      ],
      dataSource: [],
      selected: [],
    };
  },
  computed: {
    Permissions() {
      return Permissions;
    },
    _apiServiceParams() {
      return {
        type: "sample",
        archive: this.showCompleted,
      };
    },
    pageType() {
      return this.currentRouter.params?.type;
    },
  },
  watch: {
    showCompleted: function (val) {
      if (val && !this.headers.find((e) => e.value === "Archived")) {
        this.headers.push({
          text: "Добавлено в архив",
          value: "Archived",
          dataType: "Date",
          displayText: Vue.filter("DateShort"),
          align: "center",
          notGroup: true,
        });
      } else if (!val && this.headers.find((e) => e.value === "Archived")) {
        this.headers = this.headers.filter((e) => e.value !== "Archived");
      }
    },
  },
  mounted() {},
  methods: {
    async handlePublish() {
      this.loading = true;
      try {
        await DocumentTemplateService.publish(this.selected.map((e) => e.Id));
        for (let i = 0; i < this.selected.length; i++) {
          this.selected[i].Published = true;
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    selectAllToggle() {
      const selectedCount = this.selected.length;
      const selectableCount = this.dataSource.filter(
        (item) => !item.IsSystem && !item.Attribute.Disabled
      ).length;

      if (selectedCount !== selectableCount) {
        this.selected = this.dataSource.filter(
          (item) => !item.IsSystem && !item.Attribute.Disabled
        );
      } else {
        this.selected = [];
      }
    },
    DataFromServerNormalize(data) {
      return data;
    },
    async loadingDataSource() {
      let res;
      try {
        this.loading = true;

        res = this.DataFromServerNormalize(
          await this.groupInit(this._apiServiceParams)
        );
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
      if (this.virtualPage !== 1) {
        this.dataSource = [...this.dataSource, ...res];
      } else this.dataSource = res;
    },
    async init() {
      this.resetPagination();
      this.loadingDataSource();
    },
  },
};
</script>
<style scoped></style>
