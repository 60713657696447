<template>
  <base-popup
    title="Выбор полей карточки документа"
    :icon="UtilGetIcon('view-DocumentTemplates')"
    @save="save"
    v-model="dialog"
    :width="width"
    :height="height"
  >
    <template slot="activator" slot-scope="scope">
      <v-btn class="mr-1" color="primary" dark v-on="scope.on">
        <m-icon icon="mdi-pencil" left></m-icon>
        Заполнить карточку документа
      </v-btn>
    </template>
    <base-table
      v-model="selected"
      class="mt-2"
      item-key="Name"
      hide-default-footer
      :items="itemsWithDisabled"
      :headers="headers"
      @toggle-select-all="selectAllToggle"
    >
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          :value="isSelected"
          :readonly="item.disabled"
          :disabled="item.disabled"
          @input="select($event)"
          :ripple="false"
        ></v-simple-checkbox>
      </template>
      <template slot="header.DisplayName">
        Выбрано {{ selected.length }} из {{ fields.length }}
      </template>
    </base-table>
  </base-popup>
</template>
<script>
import BaseTable from "../../../components/base/BaseTable.vue";
import BasePopup from "../../../layouts/BasePopup.vue";
export default {
  components: { BasePopup, BaseTable },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    width: String,
    height: String,
  },
  data() {
    return {
      headers: [{ text: "Название", value: "DisplayName" }],
      dialog: false,
      selected: [],
    };
  },
  computed: {
    itemsWithDisabled() {
      return this.fields.map((e) => ({
        ...e,
        disabled: e.Name === "Podpisyvaet_utverzhdaet",
      }));
    },
  },
  watch: {
    items: function () {
      this.selected = this.items;
    },
  },
  methods: {
    save() {
      this.$emit("save", this.selected);
      this.dialog = false;
    },
    selectAllToggle() {
      const selectedCount = this.selected.length;
      const selectableCount = this.fields.length;

      if (selectedCount !== selectableCount) {
        this.selected = this.fields;
      } else {
        this.selected = this.fields.filter(
          (item) => item.Name === "Podpisyvaet_utverzhdaet"
        );
      }
    },
  },
};
</script>
