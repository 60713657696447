<template>
  <v-layout>
    <base-list-view
      show-expand
      show-actions
      :label-text-btn-create="
        pageType === 'projects' ? 'Добавить проект' : 'Создать план мероприятий'
      "
      label-text-search="Поиск проекта"
      @onRefresh="init"
      @onDelete="del"
      :pageEditName="pageType === 'projects' ? 'ProjectEdit' : 'PlanEdit'"
      :addParams="{ pageType }"
      :title="title"
      :dataSource="dataSource"
      :headers="headers"
      :loading="loading"
      :disabledAdd="readOnly"
      :showDelete="!readOnly"
      :showFilters="true"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <project-table-expanded :headers="headers" :Project="item" />
      </template>
      <template v-slot:item.actions="{ item }">
        <report-activator
          :report="['ProjectPlanReport']"
          :params="{ objectId: { name: 'pPlanId', value: item.Id } }"
        />
      </template>
      <template slot="item.Owner" slot-scope="{ item }">
        <template v-if="$store.state.Organization.Name !== item.Owner.Name">
          {{ item.Owner.Name }}
        </template>
        <template v-else> - </template>
      </template>
      <template slot="item.AvailableFor" slot-scope="{ item }">
        <v-tooltip
          bottom
          v-if="item.AvailableFor && item.AvailableFor.HeadOrganization"
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              :style="!item.Shared ? 'color: var(--v-text-lighten2)' : ''"
            >
              {{ item.AvailableFor.Name }}
            </span>
          </template>
          <span>
            {{
              `План ${
                !item.Shared ? "не" : ""
              } доступен для головной организации`
            }}
          </span>
        </v-tooltip>
        <template v-else> - </template>
      </template>
    </base-list-view>
  </v-layout>
</template>

<script>
import Vue from "vue";
import ProjectService from "@/services/ProjectService";
import Permissions from "../../data/permissions";
import ListEditMixin from "@/mixins/ListEditMixin";
import DataHelper from "@/utils/DataHelper";

import BaseListView from "@/layouts/BaseListView";
import ProjectTableExpanded from "@/components/task/ProjectTableExpanded.vue";
import ReportActivator from "@/components/report/ReportActivator.vue";

export default {
  name: "view-Projects",
  components: {
    BaseListView,
    ProjectTableExpanded,
    ReportActivator,
  },

  filters: {
    formatDate: function (value) {
      return DataHelper.toDate(value)?.toLocaleDateString();
    },
  },
  mixins: [ListEditMixin],
  data() {
    return {
      // Api
      apiService: ProjectService,

      loading: true,
      headers: [
        {
          text: "Дата начала",
          value: "Period.DateIn",
          dataType: "Date",
          displayText: Vue.filter("DateShort"),
          cellClass: "td-minimum text-no-wrap",
        },
        {
          text: "Дата окончания",
          value: "Period.DateOut",
          dataType: "Date",
          displayText: Vue.filter("DateShort"),
          cellClass: "td-minimum text-no-wrap",
        },
        {
          text: "Название",
          value: "Name",
          notGroup: true,
        },
        {
          text: "Ответственный",
          value: "Responsible",
          dataType: "object",
          displayText: Vue.filter("PersonShortName"),
          cellClass: "td-minimum text-no-wrap",
        },
      ],
      dataSource: [],
      readOnly:
        !this.hasPermission(Permissions.ProjectsEdit) &&
        !this.hasPermission(Permissions.AllProjectsEdit),
    };
  },

  computed: {
    pageType() {
      let result = "plans";
      if (this.currentRouter.fullPath === "/activity/projects/view") {
        result = "projects";
      }
      return result;
    },
    title() {
      return this.pageType === "projects" ? "Проекты" : "Планы мероприятий";
    },
  },
  async created() {
    await this.init();
  },
  mounted() {
    if (this.pageType !== "projects") {
      if (this.$store.state.Organization.OwnerType === 2) {
        this.headers.push({
          text: "Медицинская организация",
          value: "Owner",
          dataType: "object",
          displayText: (el) => el?.Name,
          cellClass: "td-minimum text-break text-wrap",
          align: "center",
        });
      }
      if (this.$store.state.Organization.OwnerType === 1) {
        this.headers.push({
          text: "Доступен для",
          value: "AvailableFor",
          dataType: "object",
          displayText: (el) => el?.Name,
          cellClass: "td-minimum text-break text-wrap",
          align: "center",
        });
      }
    }
  },
  methods: {
    async init() {
      try {
        this.loading = true;

        // Параметры запроса
        const params = {
          type: this.pageType === "projects" ? 0 : 1,
          doneTaskPercent: true,
          fields: 17,
        };

        const res = await ProjectService.get(params);
        this.dataSource = this.DataFromServerNormalize(res.data);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    DataFromServerNormalize(data) {
      if (this.$store.state.Organization.OwnerType === 1) {
        data = this.addFieldAvailableFor(data);
      }
      return data;
    },
    addFieldAvailableFor(data) {
      // Искусственно созданный столбец AvailableFor
      // для отображения род. организации в спис. представлении
      // если есть внешняя проверка
      return data.map((plan) => {
        if (
          plan.AuditOperations.find((operation) => operation.ExternalShared)
        ) {
          plan.AvailableFor = {
            ...plan.AuditOperations.find(
              (operation) => operation.ExternalShared
            ).Owner,
            HeadOrganization: true,
          };
        } else {
          plan.AvailableFor = { ...plan.Owner, HeadOrganization: false };
        }
        return plan;
      });
    },
  },
};
</script>

<style></style>
