var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-table',_vm._g(_vm._b({ref:"base-table",attrs:{"headers":_vm.headersInternal,"items":_vm.filteredDataSource,"loading":_vm.loading,"searchText":_vm.searchText,"options":_vm.options,"serverOptions":_vm.serverOptions},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([_vm._l((_vm.scopeHeaders),function(header){return {key:`header.${header.value}`,fn:function(){return [_c('span',{key:header.value,attrs:{"title":header.tooltip}},[_vm._v(" "+_vm._s(header.text)+" ")])]},proxy:true}}),_vm._l(([
        ...Object.keys(_vm.getPrefixedScopedSlots('item.', _vm.$scopedSlots))
          .filter((e) => e !== 'actions')
          .map((e) => 'item.' + e),
      ]),function(slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,{"rowEditing":_vm.rowEditing,"rowEditingIndex":_vm.rowEditingIndex,"closeEdit":_vm.closeEdit,"itemEdited":_vm.rowEditing && scope.item && scope.item.Id === _vm.rowEditing.Id},scope)]}}}),{key:'header',fn:function(scope){return (_vm.$scopedSlots['header'])?[_vm._t('header',null,null,scope)]:undefined}},{key:'expanded-item',fn:function(scope){return (_vm.$scopedSlots['expanded-item'])?[_vm._t('expanded-item',null,null,scope)]:undefined}},{key:'group.header',fn:function(scope){return (_vm.$scopedSlots['group.header'])?[_vm._t('group.header',null,null,scope)]:undefined}},{key:'group.header.after',fn:function(scope){return (_vm.$scopedSlots['group.header.after'])?[_vm._t('group.header.after',null,null,scope)]:undefined}},{key:'group.header.notif',fn:function(scope){return (_vm.$scopedSlots['group.header.notif'])?[_vm._t('group.header.notif',null,null,scope)]:undefined}},{key:'body.append',fn:function(scope){return (_vm.$scopedSlots['body.append'])?[_vm._t('body.append',null,null,scope)]:undefined}},_vm._l((_vm.scopeHeaders.filter(
        (e) =>
          (e.dataType &&
            (e.dataType === 'object' ||
              e.dataType === 'enum' ||
              e.dataType === 'boolean')) ||
          (e.displayText &&
            !Object.keys(_vm.$scopedSlots).includes('item.' + e.value))
      )),function(head){return {key:`item.${head.value}`,fn:function({ item }){return [(head.dataType === 'boolean')?[_vm._v(" "+_vm._s(_vm.columnBooleanText(head, item[head.value], item)))]:(head.dataType === 'object' || head.displayText)?[_vm._v(" "+_vm._s(_vm.columnObjectText(head, item[head.value], item)))]:(head.dataType === 'enum')?[_vm._v(" "+_vm._s(_vm.columnEnumText(head.options, item[head.value])))]:_vm._e()]}}}),_vm._l((_vm.scopeHeaders.filter(
        (e) => e.dataType && e.dataType === 'numberBar'
      )),function(head){return {key:'item.' + head.value,fn:function({ item }){return [_c('v-progress-linear',{key:head.value,staticStyle:{"min-width":"100px"},attrs:{"value":head.options && head.options.append === '%'
            ? item[head.value]
            : (item[head.value] /
                Math.max(..._vm.filteredDataSource.map((e) => e[head.value]))) *
                100 || 0,"height":"25","color":_vm.numberBarGetBackground(head, item[head.value], item)}},[_c('strong',{class:_vm.numberBarGetColor(head, item[head.value])},[_vm._v(" "+_vm._s(_vm._f("numberSplit")(item[head.value]))+" "+_vm._s(head.options && head.options.append ? head.options.append : "")+" ")])])]}}}),_vm._l((_vm.scopeHeaders.filter(
        (e) => e.dataType && e.dataType === 'array'
      )),function(head1){return {key:'item.' + head1.value,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.columnArrayText(head1, item[head1.value]))+" ")]}}}),(_vm.isCustomEdit)?{key:"body.append",fn:function({}){return [(typeof _vm.showAddInTable === 'boolean')?_c('v-btn',{staticStyle:{"position":"fixed","bottom":"16px","right":"16px","animation":"showWithDelay 2s forwards","z-index":"2"},attrs:{"color":"primary","large":"","fab":"","title":_vm.textCustomAdd,"dark":""},on:{"click":_vm.addInTable}},[_c('m-icon',{attrs:{"icon":"mdi-plus"}})],1):_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticStyle:{"position":"fixed","bottom":"16px","right":"16px","animation":"showWithDelay 2s forwards","z-index":"2"},attrs:{"color":"primary","large":"","fab":"","title":_vm.textCustomAdd,"dark":""}},on),[_c('m-icon',{attrs:{"icon":"mdi-plus"}})],1)]}}],null,true)},[_c('v-list',{staticClass:"base-table-full_add-list"},_vm._l((_vm.showAddInTable),function(item){return _c('v-list-item',{key:item.key,on:{"click":function($event){return _vm.addInTable(item.key)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1)]}}:null,{key:"item.actions",fn:function(scope){return [_c('div',{class:{
          'table__column__actions-active':
            _vm.rowEditing && _vm.rowEditing.Id === scope.item.Id,
        },staticStyle:{"display":"flex"}},[_vm._t("item.actions",null,null,scope),(
            _vm.editable &&
            (typeof _vm.editable !== 'function' || _vm.editable(scope.item)) &&
            (!_vm.rowEditing || scope.item.Id === _vm.rowEditing.Id)
          )?[(
              _vm.rowEditing && _vm.rowEditing.Id === scope.item.Id && _vm.cancelEnabled
            )?_c('v-btn',{attrs:{"title":"Отмена","icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.rowEditing = null}}},[_c('m-icon',{attrs:{"small":"","icon":"mdi-close"}})],1):_vm._e(),(
              !_vm.hideEdit ||
              (typeof _vm.hideEdit === 'function' && !_vm.hideEdit(scope.item))
            )?_c('v-btn',{attrs:{"disabled":!(
                !_vm.disabledEdit ||
                (typeof _vm.disabledEdit === 'function' &&
                  !_vm.disabledEdit(scope.item))
              ),"title":"Изменить","color":!(_vm.rowEditing && _vm.rowEditing.Id === scope.item.Id)
                ? 'primary'
                : 'success',"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.edit(scope.index, scope.item)}}},[_c('m-icon',{attrs:{"small":"","s16":"","icon":!(_vm.rowEditing && _vm.rowEditing.Id === scope.item.Id)
                  ? 'tool-edit'
                  : 'mdi-check'}})],1):_vm._e(),(
              !_vm.hideDelete ||
              (typeof _vm.hideDelete === 'function' && !_vm.hideDelete(scope.item))
            )?_c('v-btn',{attrs:{"icon":"","color":"error","title":"Удалить","disabled":!(
                !_vm.disabledDelete ||
                (typeof _vm.disabledDelete === 'function' &&
                  !_vm.disabledDelete(scope.item))
              )},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.del(scope.item)}}},[_c('m-icon',{attrs:{"s16":"","icon":"tool-delete"}})],1):_vm._e()]:_vm._e()],2)]}}],null,true)},'base-table',_vm.$attrs,false),_vm.$listeners),[(_vm.isShowFilters && !_vm.$attrs.hasOwnProperty('nested'))?_c('template',{slot:"body.prepend"},[_c('tr',{staticClass:"base-table-full__filter"},[(
            !_vm.$attrs.hasOwnProperty('notShowSelect') ||
            _vm.$attrs['notShowSelect'] === false
          )?_c('td'):_vm._e(),(_vm.$attrs.hasOwnProperty('show-expand'))?_c('td'):_vm._e(),_vm._l((_vm.headersFilterInternal),function(header){return _c('td',{key:header.value,staticStyle:{"vertical-align":"baseline"}},[(_vm.filters.hasOwnProperty(header.value))?_c('div',[(!header.notFilter)?_c('filter-input',{attrs:{"filter":_vm.filters[header.value],"dataSource":_vm.dataSource,"header":header}}):_vm._e()],1):_vm._e()])}),(
            _vm.$refs['base-table'] &&
            (_vm.$refs['base-table'].isShowActions ||
              _vm.$refs['base-table'].showSettings)
          )?_c('td'):_vm._e()],2)]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }