<template>
  <base-list-view
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    @onRefresh="init"
    @customDelete="customDelete"
    show-actions
    :editable="!readonly"
    :showDelete="false"
    :disabledAdd="readonly"
    hideEdit
    title="Виды нежелательных событий"
    label-text-btn-create="Добавить вид"
    label-text-search="Поиск вида"
    pageEditName="UnwishedEventTypesEdit"
    :disabledDelete="(item) => item.OnlyRead"
    :notShowSelect="readonly"
    :selectedItems.sync="selected"
    @toggle-select-all="selectAllToggle"
    :showFilters="true"
  >
    <template #item.Name="scope">
      <span>{{ scope.item.Name }}</span>
    </template>
    <template #item.Owner="scope">
      {{
        scope.item.Owner.Id !== $store.state.Organization.OwnerId
          ? scope.item.Owner.Name
          : "-"
      }}
    </template>
    <template #item.Responsibles="scope">
      <span>
        {{
          scope.item.Responsibles.map((e) =>
            $options.filters.PersonShortName(e)
          ).join(", ")
        }}
      </span>
    </template>
    <div row slot="action" class="align-center d-inline-flex">
      <label
        class="tasks__action-show-all v-label blue-grey--text mr-1"
        style="font-size: 14px"
      >
        Показать архивные виды НС
      </label>
      <v-checkbox
        v-model="showCompleted"
        @change="init"
        hide-details
      ></v-checkbox>

      <v-btn
        v-if="!readonly"
        @click="handlerArchiver"
        :disabled="!isArchiveValid"
        dark
        color="primary"
      >
        {{ !showCompleted ? "Перенести в архив" : "Извлечь из архива" }}
      </v-btn>
    </div>
  </base-list-view>
</template>

<script>
import { mapActions } from "vuex";
import UnwishedEventTypeService from "../../services/UnwishedEventTypeService";
import { defaultUnwishedEventType } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";
import BaseListView from "../../layouts/BaseListView.vue";
import ListEditMixin from "../../mixins/ListEditMixin";
import { sortBy } from "@/utils/Functions";

export default {
  name: "view-UnwishedEventTypes",
  components: { BaseListView },
  mixins: [ListEditMixin],
  data: function () {
    return {
      loading: true,
      readonly: !this.hasPermission(Permissions.UnwishedEventTypesEdit),
      apiService: UnwishedEventTypeService,
      defaultObject: defaultUnwishedEventType,
      showCompleted: false,
      headers: [
        { text: "Название", value: "Name", notGroup: true },
        {
          text: "Ответственные",
          value: "Responsibles",
          displayText: (e) => this.$options.filters.PersonShortName(e),
          dataType: "array",
        },
        this.$store.state.Organization.OwnerType !== 0
          ? this.$store.state.Organization.OwnerType === 2
            ? {
                text: "Другие МО",
                value: "IsShared",
                dataType: "enum",
                options: [
                  { id: 1, value: "да" },
                  { id: 0, value: "нет" },
                ],
                align: "center",
              }
            : {
                text: "Головная организация",
                value: "Owner",
                dataType: "object",
                displayText: (item) => item.Name,
                align: "center",
              }
          : null,
      ].filter(Boolean),

      dataSource: [],
      selected: [],
      editIndex: null,
    };
  },
  computed: {
    isArchiveValid() {
      return this.selected.length
        ? this.selected.every((e) => !e.OnlyRead)
        : false;
    },
  },
  async mounted() {
    await this.init();
    this.LOAD_EMPLOYEES();
  },
  methods: {
    ...mapActions(["LOAD_EMPLOYEES"]),
    DataFromServerNormalize(data) {
      for (const item of data) {
        item.IsShared = item.IsShared ? 1 : 0;
        item.Responsibles = sortBy(item.Responsibles, (e) => {
          return this.$options.filters.PersonShortName(e);
        });
      }
      return data;
    },
    async customDelete(value) {
      if (!value) return;
      if (value.Id > 0) await UnwishedEventTypeService.delete(value.Id);
      const index = this.dataSource.findIndex((item) => item.Id === value.Id);
      if (index >= 0) this.dataSource.splice(index, 1);
      this.editIndex = -1;
    },
    init() {
      this.baseInit({ query: { archive: this.showCompleted } });
    },
    selectAllToggle() {
      const selectedCount = this.selected.length;
      const selectableCount = this.dataSource.filter(
        (item) => !item.OnlyRead
      ).length;

      if (selectedCount !== selectableCount) {
        this.selected = this.dataSource.filter((item) => !item.OnlyRead);
      } else {
        this.selected = [];
      }
    },
    async handlerArchiver() {
      const ids = { ids: this.selected.map((e) => e.Id) };
      await UnwishedEventTypeService.archive(ids);
      this.init();
    },
  },
};
</script>
