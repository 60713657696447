<template>
  <section class="control-activities pt-15 d-flex flex-column">
    <header class="pl-4 d-flex align-center">
      <h2 class="control-activities__title text-uppercase">
        Мероприятия внутреннего контроля
      </h2>
      <m-select
        :value="year"
        :items="years"
        :clearable="false"
        dense
        hide-details
        style="max-width: 80px"
        @click.stop
        @change="(selectedYear) => (this.year = selectedYear)"
        class="ml-12"
      ></m-select>
    </header>
    <div class="control-activities__wrapper pt-6">
      <analytic-card
        card-type="chart"
        :params="cardParams.chart"
        title="Состояние качества и безопасности медицинской деятельности"
      />
      <analytic-card
        card-type="list"
        :icon="UtilGetIcon('view-AuditOperations')"
        :params="cardParams.planned"
        title="Плановые проверки"
      />
      <analytic-card
        card-type="list"
        :icon="UtilGetIcon('view-AuditOperations')"
        :params="cardParams.target"
        title="Целевые проверки"
      />
      <analytic-card
        card-type="list"
        :icon="UtilGetIcon('view-UnwishedEvents')"
        :params="cardParams.unwished"
        title="Нежелательные события"
      />
      <analytic-card
        card-type="list"
        :icon="UtilGetIcon('view-StatisticIndicators')"
        :params="cardParams.statistic"
        title="Статистические показатели"
      />
      <analytic-card
        card-type="list"
        :icon="UtilGetIcon('view-DocumentTemplates')"
        :params="cardParams.documents"
        title="Документы"
        small
      />
      <analytic-card
        card-type="list"
        :icon="UtilGetIcon('view-knowledgebase')"
        :params="cardParams.courses"
        title="Обучение"
        small
      />
    </div>
  </section>
</template>
<script>
import MSelect from "@/components/base/inputs/mSelect.vue";
import AnalyticCard from "./AnalyticCard.vue";

import MinimumDataService from "@/services/MinimumDataService";

import DataHelper from "@/utils/DataHelper";

export default {
  name: "TheAnalytics",
  components: {
    MSelect,
    AnalyticCard,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      years: [],
      cardParams: {},
    };
  },
  computed: {
    dateForChartRequest() {
      const year = DataHelper.today().getFullYear();
      const month = DataHelper.today().getMonth();
      const day = DataHelper.today().getDate();

      return this.year === year
        ? [DataHelper.toServerDateTime(new Date(year, month, day))]
        : [DataHelper.toServerDateTime(new Date(this.year, 12, 0))];
    },
  },
  watch: {
    year() {
      this.init();
    },
    "$parent.$parent.isInit": {
      handler(val) {
        if (!val) {
          this.init();
        }
      },
    },
  },
  created() {
    for (let year = new Date().getFullYear(); year >= 2018; year--) {
      this.years.push(Number(year));
    }
    this.init();
  },
  methods: {
    init() {
      this.cardParams = {
        chart: {
          request: MinimumDataService.chart.get(this.dateForChartRequest),
          date: this.dateForChartRequest,
        },
        planned: {
          request: MinimumDataService.auditShort.get({
            year: this.year,
            kind: 0,
          }),
          name: "planned",
        },
        target: {
          request: MinimumDataService.auditShort.get({
            year: this.year,
            kind: 1,
          }),
          name: "target",
        },
        unwished: {
          request: MinimumDataService.unwishedEventShort.get({
            year: this.year,
          }),
          name: "unwished",
        },
        statistic: {
          request: MinimumDataService.statisticShort.get({
            year: this.year,
          }),
          name: "statistic",
        },
        documents: {
          request: MinimumDataService.documentsShort.get({
            year: this.year,
          }),
          name: "documents",
        },
        courses: {
          request: MinimumDataService.coursesShort.get({
            year: this.year,
          }),
          name: "courses",
        },
      };
    },
  },
};
</script>
<style lang="scss">
.control-activities {
  .v-text-field {
    margin-top: 0 !important;
  }

  .v-select__selections {
    justify-content: flex-end;
    .v-select__selection {
      color: var(--v-primary-base);
    }
    & > input {
      display: none;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(4, auto);
    gap: 25px;

    @media (width <= 1775px) {
      grid-template-columns: repeat(8, 1fr);
    }

    @media (width <= 1280px) {
      grid-template-columns: repeat(4, 1fr);
      grid-auto-flow: column;
    }
  }
}
</style>
