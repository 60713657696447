<template>
  <base-list-view
    :dataSource="buildings"
    :headers="headers"
    :showActions="!readonly"
    :notShowActions="['delete']"
    :disabledAdd="readonly"
    notShowSelect
    headerHidden
    isNested
    hide-default-footer
    :show-add-in-table="!readonly"
    editable
    hideEdit
    @customAdd="addExistingBuildings"
    @customHandle="customHandle"
  >
    <buildings-select
      v-if="!readonly"
      v-model="dialogSelect"
      :dataSource="buildings"
      :filter="buildingsFilter"
      :width="'80vw'"
      :height="'80vh'"
      @save="selectedBuildings"
    ></buildings-select>
  </base-list-view>
</template>

<script>
import BaseListView from "@/layouts/BaseListView.vue";
export default {
  components: {
    BaseListView,
    buildingsSelect: () =>
      import("@/components/organization/buildings/BuildingsSelect.vue"),
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buildings: [],
      headers: [
        { text: "Название", value: "Name" },
        {
          text: "Адрес",
          value: "Address",
        },
      ],
      dialogSelect: false,
      buildingsFilter: [],
    };
  },
  mounted() {
    this.buildings = this.data;
  },
  methods: {
    addExistingBuildings() {
      this.dialogSelect = true;
      this.buildingsFilter = this.buildings.map((item) => item.Id);
    },
    customHandle(value) {
      if (!value?.Id || value.Id <= 0) return;
      this.$router.push({
        name: "BuildingEdit",
        params: {
          objectId: value.Id,
        },
      });
    },
    selectedBuildings(value) {
      this.dialogSelect = false;
      this.buildings = [...this.buildings, ...value];
      this.$emit("add", this.buildings);
    },
  },
};
</script>

<style lang="scss" scoped></style>
