<template>
  <base-list-view
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    :showActions="!readOnly"
    :notShowActions="['delete']"
    :disabledAdd="readOnly"
    notShowSelect
    title="Здания"
    pageEditName="BuildingEdit"
    label-text-btn-create="Добавить здание"
    label-text-search="Поиск здания"
    :showFilters="true"
    editable
    hideEdit
    @onRefresh="init"
    @customDelete="deleteBuilding"
  />
</template>

<script>
import ListEditMixin from "@/mixins/ListEditMixin";

import Permissions from "@/data/permissions";

import BaseListView from "@/layouts/BaseListView.vue";
import BuildingService from "@/services/BuildingService";

export default {
  name: "view-Buildings",
  components: {
    BaseListView,
  },
  mixins: [ListEditMixin],
  data() {
    return {
      loading: false,
      dataSource: [],
      apiService: BuildingService,
      headers: [
        {
          text: "Название",
          value: "Name",
        },
        {
          text: "Адрес",
          value: "Address",
        },
      ],
      readOnly: !this.hasPermission(Permissions.PartitionsEdit),
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async deleteBuilding(building) {
      if (
        !building ||
        !(await this.$confirm(
          `Вы действительно хотите удалить здание: "${building.Name}"?`
        ))
      )
        return;
      await BuildingService.delete(building.Id);
      const index = this.dataSource.findIndex(
        (item) => item.Id === building.Id
      );
      if (index >= 0) this.dataSource.splice(index, 1);
    },
  },
};
</script>
