var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tfoms-panel',{staticClass:"analysis-tfoms__base-table",attrs:{"small":"","title":"Выполнение плана","subTitle":"2024 год","iconColor":"var(--v-success-base)"}},[_c('base-table',{attrs:{"items":_vm.data,"headers":[
      { text: '', value: 'icon', width: '1%' },
      {
        text: '',
        value: 'name',
        cellClass: 'text-no-wrap',
      },
      {
        text: 'План',
        value: 'VolumePlanValue',
        align: 'center',
        width: '1%',
        cellClass: 'text-no-wrap',
      },
      {
        text: 'Факт',
        value: 'VolumeValue',
        align: 'center',
        cellClass: 'text-no-wrap',
        width: '1%',
      },
      { text: '', value: 'percent' },
    ],"hide-default-footer":"","notShowSelect":"","nested":"","showSettings":false},scopedSlots:_vm._u([{key:"item.icon",fn:function({ item }){return [_c('div',{staticClass:"analysis-tfom__icon-wrapper"},[_c('m-icon',{attrs:{"icon":item.icon.name,"color":item.icon.color}})],1)]}},{key:"item.name",fn:function({ item }){return [_c('div',[_vm._v(_vm._s(item.name.title))]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(item.name.subTitle))])]}},{key:"item.VolumePlanValue",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("numberSplit")(item.VolumePlanValue))+" ")]}},{key:"item.VolumeValue",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("numberSplit")(item.VolumeValue))+" "),(item.VolumeValue > item.VolumePlanValue)?_c('m-icon',{attrs:{"color":"success","icon":'mdi-arrow-up-thin'}}):_vm._e(),(item.VolumeValue < item.VolumePlanValue)?_c('m-icon',{attrs:{"color":"error","icon":'mdi-arrow-down-thin'}}):_vm._e()]}},{key:"item.percent",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"min-width":"100px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-progress-linear',_vm._g({staticClass:"mr-2",staticStyle:{"border-radius":"20px"},attrs:{"value":item.percent,"height":"9","color":"var(--v-success-base)"}},on))]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(item.Labels && item.Labels.length >= 1 ? item.Labels[0] : "План:")+" "+_vm._s(item.VolumePlanValue)+" "+_vm._s(item.Unit)+" ")]),_c('div',[_vm._v(" "+_vm._s(item.Labels && item.Labels.length >= 2 ? item.Labels[1] : "Факт:")+" "+_vm._s(item.VolumeValue)+" "+_vm._s(item.Unit)+" ")])]),_vm._v(" "+_vm._s(item.percent)+"% ")],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }