var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"eager":"","value":"true","max-width":_vm.width,"persistent":_vm.persistent},on:{"input":_vm.change,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.choose(false)}}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-text',{class:[
        !_vm.extionSticky
          ? 'text-body-1 confirm__body'
          : 'text-body-1 confirm__body confirm__sticky',
      ]},[_c('m-icon',{staticClass:"mb-4",attrs:{"icon":"tool-warning","color":_vm.color,"size":"75"}}),(Boolean(_vm.title))?_c('v-toolbar-title',{staticStyle:{"color":"var(--v-text-primary)"},domProps:{"textContent":_vm._s(_vm.title)}}):_vm._e(),_c('p',{staticClass:"mt-4 confirm__message",class:{ 'text-center': _vm.center },domProps:{"innerHTML":_vm._s(_vm.message)}})],1),(_vm.type === 0)?_c('v-card-actions',{staticClass:"confirm__actions"},[(Boolean(_vm.buttomCustomValue))?_c('v-btn',{ref:"buttonCustom",attrs:{"color":_vm.buttonCustomColor,"text":_vm.buttonFalseFlat},on:{"click":function($event){return _vm.choose(_vm.buttomCustomValue)}}},[_vm._v(" "+_vm._s(_vm.buttonCustomText)+" ")]):_vm._e(),(Boolean(_vm.buttonTrueText))?_c('v-btn',{attrs:{"color":_vm.buttonTrueColor,"text":_vm.buttonTrueFlat},on:{"click":function($event){return _vm.choose(true)}}},[_vm._v(" "+_vm._s(_vm.buttonTrueText)+" ")]):_vm._e(),(Boolean(_vm.buttonFalseText))?_c('v-btn',{class:{
          'mt-3': Boolean(_vm.buttomCustomValue),
        },attrs:{"color":_vm.buttonFalseColor,"text":_vm.buttonFalseFlat,"outlined":_vm.buttonFalseOutlined},on:{"click":function($event){return _vm.choose(false)}}},[_vm._v(" "+_vm._s(_vm.buttonFalseText)+" ")]):_vm._e()],1):_vm._e(),(_vm.type === 1)?_c('v-card-actions',{staticClass:"confirm__actions"},[_vm._l((_vm.options),function(item){return _c('v-btn',{key:item.OptionCode,attrs:{"color":"primary"},on:{"click":function($event){return _vm.choose(item.OptionCode)}}},[_vm._v(" "+_vm._s(item.Text)+" ")])}),_c('v-btn',{class:{
          'mt-3': _vm.options.length > 1,
        },attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.choose(false)}}},[_vm._v(" Отмена ")])],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }