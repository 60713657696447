<template>
  <div class="block__content">
    <v-form :value="valid" @input="$emit('validate', $event)">
      <v-container>
        <v-row>
          <v-col>
            <base-panel headerTogether headerHidden>
              <span class="subtitle">Новый чек-лист </span>
              <v-textarea
                v-model="newCheckList.Name"
                label="Название чек-листа"
                clearable
                :rules="[rules.required]"
                required
                rows="1"
                no-resize
                auto-grow
                maxlength="500"
                counter
                v-up-first-symbol
              ></v-textarea>
              <m-autocomplete
                v-model="newCheckList.Indicators"
                multiple
                label="Показатель оценки качества"
                deletable-chips
                item-text="Name"
                :item-value="(e) => e"
                :items="indicators"
              ></m-autocomplete>
            </base-panel>
            <base-panel
              headerTogether
              headerHidden
              v-if="newCheckList.Questions.length"
            >
              <div class="d-flex justify-space-between mb-3 align-center">
                <span class="subtitle">Вопросы нового чек-листа</span>
                <v-btn
                  color="red red--lighten-1"
                  @click="clear"
                  small
                  :disabled="!newCheckList.Questions.length"
                >
                  Очистить вопросы
                </v-btn>
              </div>
              <div
                style="
                  width: 100%;
                  max-height: 400px;
                  overflow-y: auto;
                  overscroll-behavior-y: contain;
                "
              >
                <questions
                  :value="newCheckList.Questions"
                  show-edit-popup
                  @input="$emit('input', $event)"
                />
              </div>
            </base-panel>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import mAutocomplete from "@/components/base/inputs/mAutocomplete";
import BasePanel from "@/layouts/BasePanel.vue";
import Questions from "../Question/Questions.vue";
export default {
  name: "CreatedCheckList",
  components: {
    mAutocomplete,
    BasePanel,
    Questions,
  },
  props: {
    newCheckList: { type: Object, default: () => {} },
    valid: Boolean,
    indicators: Array,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Укажите значение.",
      },
    };
  },
  methods: {
    async clear() {
      if (await this.$confirm("Вы действительно хотите очистить вопросы?")) {
        this.newCheckList.Questions = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
