import Api from "./Api";
import qs from "qs";

export default {
  settings: {
    get() {
      return Api().get("common/GetInterfaceSettings");
    },
    update(data) {
      return Api().put("common/UpdateInterfaceSettings", { Items: data });
    },
  },
  instructions: {
    async get(params) {
      return Api().get("help/GetHelpStartHome", { params });
    },
  },
  audit: {
    async get(getParams) {
      return Api().get("auditoperation/GetAuditOperations", {
        params: {
          topCount: 5,
          isMember: getParams?.viewMy ?? true,
        },
      });
    },
  },
  task: {
    async get(getParams) {
      return Api().get("task/GetTasks", {
        params: {
          topCount: 5,
          plannedEnd: true,
          done: false,
          executor: getParams?.viewMy ?? true,
        },
      });
    },
  },
  project: {
    async get(getParams) {
      return Api().get("task/GetProjectsSummary", {
        params: {
          topCount: 5,
          isMember: getParams?.viewMy ?? true,
        },
      });
    },
  },
  unwishedEvent: {
    async get(getParams) {
      return Api().get("unwishedevent/GetUnwishedEvents", {
        params: {
          topCount: 5,
          done: false,
          responsible: getParams?.viewMy ?? true,
        },
      });
    },
  },
  document: {
    async get(getParams) {
      return Api().get("document/GetOrganizationDocuments", {
        params: {
          topCount: 5,
          acquaintance: getParams?.viewMy ?? true,
          participance: getParams?.viewMy ?? true,
        },
        paramsSerializer: function (params) {
          return qs.stringify(params);
        },
      });
    },
  },
  course: {
    async get() {
      return Api().get("/knowledgebase/GetCourseEmployees", {
        params: {
          topCount: 5,
          full: false,
        },
      });
    },
  },
  courseAll: {
    async get() {
      return Api().get("knowledgebase/GetCourses", {
        params: {
          topCount: 5,
          fields: 0,
          done: false,
        },
      });
    },
  },
  todos: {
    async get() {
      return Api().get("user/GetToDoList");
    },
  },
  chart: {
    async get(dates) {
      return Api().post("auditoperation/GetCommonAnalysis", {
        dates,
        algorithm: 0,
        useSettings: true,
        onlyWithCheckLists: true,
      });
    },
  },
  auditShort: {
    async get(params) {
      return Api().get("auditoperation/GetAuditOperationsShortAnalyze", {
        params,
      });
    },
  },
  unwishedEventShort: {
    async get(params) {
      return Api().get("unwishedevent/GetUnwishedEventsShortAnalyze", {
        params,
      });
    },
  },
  statisticShort: {
    async get(params) {
      return Api().get("statisticindicator/GetStatisticIndicatorShortAnalyze", {
        params,
      });
    },
  },
  documentsShort: {
    async get(params) {
      return Api().get("document/GetOrganizationDocumentShortAnalyze", {
        params,
      });
    },
  },
  coursesShort: {
    async get(params) {
      return Api().get("KnowledgeBase/GetCourseShortAnalyze", {
        params,
      });
    },
  },
};
