<template>
  <section class="hero">
    <meeting-card :todos="params.todos" :mission="params.mission" />
    <news-card :news="params.news" />
  </section>
</template>
<script>
import minimumDataService from "@/services/MinimumDataService";
import OrganizationService from "@/services/OrganizationService";
import NewsService from "@/services/NewsService";

import MeetingCard from "./MeetingCard.vue";
import NewsCard from "./NewsCard.vue";

export default {
  name: "TheGreetings",
  components: {
    MeetingCard,
    NewsCard,
  },
  data() {
    return {
      params: {},
    };
  },
  watch: {
    "$parent.$parent.isInit": {
      handler(val) {
        if (!val) {
          this.init();
        }
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.params = {
        todos: { request: minimumDataService.todos.get() },
        mission: { request: OrganizationService.show() },
        news: { request: NewsService.get([1, 2]) },
      };
    },
  },
};
</script>
<style lang="scss">
.hero {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 50px;
  height: auto;

  @media (width <= 1440px) {
    gap: 30px;
    flex-direction: column;
  }
}
</style>
