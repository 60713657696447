import Api from "../Api";

export default {
  async getListPerPage(options, p) {
    return Api().post("common/GetListPerPage", {
      ...options,
      done: p.done,
      isSample: p.type !== "template",
      listObjectType: "DocumentTemplate",
    });
  },
  async getListByGroup(options, p) {
    return Api().post("common/GetListByGroup", {
      options,
      done: p.done,
      archive: p.archive,
      isSample: p.type !== "template",
      listObjectType: "DocumentTemplate",
    });
  },

  async get(params = { user: null, archive: false, isSample: null }) {
    return Api().get("document/GetDocumentTemplates", {
      params,
    });
  },
  async show(id) {
    return Api().get("document/GetDocumentTemplate", { params: { id } });
  },
  async create(document) {
    return Api().post("document/CreateDocumentTemplate", document);
  },
  async update(document) {
    return Api().put("document/UpdateDocumentTemplate", document);
  },
  async delete(id) {
    const results = [];
    results.push(
      Api().delete("document/DeleteDocumentTemplate", {
        params: { id },
      })
    );
    return Promise.all(results);
  },
  GetOrganizationDocumentTypes() {
    return Api().get("document/GetOrganizationDocumentTypes");
  },
  archive(id) {
    return Api().put("Document/UpdateDocumentTemplateState", {
      id,
    });
  },
  publish(SampleIds) {
    return Api().put("document/PublishDocumentSample", {
      SampleIds,
    });
  },
};
