<template>
  <div>
    <base-panel title="Главная" :logo="require('@/assets/logo.svg')">
      <template slot="action">
        <v-btn @click="init" class="mr-4" title="Обновить" icon>
          <m-icon icon="tool-refresh" s16></m-icon>
        </v-btn>
        <!-- TODO: Кнопка скрыта в силу новой реализации настройки блоков в будущем -->
        <v-btn
          color="primary"
          dark
          @click="showSettings = !showSettings"
          v-if="false"
        >
          Настроить
        </v-btn>
      </template>
      <the-greetings></the-greetings>
      <the-analytics v-if="showAnalysis" />
      <slider-card :instructions="instructions"></slider-card>
      <todo-list v-if="settings" :settings="settings"> </todo-list>
    </base-panel>
    <modal-settings
      v-model="showSettings"
      :settings="settings"
      @save="saveSettings"
    ></modal-settings>
    <modal-confirmation-phone
      v-model="showConfirmPhone"
    ></modal-confirmation-phone>
  </div>
</template>

<script>
import Api from "../../services/Api";
import NewsService from "../../services/NewsService";
import minimumDataService from "@/services/MinimumDataService";

import BasePanel from "@/layouts/BasePanel";

import modalSettings from "./components/modalSettings.vue";
import modalConfirmationPhone from "./components/modalConfirmationPhone.vue";
import TheGreetings from "./components/TheGreetings.vue";
import SliderCard from "./components/SliderCard.vue";
import TheAnalytics from "./components/TheAnalytics.vue";
import todoList from "./components/todoList.vue";

import Permissions from "@/data/permissions";

export default {
  name: "view-Home",
  components: {
    BasePanel,
    modalSettings,
    modalConfirmationPhone,
    TheGreetings,
    TheAnalytics,
    SliderCard,
    todoList,
  },
  data() {
    return {
      showSettings: false,
      showConfirmPhone: false,
      instructions: [],
      settings: [],
      showAnalysis: this.hasPermission(Permissions.AnalysisIndicatorsView),
      isInit: false,
    };
  },
  async created() {
    this.init();

    // TODO: Переход на вкладку "Начало работы" (инструкции)
    const openHelpView = await Api().get("help/GetHelpStartNoMore");
    if (!openHelpView.data) {
      this.$router.push({
        name: "Instructions",
      });
    }

    // TODO: Переход на новости, если есть новая
    const newNews = (await NewsService.getLastUpdateNews()).data;
    if (newNews && !newNews.Read) {
      this.$store.dispatch("frame/RUN_COMPONENT", {
        componentName: "news/NewsEdit",
        params: {
          _objectId: newNews.Id,
          persistent: true,
          color: "#FBFBFB",
        },
      });
    }

    if (
      !this.$store.state.user.PhoneNum ||
      !this.$store.state.user.IsNumberChangedInformed
    ) {
      const user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;

      this.showConfirmPhone = user.modalNumberPhone !== false;
    }
  },
  methods: {
    async init() {
      this.isInit = false;
      this.settings = (await minimumDataService.settings.get()).data.Items;
      this.instructions = (await minimumDataService.instructions.get()).data;
      this.isInit = true;
    },
    saveSettings(items) {
      for (let i = 0; i < items.length; i++) {
        const item = this.settings.find((e) => e.Type === items[i].Type);
        if (!item) return;
        item.IsSelected = items[i].IsSelected;
        item.IsDisabled = items[i].IsDisabled;
      }

      minimumDataService.settings.update(this.settings);
      this.showSettings = false;
    },
  },
};
</script>
<style lang="scss">
.td-minimum {
  width: 1%;
}

.td-fixed-width-actions {
  width: 104.7px !important;
  min-width: 104.7px !important;
}
</style>

<style lang="scss">
.text-success {
  color: var(--v-success-base) !important;
}
.text-pending {
  color: var(--v-warning-base) !important;
}
</style>
