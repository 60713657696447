<template>
  <base-list-view
    :dataSource="partitions"
    :headers="headers"
    :showActions="!readOnly"
    :notShowActions="['delete']"
    :disabledAdd="readOnly"
    notShowSelect
    headerHidden
    isNested
    hide-default-footer
    editable
    hideEdit
    :show-add-in-table="!readOnly"
    :exclude-settings-actions="['table', 'group']"
    @customAdd="dialog = true"
    @customHandle="customHandlePartition"
    @customDelete="deletePartition"
  >
    <select-employees
      v-if="!readOnly"
      :value="selectedPartitions"
      :showDialog.sync="dialog"
      :width="'80vw'"
      :height="'80vh'"
      selectType="partition"
      dialogTitle="Добавить подразделение"
      persistent
      @input="savePartitions"
      need-independent
    />
  </base-list-view>
</template>

<script>
import BaseListView from "@/layouts/BaseListView.vue";
export default {
  components: {
    BaseListView,
    selectEmployees: () =>
      import("@/components/organization/employees/SelectEmployees.vue"),
  },
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      partitions: [],
      headers: [{ text: "Название", value: "Name" }],
      dialog: false,
    };
  },
  computed: {
    selectedPartitions() {
      return this.partitions.map((el) => ({
        Id: el.Id,
        Partition: el,
      }));
    },
  },
  mounted() {
    this.partitions = this.dataSource;
  },
  methods: {
    customHandlePartition(value) {
      if (!value?.Id || value.Id <= 0) return;
      this.$router.push({
        name: "PartitionEdit",
        params: {
          objectId: value.Id,
        },
      });
    },
    savePartitions(value) {
      this.partitions = [
        ...value.map((el) => ({
          Id: el.Id,
          Name: el.Name,
        })),
      ];
      this.$emit("change", this.partitions);
    },
    async deletePartition(partition) {
      const index = this.partitions.findIndex(
        (item) => item.Id === partition.Id
      );
      if (index >= 0) this.partitions.splice(index, 1);
      this.$emit("change", this.partitions);
    },
  },
};
</script>

<style lang="scss" scoped></style>
