<template>
  <v-card
    v-if="dataSource.length"
    class="pa-4"
    style="background: transparent; box-shadow: unset"
  >
    <v-card-actions>
      <h4 class="mb-5">Статистические показатели</h4>
      <v-spacer></v-spacer>
      <div style="margin-right: 33px">
        <base-input-year
          v-model="_showYear"
          moreLeft
          :periodEndYear="maxYear"
          :periodStartYear="minYear"
        ></base-input-year>
      </div>
    </v-card-actions>
    <v-row class="statisticWrapChart">
      <v-col v-for="indicator in dataSource" :key="indicator.Id" lg="6" sm="12">
        <statistic-card
          :statisticIndicator="indicator"
          :isRefreshing="loading"
          :showYear="showYear"
        ></statistic-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import StatisticIndicatorService from "../../services/StatisticIndicatorService";
import StatisticIndicatorHelper from "../../views/statisticIndicator/StatisticIndicatorHelper";
import statisticCard from "./statisticCard.vue";
import BaseInputYear from "@/components/base/BaseInputYear.vue";

export default {
  name: "view-StatisticIndicatorsChart",
  components: { statisticCard, BaseInputYear },
  props: {
    items: Array,
    loading: Boolean,
    showYear: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      apiService: StatisticIndicatorService,
      dataSource: [],
      barData: {},
      barOptions: {
        legend: {
          display: false,
        },
        plugins: {
          datalabels: {
            align: "end",
            anchor: "end",
            color: "rgb(33, 33, 33)",
            formatter: (value) => {
              return value.y ? Math.round(value.y) : "";
            },
          },
        },
        tooltips: {
          callbacks: {
            title: () => {
              return ``;
            },
            label: function (tooltipItem, data) {
              const value =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              return value?.x.toLocaleDateString();
            },
          },
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              barPercentage: 0.9,
              categoryPercentage: 1.0,
              offset: true,
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                display: false,
                min: 0,
              },
            },
          ],
        },
        layout: {
          padding: {
            top: 25,
          },
        },
      },
    };
  },
  computed: {
    StatisticIndicatorHelper() {
      return StatisticIndicatorHelper;
    },
    _showYear: {
      get() {
        return this.showYear;
      },
      set(val) {
        this.$emit("update:showYear", val);
      },
    },
    maxYear() {
      if (this.$attrs.years) {
        return this.$attrs.years[this.$attrs.years.length - 1];
      }
      return "";
    },
    minYear() {
      if (this.$attrs.years) {
        return this.$attrs.years[0];
      }
      return "";
    },
  },
  watch: {
    async items(value) {
      if (value) {
        await this.init();
      }
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      try {
        const data = this._.cloneDeep(this.items);
        this.dataSource = data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
@media (min-width: 600px) {
  .statisticWrapChart .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1500px) {
  .statisticWrapChart .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
</style>
