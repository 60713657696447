import Api from "./Api";

export default {
  async getListPerPage(options, p) {
    p = { type: null, participance: true, ...p };

    let params;

    if (p.published) {
      params = { published: p.published };
    }
    switch (p.type) {
      case "developing":
        params = {
          published: false,
          acquaintance: false,
          participance: p.participance,
        };
        break;
      case "my":
        params = {
          published: null,
          acquaintance: true,
          participance: p.participance,
        };
        break;
      case "all":
        params = { published: null };
        break;
    }

    return Api().post("common/GetListPerPage", {
      ...options,
      ...params,
      listObjectType: "OrganizationDocument",
    });
  },
  async getListByGroup(options, p) {
    p = { type: null, participance: true, ...p };

    let params;

    if (p.published) {
      params = { published: p.published };
    }
    switch (p.type) {
      case "developing":
        params = {
          published: false,
          acquaintance: false,
          participance: p.participance,
        };
        break;
      case "my":
        params = {
          published: null,
          acquaintance: true,
          participance: p.participance,
        };
        break;
      case "all":
        params = { published: null };
        break;
    }

    return Api().post("common/GetListByGroup", {
      options,
      ...params,
      archive: p.archive,
      listObjectType: "OrganizationDocument",
    });
  },
  async get(p) {
    p = { type: null, participance: true, ...p };

    let params;

    if (p.published) {
      params = { published: p.published };
    }
    switch (p.type) {
      case "developing":
        params = {
          published: false,
          acquaintance: false,
          participance: p.participance,
        };
        break;
      case "my":
        params = {
          published: true,
          acquaintance: true,
          participance: p.participance,
        };
        break;
      case "all":
        params = { published: null };
        break;
    }

    return Api().get("document/GetOrganizationDocuments", { params });
  },
  async show(id) {
    return Api().get("document/GetOrganizationDocument", { params: { id } });
  },
  async create(document) {
    return Api().post("document/CreateOrganizationDocument", document);
  },
  async update(document) {
    return Api().put("document/UpdateOrganizationDocument", document);
  },
  async archive(ids) {
    return Api().put("document/UpdateOrganizationDocumentArchive", ids);
  },

  async delete(id) {
    return Api().delete("document/DeleteOrganizationDocument", {
      params: { id },
    });
  },
  async new(templateId = 0) {
    return Api().get("document/NewOrganizationDocument", {
      params: { templateId },
    });
  },
  acquainted(documentId) {
    return Api().put("document/UpdateDocumentAcquaintance", { id: documentId });
  },
  updateStatus(
    documentId,
    status,
    options = { comment: null, resetSigned: false }
  ) {
    return Api().put("document/UpdateOrganizationDocumentStatus", {
      Id: documentId,
      Status: status,
      Comment: options?.comment,
      ResetSigned: options?.resetSigned,
    });
  },
  updateSign(
    documentId,
    signed,
    options = { comment: null, onAgreement: false, employees: [] }
  ) {
    return Api().put("document/UpdateOrganizationDocumentSign", {
      Id: documentId,
      Signed: signed,
      Comment: options?.comment,
      OnAgreement: options?.onAgreement,
      Employees: options?.employees ?? [],
    });
  },
  async getHistory(documentId) {
    return Api().get("document/GetOrganizationDocumentHistory", {
      params: { documentId },
    });
  },
  async createHistory(data) {
    return Api().post("document/CreateOrganizationDocumentHistory", data);
  },
  async updateHistory(data) {
    return Api().put("document/UpdateOrganizationDocumentHistory", data);
  },
  async deleteHistory(documentId) {
    return Api().delete("document/DeleteOrganizationDocumentHistory", {
      params: { documentId },
    });
  },
  async getAcuaintance(documentId) {
    return Api().get("document/GetDocumentAcquaintance", {
      params: { documentId },
    });
  },
  async getOrganizationDocumentTypes() {
    return Api().get("document/GetOrganizationDocumentTypes");
  },
  async getOrganizationDocumentPDF(id) {
    return Api().get("document/GetOrganizationDocumentPDF", { params: { id } });
  },
};
