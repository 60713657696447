<template>
  <div>
    <iframe
      v-if="yid"
      id="ytplayer"
      type="text/html"
      width="100%"
      style="height: 60vh"
      :src="'https://www.youtube.com/embed/' + yid + '?autoplay=1'"
      frameborder="0"
      allowfullscreen
    />

    <iframe
      v-else-if="vkid"
      :src="'https://vk.com/video_ext.php?' + vkid"
      width="100%"
      style="height: 60vh"
      frameborder="0"
      allowfullscreen="1"
      allow="autoplay; encrypted-media; fullscreen; picture-in-picture"
    ></iframe>

    <div
      v-else-if="yandexId"
      src="https://disk.yandex.ru/i/nB1FB1iGXj860Q"
      class="d-flex justify-center mt-10"
    >
      <a :href="'https://disk.yandex.ru/i/' + yandexId" target="_blank">
        Посмотреть материал на Яндекс.Диск
      </a>
    </div>

    <iframe
      v-else-if="rutubeId"
      :src="'https://rutube.ru/play/embed/' + rutubeId"
      width="100%"
      style="height: 60vh"
      frameborder="0"
      allowfullscreen="1"
      allow="autoplay; encrypted-media; fullscreen; picture-in-picture"
    ></iframe>

    <video
      v-else-if="directLink"
      :src="directLink"
      width="100%"
      style="height: 60vh"
      role="application"
      playsinline="playsinline"
      controls
    ></video>
  </div>
</template>
<script>
export default {
  name: "knowledgeBase-youtube",
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  computed: {
    yid() {
      return this.youtube_parser(this.url);
    },
    vkid() {
      return this.vk_parser(this.url);
    },
    yandexId() {
      return this.yandexId_parse(this.url);
    },
    rutubeId() {
      return this.rutubeId_parse(this.url);
    },
    directLink() {
      return this.directLink_parse(this.url);
    },
  },
  methods: {
    directLink_parse(url) {
      const ext = url.split(".").pop();

      if (
        ext === "mp4" ||
        ext === "flv" ||
        ext === "m4a" ||
        ext === "3gp" ||
        ext === "mkv"
      ) {
        return url;
      }
      return false;
    },
    yandexId_parse(url) {
      if (url && url.includes("disk.yandex.ru/i/")) {
        return url.split("/").pop();
      }
    },
    rutubeId_parse(url) {
      const regExp =
        /^.*((rutube.ru\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))video\/([^#&?]*)?\/.*/;
      const match = url.match(regExp);
      return match && match[7].length === 32 ? match[7] : false;
    },
    youtube_parser(url) {
      if (!url) return false;
      const regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      return match && match[7].length === 11 ? match[7] : false;
    },
    vk_parser(url) {
      if (
        !url ||
        (!url.includes("vk.com") &&
          !url.includes("vk.ru") &&
          !url.includes("vkvideo.ru"))
      )
        return false;
      let oid;
      let id;
      if (url.includes("video_ext")) {
        oid = url.match(/oid=(-?\d+)/)[1];
        id = url.match(/id=(\d+)/)[1];
      } else if (url.includes("video") || url.includes("clip")) {
        const pars = url.match(/[video|clip](-?[0-9]+)_([0 -9]+)/);
        if (pars.length >= 3) {
          oid = pars[1];
          id = pars[2];
        }
      }
      return oid && id ? `oid=${oid}&id=${id}` : false;
    },
  },
};
</script>
