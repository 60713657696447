<template>
  <section class="todo-list pt-13">
    <v-tabs>
      <h2 class="tabs-title pl-4 pr-11">СПИСОК ДЕЛ</h2>
      <v-tab @click="getParams.viewMy = true">Мои</v-tab>
      <v-tab @click="getParams.viewMy = false">Все</v-tab>
    </v-tabs>
    <div class="d-flex flex-wrap justify-space-between pt-5 todo-list__wrapper">
      <card
        v-if="isShow(4)"
        title="ПРОВЕРКИ"
        :icon="UtilGetIcon('view-AuditOperations')"
        :params="auditConfig"
        :getParams="
          hasPermission(Permissions.AuditOperationViewAll)
            ? getParams
            : { viewMy: true }
        "
      >
        <template slot="item.Status" slot-scope="{ item }">
          <span>
            <span :class="auditStatus(item.Status)">
              {{ item.Status | getEnums("AuditOperationStatus") }}
            </span>
          </span>
        </template>
      </card>
      <card
        v-if="isShow(1)"
        title="ЗАДАЧИ"
        :icon="UtilGetIcon('view-Tasks')"
        :params="taskConfig"
        :getParams="
          hasPermission(Permissions.TasksViewAll) ? getParams : { viewMy: true }
        "
      >
        <template slot="item.PlannedPeriod.DateOut" slot-scope="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" v-html="taskDate(item)"></span>
            </template>
            <span>{{ taskStatus(item) }}</span>
          </v-tooltip>
        </template>
      </card>

      <card
        v-if="isShow(2)"
        title="ПЛАНЫ И ПРОЕКТЫ"
        :icon="UtilGetIcon('view-Projects', 'all')"
        :params="data.project"
        :getParams="getParams"
      >
        <template slot="cardAction">
          <v-row class="mt-1">
            <v-col>
              <v-btn
                block
                depressed
                @click="
                  $router.push({
                    name: 'Plans',
                    params: { type: 'plan' },
                  })
                "
              >
                Планы
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                block
                depressed
                @click="
                  $router.push({
                    name: 'Projects',
                    params: { type: 'project' },
                  })
                "
              >
                Проекты
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template slot="item.DoneTaskPercent" slot-scope="{ item }">
          <div
            :class="{
              'd-flex justify-end': true,
              'text-success': item.DoneTaskPercent === 100,
            }"
          >
            {{ parseFloat(parseFloat(item.DoneTaskPercent).toFixed(2)) + "%" }}
            ({{ item.Completed }} из {{ item.Total }})
          </div>
        </template>
      </card>
      <card
        v-if="isShow(6)"
        title="НЕЖЕЛАТЕЛЬНЫЕ СОБЫТИЯ"
        :icon="UtilGetIcon('view-UnwishedEvents')"
        :params="data.unwishedEvent"
        :getParams="getParams"
      >
        <template slot="item.Status" slot-scope="{ item }">
          <span :class="item.Done ? '' : 'red--text'">
            {{ item.Done ? "Закрыто" : "Новое" }}
          </span>
        </template>
      </card>
      <card
        v-if="isShow(3)"
        title="ДОКУМЕНТЫ"
        :icon="UtilGetIcon('view-LocalDocuments')"
        :params="documentConfig"
        :getParams="
          hasPermission(Permissions.OrganizationDocumentViewAll)
            ? getParams
            : { viewMy: true }
        "
      >
        <template slot="item.Status" slot-scope="{ item }">
          <span :class="documentStatus(item.Status)">
            {{ item.Status | getEnums("OrganizationDocumentStatus") }}
          </span>
        </template>
      </card>
      <card
        v-if="isShow(7)"
        title="ОБУЧЕНИЕ"
        :icon="
          UtilGetIcon(
            getParams.viewMy ? 'knowledgebase-my' : 'knowledgebase-all'
          )
        "
        :params="courseConfig"
        :getParams="
          hasPermission(Permissions.KnowledgeBaseAllCourseReady)
            ? getParams
            : { viewMy: true }
        "
      >
        <template slot="item.DoneTaskPercent" slot-scope="{ item }">
          <div
            :class="{
              'd-flex justify-end': true,
              'text-success': item.Status === 3,
            }"
          >
            {{
              !item.Total
                ? "0%"
                : Math.round((item.Completed / item.Total) * 100) + "%"
            }}
            ({{ item.Completed }} из {{ item.Total }})
          </div>
        </template>
        <template slot="item.DoneEmployeesCount" slot-scope="{ item }">
          <div
            :class="{
              'd-flex justify-end': true,
              'text-success': item.Status === 4,
            }"
            v-if="item.AllEmployeesCount > 0"
          >
            Прошли {{ item.DoneEmployeesCount }} из {{ item.AllEmployeesCount }}
          </div>
        </template>
      </card>
    </div>
  </section>
</template>

<script>
import card from "./card.vue";
import minimumDataService from "@/services/MinimumDataService";
import Permissions from "../../../data/permissions";
import {
  OrganizationDocumentStatus,
  AuditOperationStatus,
} from "../../../data/enums";

import DataHelper from "../../../utils/DataHelper";

export default {
  name: "todoList",
  components: {
    card,
  },
  props: {
    settings: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      getParams: { viewMy: true },
      data: {
        audit: {
          load: minimumDataService.audit,
          headers: [
            { text: "Subject", value: "Subject" },
            {
              text: "Status",
              value: "Status",
              dataType: "enum",
              options: AuditOperationStatus,
              cellClass: "td-minimum",
            },
          ],
          item: "AuditOperationEdit",
          items: "AuditOperations",
        },
        task: {
          load: minimumDataService.task,
          headers: [
            { text: "Name", value: "Name" },
            {
              text: "DateOut",
              value: "PlannedPeriod.DateOut",
              cellClass: "td-minimum",
            },
          ],
          item: "TaskEdit",
          items: "Tasks",
        },
        project: {
          load: minimumDataService.project,
          headers: [
            { text: "Name", value: "Name" },
            {
              text: "DoneTaskPercent",
              value: "DoneTaskPercent",
              cellClass: "td-minimum",
            },
          ],
          item: "ProjectEdit",
          items: "Projects",
        },
        unwishedEvent: {
          load: minimumDataService.unwishedEvent,
          headers: [
            { text: "Content", value: "Content" },
            {
              text: "Status",
              value: "Status",
              cellClass: "td-minimum",
            },
          ],
          item: "UnwishedEventEdit",
          items: "UnwishedEventsJournal",
          moreText: "Журнал нежелательных событий",
        },
        document: {
          load: minimumDataService.document,
          headers: [
            { text: "Name", value: "Name" },
            {
              text: "Status",
              value: "Status",
              dataType: "enum",
              options: OrganizationDocumentStatus,
              cellClass: "td-minimum",
            },
          ],
          item: "LocalDocumentEdit",
          items: "LocalDocuments",
        },
        course: {
          load: minimumDataService.course,
          headers: [
            {
              text: "Name",
              value: "Name",
            },
            {
              text: "DoneTaskPercent",
              value: "DoneTaskPercent",
              cellClass: "td-minimum",
            },
          ],
          valueKey: "CourseId",
          item: "KnowledgeBaseCourseEmployeeEdit",
        },
        courseAll: {
          load: minimumDataService.courseAll,
          headers: [
            {
              text: "Name",
              value: "Name",
            },
            {
              text: "DoneTaskPercent",
              value: "DoneEmployeesCount",
              cellClass: "td-minimum",
            },
          ],
          valueKey: "Id",
          item: "KnowledgeBaseCourseEdit",
        },
      },
    };
  },
  computed: {
    Permissions() {
      return Permissions;
    },
    auditConfig() {
      const isViewAll =
        !this.getParams.viewMy &&
        this.hasPermission(Permissions.AuditOperationViewAll);
      return {
        ...this.data.audit,
        moreText: isViewAll ? "Все проверки" : "Мои проверки",
        query: {
          type: isViewAll ? "all" : "my",
        },
      };
    },
    taskConfig() {
      const isViewAll =
        !this.getParams.viewMy && this.hasPermission(Permissions.TasksViewAll);
      return {
        ...this.data.task,
        moreText: isViewAll ? "Все задачи" : "Мои задачи",
        query: {
          type: isViewAll ? "all" : "my",
        },
      };
    },
    documentConfig() {
      const isViewAll =
        !this.getParams.viewMy &&
        this.hasPermission(Permissions.OrganizationDocumentViewAll);
      return {
        ...this.data.document,
        moreText: isViewAll ? "Все документы" : "Мои документы",
        query: {
          type: isViewAll ? "all" : "my",
        },
      };
    },
    courseConfig() {
      const isViewAll =
        !this.getParams.viewMy &&
        this.hasPermission(Permissions.KnowledgeBaseAllCourseReady);

      if (isViewAll) {
        return {
          ...this.data.courseAll,
          items: "KnowledgeBaseAll",
          moreText: "Все курсы",
        };
      } else {
        return {
          ...this.data.course,
          items: "KnowledgeBaseMy",
          moreText: "Мои курсы",
        };
      }
    },
  },
  methods: {
    isShow(type) {
      const isSelected = this.settings[type]?.IsSelected;
      return isSelected;
    },
    taskDate(item) {
      const DateIn = item.PlannedPeriod?.DateIn?.substring(0, 10);
      const DateOut = item.PlannedPeriod?.DateOut?.substring(0, 10);

      // Группировка по сроку
      const today = DataHelper.today();
      const tomorrow = DataHelper.today().setDate(today.getDate() + 1);

      // Группировка по сроку завершения
      const date = DataHelper.toDate(item.PlannedPeriod.DateOut);
      const dateStart = DataHelper.toDate(item.PlannedPeriod.DateIn);
      let status = 0;

      if (date) {
        // просроченные
        if (date < today) status = 1;
        // сегодня
        else if (date < tomorrow) status = 2;
      } else if (!date && dateStart) {
        // просроченные
        if (dateStart < today) status = 1;
        // сегодня
        else if (dateStart < tomorrow) status = 2;
      }
      // период не задан
      else if (!date && !dateStart) {
        status = 3;
      }

      if (status === 0) return DateOut || DateIn;
      else if (status === 1)
        return item.PlannedPeriod.DateOut
          ? `<span class='red--text'>${DateOut}</span>`
          : `<span class='red--text'>${DateIn}</span>`;
      else if (status === 2)
        return "<span class='primary--text'>Сегодня</span>";
      else if (status === 3)
        return "<span class='text-pending'>Период не задан</span>";
    },
    taskStatus(item) {
      return item.PlannedPeriod.DateIn
        ? item.PlannedPeriod.DateIn?.substring(0, 10) +
            " - " +
            (item.PlannedPeriod.DateOut?.substring(0, 10) ||
              item.PlannedPeriod.DateIn?.substring(0, 10))
        : "Период не задан";
    },
    auditStatus(status) {
      switch (status) {
        case 1:
        case 2:
          return "text-pending";
        case 3:
          return "text-success";
        default:
          return "";
      }
    },
    documentStatus(status) {
      return status === 5
        ? "text-success"
        : status !== 1
        ? "text-pending"
        : "primary--text";
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs-title {
  color: var(--v-text-base);
  font-size: 18px;
  font-weight: 600;
  align-content: center;
}

.home__card {
  .text-pending {
    color: var(--v-warning-lighten1) !important;
  }
}

.td-minimum {
  width: 1%;
}

.td-fixed-width-actions {
  width: 104.7px !important;
  min-width: 104.7px !important;
}

.todo-list {
  &__wrapper {
    gap: 25px;
  }
}
</style>
