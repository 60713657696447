<template>
  <section class="useful pt-15">
    <header class="pl-4">
      <h2 class="useful__title text-uppercase">Полезное</h2>
    </header>
    <v-slide-group
      class="justify-space-between align-center useful__slider slider"
      selected-class="bg-primary"
      show-arrows
      height="200"
      :style="instructions.length < 4 ? 'padding: 0 50px' : ''"
      v-if="loading"
    >
      <v-slide-item v-for="i in 3" :key="i" class="slider__img">
        <v-skeleton-loader
          type="image"
          width="460"
          height="150"
        ></v-skeleton-loader>
      </v-slide-item>
    </v-slide-group>

    <v-slide-group
      class="justify-space-between align-center useful__slider slider"
      selected-class="bg-primary"
      show-arrows
      height="200"
      :style="instructions.length < 4 ? 'padding: 0 50px' : ''"
      v-else
    >
      <v-slide-item
        v-for="instruction in instructions"
        :key="instruction.Id"
        :class="`rounded-lg slider__img ${
          instruction.Preview === null ? 'slider__img--no-img' : ''
        }`"
      >
        <img
          :src="getMediaByExternalId(instruction.Preview)"
          :alt="instruction.Name"
          @click="showInstruction(instruction)"
          v-if="instruction.Preview !== null"
        />
        <span
          class="d-flex pa-4 justify-center align-center text-wrap"
          @click="showInstruction(instruction)"
          v-else
        >
          {{ instruction.Name }}
        </span>
      </v-slide-item>
    </v-slide-group>
  </section>
</template>

<script>
export default {
  name: "SliderCard",
  props: {
    instructions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: true,
      baseUrl: process.env.VUE_APP_SERVER_URL,
    };
  },
  watch: {
    "$parent.$parent.isInit": {
      immediate: true,
      handler(val) {
        if (!val) this.loading = true;
        else {
          this.loading = false;
        }
      },
    },
  },
  methods: {
    showInstruction(item) {
      this.$router.push({
        name: "Instructions",
        params: {
          objectId: item.Id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.useful {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (width <= 1440px) {
    padding-top: 30px;
    padding-bottom: 6px;
  }

  &__slider {
    padding-top: 16px;
  }
}

.slider {
  width: 100%;

  .v-slide-group__content {
    align-items: flex-end;
    gap: 20px;
    height: 160px;
  }

  .v-slide-group__prev {
    justify-content: flex-start;
  }

  .v-slide-group__next {
    justify-content: flex-end;
  }

  .v-slide-group__next .v-icon,
  .v-slide-group__prev .v-icon {
    color: var(--v-primary-base) !important;
    font-size: 32px;
  }

  .v-slide-group__next.v-slide-group__next--disabled .v-icon,
  .v-slide-group__prev.v-slide-group__prev--disabled .v-icon {
    visibility: hidden;
    user-select: none;
  }

  &__img {
    width: 460px;
    height: 150px;
    transition: all 0.2s linear;

    &:hover {
      transform: translateY(-10px);
      cursor: pointer;
      -webkit-box-shadow: 0 4px 4px 0 var(--v-text-lighten2);
      -moz-box-shadow: 0 4px 4px 0 var(--v-text-lighten2);
      box-shadow: 0 4px 4px 0 var(--v-text-lighten2);
    }

    & img {
      width: 100%;
      height: 100%;
      object-position: top left;
      object-fit: cover;
      border-radius: inherit;
    }

    &--no-img {
      background-color: var(--v-blue-grey-lighten2);
    }
  }
}
</style>
