var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-tree-table',{ref:"listView",staticClass:"statistic-indicator-values",attrs:{"editable":"","notShowSelect":"","show-expand":"","expand-icon":"mdi-comment-arrow-right","options":"Values","hide-default-header":"","hide-default-footer":"","hidePaddingLeft":"","items":_vm.value2,"headers":_vm.headers,"show-add-in-table":false,"show-actions":true,"single-expand":false,"fetch-delete":_vm.del,"hideDelete":(item) => {
        return item.IndicatorId ? true : _vm.dataSource.OnlyRead ? true : false;
      },"hideEdit":(item) => !item.IndicatorId,"disabledDelete":(item) => {
        return (
          _vm.readonlyItemValue(item) ||
          (!(item.IndicatorId && item.Value === null) &&
            !(item.Values && item.Values.every((e) => e.Value === null)))
        );
      },"disabledEdit":(item) => _vm.readonlyItemValue(item),"item-class":(item) => (_vm.value && item.Id === _vm.value.Id ? 'table-item--active' : ''),"not-filter":""},on:{"click:row":_vm.customHandle,"afterEdit":_vm.afterEdit},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers }, level }){return [(level === 0)?_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"d-flex align-center"},[_c('base-input-year',{attrs:{"value":_vm.showYear,"periodStartYear":_vm.getPeriodStartYear(),"periodEndYear":_vm.getPeriodEndYear(),"currentYear":_vm.currentYear},on:{"input":function($event){return _vm.$emit('update:showYear', $event)}}}),(_vm.dataSource.Id > 0 && !_vm.readonly)?_c('v-btn',{staticStyle:{"margin-left":"auto","color":"var(--v-white-base)"},attrs:{"color":"primary","dark":"","disabled":!!(_vm.disabledAddPeriod || _vm.dataSource.OnlyRead)},on:{"click":function($event){return _vm.$emit('createValues')}}},[_c('m-icon',{attrs:{"icon":"tool-add-item","color":"var(--v-white-base)","left":"","size":"20"}}),_vm._v(" Добавить период ")],1):_vm._e()],1)])])]):_vm._e()]}},{key:"item.data-table-expand",fn:function({ isExpanded, expand, item }){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[(item.Date)?_c('m-icon',{attrs:{"icon":item.Comment ? 'mdi-comment-eye' : 'mdi-comment-eye-outline'}}):_c('m-icon',{attrs:{"icon":isExpanded ? '$ToolArrowUp' : '$ToolArrowDown'}})],1)]}},{key:"item.Comment",fn:function(scope){return [_c('div',{staticStyle:{"width":"16px"},style:(_vm.type === 0 ? 'margin-left: 1rem' : '')},[(scope.item.Comment)?_c('m-icon',{attrs:{"small":"","color":"var(--v-blue-grey-base)","title":scope.item.Comment,"icon":"mdi-message-reply-text"}}):_vm._e()],1)]}},{key:"item.Date",fn:function(scope){return [_c('div',{staticStyle:{"color":"var(--v-blue-grey-base)"},style:(_vm.type === 0 ? 'min-width: 130px;' : 'min-width: 230px;')},[(
            scope.item.Object && scope.item.Object.Type === 'organization'
          )?_c('span',{staticClass:"pl-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_c('v-badge',{attrs:{"bordered":"","bottom":"","color":"primary","dot":"","offset-x":"15","offset-y":"9"}})],1)]}}],null,true)},[_vm._v(" Другие МО ")])],1):_vm._e(),(scope.item.Object)?_c('span',[_vm._v(" "+_vm._s(scope.item.Object.Name))]):(scope.item.OwnerName)?_c('span',[_vm._v(" "+_vm._s(scope.item.OwnerName))]):_vm._e(),(scope.rowEditing && scope.item.Id === scope.rowEditing.Id)?_c('div',{staticClass:"pl-4"},[_c('base-date-picker',{attrs:{"label":"Дата","required":"","allowed-dates":(val) => {
                return _vm.allowDates(
                  scope.rowEditing.Date || scope.rowEditing.PeriodIn,
                  val
                );
              }},model:{value:(scope.rowEditing.Date),callback:function ($$v) {_vm.$set(scope.rowEditing, "Date", $$v)},expression:"scope.rowEditing.Date"}})],1):_vm._e(),(scope.item && scope.item.Values && scope.item.PeriodIn)?_c('span',[_c('strong',{domProps:{"innerHTML":_vm._s(
              _vm.StatisticIndicatorHelper.getPeriodText(
                scope.item.PeriodIn,
                _vm.period
              )
            )}})]):_vm._e()])]}},{key:"item.DiffValue",fn:function(scope){return [(scope.rowEditing && scope.item.Id === scope.rowEditing.Id)?_c('div'):_c('div',{staticStyle:{"width":"75px","max-width":"75px"}},[(scope.item.Date)?_c('span',[_vm._v(" "+_vm._s(scope.item.Date.substr(0, 10))+" ")]):(scope.item.Values)?_c('span',[_vm._v(" ("+_vm._s(scope.item.Values.length)+") ")]):_c('span',[_vm._v("-")])])]}},{key:"item.chart",fn:function(scope){return [(scope.rowEditing && scope.item.Id === scope.rowEditing.Id)?_c('div',[(_vm.type2 === 0)?_c('div',[_c('v-text-field',{directives:[{name:"focusInput",rawName:"v-focusInput",value:(scope.closeEdit),expression:"scope.closeEdit"}],attrs:{"value":scope.rowEditing.Value,"type":"number","clearable":""},on:{"input":function($event){return _vm.inputNumberLimit(scope.rowEditing, 'Value', $event)}}})],1):(_vm.type2 === 1)?_c('div',[_c('div',{staticClass:"statistic-indicator-values-calc"},[_c('div',{staticClass:"statistic-indicator-values-calc__inner"},[_c('div',{staticClass:"statistic-indicator-values-calc__left"},[_c('div',{staticClass:"pr-2"},[_c('div',{staticStyle:{"height":"70px","display":"flex","align-items":"center","justify-content":"center"}},[_c('m-icon',{attrs:{"title":_vm.dataSource.PhenomenonDescription,"color":"var(--v-text-lighten2)","icon":"mdi-information-outline"}})],1),_c('div',{staticStyle:{"height":"50px","display":"flex","align-items":"center","justify-content":"center"}},[_c('m-icon',{attrs:{"color":"var(--v-text-lighten2)","title":_vm.dataSource.EnvironmentDescription,"icon":"mdi-information-outline"}})],1)]),_c('div',[_c('div',[_c('v-text-field',{directives:[{name:"focusInput",rawName:"v-focusInput.noFocus",value:(scope.closeEdit),expression:"scope.closeEdit",modifiers:{"noFocus":true}}],staticStyle:{"width":"120px"},attrs:{"value":scope.rowEditing.PhenomenonValue,"placeholder":"0","outlined":"","hide-details":"","rows":"1","clearable":""},on:{"input":function($event){scope.rowEditing.PhenomenonValue =
                          $event && $event != '-' ? parseFloat($event) : null}}})],1),_c('hr',{staticClass:"my-1"}),_c('div',[_c('v-text-field',{staticStyle:{"width":"120px"},attrs:{"value":scope.rowEditing.EnvironmentValue,"placeholder":"0","outlined":"","hide-details":"","rows":"1","clearable":""},on:{"input":function($event){scope.rowEditing.EnvironmentValue =
                          $event && $event != '-' ? parseFloat($event) : null}}})],1)])]),_c('div',{staticClass:"statistic-indicator-values-calc__center"},[_c('span',[_vm._v("X")])]),_c('div',{staticClass:"statistic-indicator-values-calc__right d-flex"},[_vm._v(" "+_vm._s(_vm.dataSource.BaseIndicatorValue)+" "),_c('span',{staticClass:"mx-1"},[_vm._v("=")]),_vm._v(" "+_vm._s(_vm.computedIndicatorValue( scope.rowEditing.PhenomenonValue, scope.rowEditing.EnvironmentValue ))+" ")])])])]):_vm._e()]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!(scope.rowEditing && scope.item.Id === scope.rowEditing.Id)),expression:"!(scope.rowEditing && scope.item.Id === scope.rowEditing.Id)"}],style:((scope.item && scope.item.Values) || _vm.type === 0 ? '' : 'width: 50%')},[_c('v-progress-linear',{ref:'progress' + scope.item.Id,staticClass:"statistic-indicator-values__progress",attrs:{"value":(scope.item.Value || scope.item.Value === 0 ? 2 : 0) +
              (scope.item.Value / _vm.getMaxValue(scope.item)) * 90 || 0,"buffer-value":scope.item.Value || scope.item.Value === 0 ? 88 : 90,"background-color":"var(--v-grey-lighten3)","height":"15","color":_vm.StatisticIndicatorHelper.getColorByRefs(
              _vm.refs,
              scope.item.Value,
              _vm.RatingType
            ) || 'var(--v-grey-base)'}},[_vm._v(" "+_vm._s(scope.item.Value || scope.item.Value === 0 ? Number(scope.item.Value.toFixed(2)) : "Нет данных")+" ")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }