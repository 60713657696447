<template>
  <v-window-item :key="name" :value="value">
    <div class="left-tab__panel">
      <slot></slot>
    </div>
  </v-window-item>
</template>
<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      default: 0,
    },
    author: {
      type: Object,
      default: () => {},
    },
    value: null,
  },
  data() {
    return {
      isActive: this.active,
    };
  },
  methods: {
    setActive(flag) {
      setTimeout(() => {
        this.isActive = flag;
      }, 500);
    },
  },
};
</script>
<style lang="scss">
.left-tab__panel {
  min-height: 100px;
  width: 100%;
}
</style>
