import { mergeDeep } from "vuetify/lib/util/helpers";
export default {
  props: {
    nested: Boolean,
    _objectId: Number,
    _type: String,
    _object: Object,
    _created: Boolean, // Для фрейма. Позволяет создавать новые объекты
    _independent: Boolean, // Для фрейма. Позволяет нативно работать с объектом, сохронять его
  },
  provide() {
    const provideReadonly = {};

    Object.defineProperty(provideReadonly, "value", {
      enumerable: true,
      get: () => this.readonly,
    });

    return {
      provideReadonly,
      currentRouter: this.currentRouter,
      nested: this.nested,
    };
  },
  data() {
    return {
      loading: true,
      dataSource: {},
      listViewItemUpdateFunc: null,
      listViewDetail: null,
      currentRouter: this.nested ? null : { ...this.$route },
    };
  },
  watch: {
    _objectId: function () {
      this.init();
    },
    _type: function () {
      this.init();
    },
  },
  mounted() {
    // Регистрация функции для обновления элемента в списочном представлении
    this.listViewDetail = this.$store.state.pageTabs.transaction.listViewDetail;
    this.listViewItemUpdateFunc = this.listViewDetail?.listViewUpdateItems;
    this.$store.dispatch("pageTabs/TAKE_TRANSACTION_VIEW_DETAIL");

    // Регистрация функции сохранения
    setTimeout(() => {
      this.$store.dispatch("pageTabs/REGISTER_COMPONENT", {
        component: this.beforeSave,
        destroy: this.destroy,
      });
    }, 0);
  },
  methods: {
    destroy() {
      this.$destroy();
    },
    async beforeSave() {
      let res = await this.$confirm(
        `Вы хотите закрыть экран. Сохранить изменения?`,
        {
          buttomCustomValue: "save",
          buttonCustomText: "Сохранить",
          buttonFalseText: "Отмена",
          buttonTrueColor: "primary",
          buttonTrueText: "Не сохранять",
        }
      );
      if (res === "save") {
        res = !!(await this.save());
      }
      return res;
    },
    DataBeforeSave(data) {
      return data;
    },
    DataFromServerNormalize(data) {
      return data;
    },
    CopyDataNormalize(data) {
      return data;
    },
    updateDataView() {},
    getActualDataSources(dataSource) {
      return this.baseGetActualDataSources(dataSource);
    },
    baseGetActualDataSources(dataSource) {
      return this._.cloneDeep(dataSource);
    },
    async init(args) {
      return this.baseInit(args);
    },
    async baseInit(params) {
      this.loading = true;
      try {
        let res;
        if (this.type === "create") {
          res = {
            data: mergeDeep(
              this.getDefaultObject(),
              this._.cloneDeep(this._object)
            ),
          };
        } else if (this._object) res = { data: this._.cloneDeep(this._object) };
        else if (this.type === "copy") {
          const copyId = this.objectId;
          res = await this.apiService.show(copyId);

          // todo:  Временное решение для проверок
          if (res.data?.CheckOperations?.length >= 0)
            res.data.TemplateId = res.data.Id;

          res.data.Id = -Math.round(Math.random() * 100000);
          res.data = this.CopyDataNormalize(res.data);
        } else res = await this.apiService.show(this.objectId, params);

        this.dataSource = this.DataFromServerNormalize(res.data);
        await this.updateDataView();
        this.originObject = this.getActualDataSources(this.dataSource);
      } catch (error) {
        console.log(error);
      }
      this.updateObjectText(
        this.dataSource,
        this.type === "create" || this.type === "copy"
      );
      this.loading = false;
    },
    async save() {
      return this.baseSave();
    },
    async baseSave() {
      this.loading = true;
      let newObj = this.getActualDataSources(this.dataSource);
      newObj = this.DataBeforeSave(newObj);
      let res;

      if (!this.nested || this._independent) {
        try {
          if (this.type === "create" || this.type === "copy") {
            res = await this.apiService.create(newObj);
            this.dataSource = this.DataFromServerNormalize(res.data);
            // todo: Говно код, исправить
            if (this.listViewItemUpdateFunc)
              this.$store.commit("pageTabs/SET_TRANSACTION_LIST_VIEW_DETAIL", {
                listViewUpdateItems: this.listViewItemUpdateFunc,
                listViewInit: this.$parent?.init, // todo: в this.$parent?.init не может быть списочного представления
              });
            // setTimeout(() => {
            this.$router.push({
              name: this.pageEditName ?? this.$route.name,
              params: {
                objectId: res.data.Id,
                isReplace: true,
              },
            });
            // }, 1);
          } else {
            try {
              res = await this.apiService.update(newObj);
              if (res.data instanceof Object)
                this.dataSource = this.DataFromServerNormalize(res.data);
            } catch (error) {
              console.log(error);
              this.loading = false;
              return false;
            }
          }

          this.originObject = this.getActualDataSources(this.dataSource);
        } catch (error) {
          console.log(error);
          this.loading = false;
          return false;
        }
        this.updateObjectText(this.dataSource, false);
      } else {
        // Создание, если детальная страница открыта как фрейм
        if (this._created) {
          try {
            res = await this.apiService.create(newObj);
            this.dataSource = this.DataFromServerNormalize(res.data);
            newObj = this.dataSource;
          } catch {
            this.loading = false;
            return;
          }
        }

        this.originObject = this.getActualDataSources(this.dataSource);

        this.$store.commit("frame/SET_META", { meta: { valid: this.valid } });
        this.$store.commit("frame/SET_OBJECT", { obj: newObj });
      }
      this.loading = false;

      if (res) {
        // Обновление элемента в списочном представлении
        this.applyListViewItemUpdateFunc();
        return res;
      }
    },
    // Обновляет описание объекта на текущей вкладке
    async updateObjectText(obj, isNew) {
      if (!this.getObjectText || this.nested) return;
      try {
        const objectText = this.getObjectText(obj, isNew);
        this.$store.commit("pageTabs/UPDATE_TAB_OBJECT_TEXT", {
          route: this.currentRouter.meta.normalizeFullPath,
          objectText,
        });

        this.$store.dispatch("pageTabs/UPDATE_COMPONENT_TAB");
      } catch (error) {
        console.log(error);
      }
    },
    // Обновление элемента в списочном представлении
    applyListViewItemUpdateFunc() {
      if (this.listViewItemUpdateFunc)
        this.listViewItemUpdateFunc([
          this.getActualDataSources(this.dataSource),
        ]);
    },
  },
  computed: {
    isNested() {
      return this.nested;
    },
    objectId() {
      return (
        (this.nested ? this._objectId : null) ??
        this.currentRouter?.params?.objectId ??
        this.$store.state.route.params.objectId
      );
    },
    type() {
      return (
        (this.nested ? this._type : null) ||
        (this?.onlyCreate ? "create" : null) ||
        this.currentRouter?.query?.type ||
        "edit"
      );
    },
  },
};
