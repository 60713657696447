var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{class:`d-flex flex-column flex-grow-1 analytic-card py-4 px-8 ${
    _vm.small ? 'analytic-card--small' : ''
  }`,attrs:{"elevation":"0"}},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"heading"}}):_c('header',{staticClass:"d-flex align-center mb-1"},[(_vm.icon !== null)?_c('m-icon',{staticClass:"mr-4",attrs:{"icon":_vm.icon,"active":""}}):_vm._e(),_c('h3',{staticClass:"text-uppercase analytic-card__title"},[_vm._v(_vm._s(_vm.title))])],1),(_vm.cardType === 'list')?[(_vm.loading)?[(_vm.small)?_vm._l((2),function(i){return _c('v-skeleton-loader',{key:i,attrs:{"type":"list-item"}})}):_vm._l((6),function(i){return _c('v-skeleton-loader',{key:i,attrs:{"type":"list-item"}})})]:_c('ul',{staticClass:"pt-6 px-0 d-flex flex-column"},_vm._l((_vm.cardData),function(field,key){return _c('li',{key:key,class:{
          'd-flex justify-space-between align-start': true,
          'error--text text--lighten-1':
            ['Expired', 'TasksExpired', 'Negative', 'ExpiredValue'].indexOf(
              key
            ) !== -1 && field.count > 0,
          'success--text text--lighten-1':
            ['Done', 'TasksDone', 'Positive', 'Percent'].indexOf(key) !==
              -1 && field.count > 0,
          'pt-4':
            ['NonCompliances', 'TasksTotal', 'ExpiredValue'].indexOf(key) !==
            -1,
        }},[_c('span',[_vm._v(_vm._s(field.title))]),_c('span',[_vm._v(_vm._s(key === "Percent" ? `${field.count}%` : field.count))])])}),0)]:_vm._e(),(_vm.cardType === 'chart')?[(_vm.loading)?[_c('v-skeleton-loader',{attrs:{"type":"list-item"}}),_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"20px"}},[_c('v-skeleton-loader',{attrs:{"type":"image","width":"50%"}}),_c('v-skeleton-loader',{attrs:{"type":"paragraph","width":"50%"}})],1)]:_c('div',[_c('p',{staticClass:"d-flex flex-column mb-0 pt-2"},[_vm._v(" На "+_vm._s(_vm._f("DateOnly")(_vm.params.date[0]))+" ")]),_c('div',{staticClass:"pt-6 analytic-card__chart"},[_c('div',{staticStyle:{"width":"150px","height":"auto"}},[_c('doughnut-chart',{attrs:{"chart-data":_vm.doughnutData,"options":_vm.doughnutOptions,"common-percent":(_vm.cardData && _vm.cardData.Value ? Math.round(_vm.cardData.Value) : 0) +
              '%',"font-size":80}})],1),_c('p',{staticClass:"mb-0 text-center font-weight-bold analytic-card__description"},[_vm._v(" "+_vm._s(_vm.cardData.Description)+" ")])])])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }