<template>
  <div>
    <base-tree-list-view
      text-custom-add="Добавить задачу"
      ref="taskListView"
      hide-default-footer
      :show-actions="!readonly"
      editable
      :notShowSelect="notShowSelect"
      :headerHidden="headerHidden"
      :dataSource="Tasks"
      :headers="headers"
      :sort-by.sync="sortBy"
      class="taskList"
      :show-add-in-table="computedAddItems"
      addInTable
      @customHandle="customHandle"
      @customAdd="customAdd"
      :item-class="
        (item) => {
          return markCompleted && item.Status === 5
            ? 'disabled text-decoration-line-through'
            : '';
        }
      "
      :customInplaceEdit="customInplaceEdit"
      v-bind="$attrs"
      v-on="$listeners"
      :fetchDelete="apiService ? apiService.delete : null"
      :showFilters="typeParent === 'project'"
    >
      <!-- ! Фильтры будут по умолчанию открыты только в планах и проектах -->
      <template slot="group.header.after" slot-scope="scope">
        ({{
          scope.items.reduce((sum, obj) => sum + (obj.Status === 5 ? 1 : 0), 0)
        }}/{{ scope.items.length }}
        -
        {{
          scope.items.length
            ? parseFloat(
                (
                  (scope.items.reduce(
                    (sum, obj) => sum + (obj.Status === 5 ? 1 : 0),
                    0
                  ) /
                    scope.items.length) *
                  100
                ).toFixed(2)
              )
            : 0
        }}%)
      </template>

      <template slot="item.Name" slot-scope="scope">
        <v-text-field
          v-if="scope.itemEdited"
          :value="scope.item.Name"
          @change="scope.rowEditing.Name = $event"
          maxlength="100"
          v-focusInput.noFocus="scope.closeEdit"
          placeholder="Мероприятие"
        ></v-text-field>
        <template v-else>{{ scope.item.Name }}</template>
      </template>
      <template slot="item.ViolationDescription" slot-scope="scope">
        <v-text-field
          v-if="scope.itemEdited"
          :value="scope.item.ViolationDescription"
          @change="scope.rowEditing.ViolationDescription = $event"
          maxlength="100"
          placeholder="Несоответствие"
        ></v-text-field>
        <template v-else>{{ scope.item.ViolationDescription }}</template>
      </template>

      <template slot="item.Executors" slot-scope="scope">
        <m-autocomplete
          v-if="scope.itemEdited"
          v-model="scope.rowEditing.Executors"
          label="Исполнители"
          return-object
          filter="EmployeeActive"
          dict="Employees"
          :textCustomAdd="'Создать нового сотрудника'"
          multiple
        ></m-autocomplete>
        <template v-else>
          {{
            scope.item &&
            scope.item.Executors &&
            scope.item.Executors.map((e) =>
              $options.filters.PersonShortName(e)
            ).join(", ")
          }}
        </template>
      </template>
      <template v-slot:item.Status="{ item, header }">
        <span v-if="!item.Children.length">
          {{ header.options.find((el) => el.id === item.Status).value }}
        </span>
        <span
          v-else
          :class="{
            'success--text':
              item.DoneChildrenCount > 0 &&
              item.DoneChildrenCount === item.ChildrenCount,
          }"
        >
          {{ item.DoneChildrenCount }}/{{ item.ChildrenCount }} ({{
            item.ChildrenCount !== 0
              ? parseFloat(
                  ((item.DoneChildrenCount / item.ChildrenCount) * 100).toFixed(
                    2
                  )
                )
              : "0"
          }}%)
        </span>
      </template>
      <template v-slot:item.PlannedStart="scope">
        <base-date-picker-range
          v-if="scope.itemEdited"
          :value="PlannedDates(scope.rowEditing)"
          label="Срок"
          isPeriod
          :nudge-left="'200px'"
          @input="setPlannedDates($event, scope.rowEditing)"
        />
      </template>
      <template slot="item.PlannedPeriod" slot-scope="{ item }">
        <span
          :class="{
            'error--text':
              (!item.Done || !markCompleted) &&
              checkIsOverdue(item.PlannedPeriod),
          }"
        >
          {{
            item.PlannedPeriod.DateIn | PeriodFormat(item.PlannedPeriod.DateOut)
          }}
        </span>
      </template>
      <template slot="item.QuestionResult.Requirement" slot-scope="scope">
        <template
          v-if="
            scope.item.QuestionResult &&
            scope.item.QuestionResult.Question &&
            scope.item.QuestionResult.Question.Children.length
          "
        >
          <span>
            {{
              scope.item.QuestionResult.Requirement
                ? scope.item.QuestionResult.Requirement
                : ""
            }}
          </span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <m-icon
                v-bind="attrs"
                v-on="on"
                icon="mdi-information"
                small
                color="primary lighten-1"
                style="cursor: pointer"
              ></m-icon>
            </template>
            <span
              v-html="
                getTextQuestions(scope.item.QuestionResult.Question, []).join(
                  '<br/>'
                )
              "
            ></span>
          </v-tooltip>
        </template>
        <span v-else-if="scope.item.QuestionResult">
          {{
            scope.item.QuestionResult.Requirement
              ? scope.item.QuestionResult.Requirement
              : ""
          }}
        </span>
      </template>
    </base-tree-list-view>
    <task-add
      v-if="typeTaskList === 1"
      v-model="dialogTask"
      :Tasks="Tasks"
      :width="'80vw'"
      :height="'80vh'"
      :typeParent="typeParent"
      :current-task-id="currentTaskId"
    />
  </div>
</template>

<script>
import Vue from "vue";
import BaseDatePickerRange from "@/components/base/BaseDatePickerRange";
import BaseTreeListView from "../../layouts/BaseTreeListView.vue";
import { TaskStatus } from "../../data/enums";
import { defaultTask } from "../../data/defaultObjects";
import mAutocomplete from "@/components/base/inputs/mAutocomplete.vue";
import DataHelper from "../../utils/DataHelper";

export default {
  name: "view-TaskList",
  components: {
    BaseTreeListView,
    mAutocomplete,
    BaseDatePickerRange,
    TaskAdd: () => import("@/components/task/TaskAdd"),
  },
  props: {
    // Прокидываем пропс из TaskEdit в TaskAdd
    currentTaskId: {
      type: Number,
      default: null,
    },
    headerHidden: {
      type: Boolean,
      default: true,
    },
    notShowSelect: {
      type: Boolean,
      default: true,
    },
    Tasks: {
      type: Array,
      default: () => [],
    },
    projectId: {
      type: Number,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Function,
      default: null,
    },
    create: {
      type: Function,
    },
    hideEdit: {
      type: Boolean,
    },
    isFromTask: {
      type: Boolean,
      default: true,
    },
    customInplaceEdit: {
      type: Function,
      default: null,
    },
    markCompleted: {
      type: Boolean,
      default: true,
    },
    afterAddTask: {
      type: Function,
      default: null,
    },
    apiService: { type: Object, default: null },
    typeParent: {
      default: null,
      validator: function (value) {
        return [null, "project", "document", "unwishedEvent"].includes(value);
      },
    },
    typeTaskList: {
      type: Number,
      default: 1,
      validator: function (e) {
        return [0, 1].includes(e);
      },
    },
    hideExecutors: { type: Boolean, default: false },
  },
  data() {
    return {
      dialogTask: false,
      sortBy: [],
      headers:
        this.typeTaskList === 0
          ? [
              // Проекты
              {
                text: "Номер",
                value: "Num",
                width: "1%",
                cellClass: "td-minimum text-no-wrap td-1",
                notGroup: true,
              },
              {
                text: "Название",
                value: "Name",
                notGroup: true,
              },
              {
                text: "Исполнитель",
                value: "Executors",
                dataType: "array",
                displayText: Vue.filter("PersonShortName"),
                width: "1%",
                cellClass: "td-minimum text-no-wrap",
              },
              {
                text: "Требование",
                value: "QuestionResult.Requirement",
                dataType: "string",
                width: "1%",
                cellClass: "td-minimum td-min ",
              },
              {
                text: "Срок",
                value: "PlannedPeriod",
                width: "1%",
                cellClass: "td-minimum td-min text-no-wrap",
                dataType: "Period",
                align: "center",
                displayText: (e) =>
                  Vue.filter("PeriodFormat")(e.DateIn, e.DateOut),
              },
              {
                text: "Завершена",
                value: "Done",
                dataType: "object",
                displayText: Vue.filter("DateShort"),
                align: "center",
                width: "1%",
                cellClass: "td-minimum td-min",
              },
            ]
          : [
              // Планы
              {
                text: "Показатель оценки качества",
                value: "Indicator",
                dataType: "object",
                cellClass: "td-minimum td-large",
                showGroupName: false,
                displayText: (e) => e?.Name,
              },
              {
                text: "Проверка",
                value: "AuditOperation.Num",
                dataType: "string",
                cellClass: "td-minimum text-no-wrap td-small",
                width: "1%",
                disabled: true,
              },
              {
                text: "Чек-лист",
                value: "QuestionResult.CheckList",
                dataType: "object",
                cellClass: "td-minimum td-large",
                width: "1%",
                displayText: (el) => el?.Name,
                disabled: true,
              },
              {
                text: "Требование",
                value: "QuestionResult.Requirement",
                dataType: "string",
                width: "1%",
                cellClass: "td-minimum td-3",
              },
              {
                text: "Несоответствие",
                value: "ViolationDescription",
                width: "1%",
                cellClass: "td-minimum td-4",
              },
              {
                text: "Причина",
                value: "Reason",
                width: "1%",
                cellClass: "td-minimum td-min",
                disabled: true,
                notGroup: true,
              },
              {
                text: "Номер",
                value: "Num",
                width: "1%",
                cellClass: "td-minimum text-no-wrap td-mid",
                notGroup: true,
                disabled: true,
              },
              {
                text: "Мероприятие",
                value: "Name",
                notGroup: true,
                width: "75%",
              },
              !this.hideExecutors
                ? {
                    text: "Исполнитель",
                    value: "Executors",
                    dataType: "array",
                    displayText: Vue.filter("PersonShortName"),
                    width: "1%",
                    cellClass: "td-minimum text-no-wrap td-large",
                  }
                : null,
              {
                text: "Срок",
                value: "PlannedPeriod",
                width: "100%",
                cellClass: "td-minimum td-period",
                dataType: "Period",
                align: "center",
                displayText: (e) =>
                  Vue.filter("PeriodFormat")(e.DateIn, e.DateOut),
              },
              {
                text: "Завершена",
                value: "Done",
                dataType: "Date",
                displayText: Vue.filter("DateShort"),
                width: "1%",
                disabled: true,
                cellClass: "td-minimum td-min",
              },

              {
                text: "Статус",
                value: "Status",
                dataType: "enum",
                options: TaskStatus,
                width: "1%",
                cellClass: "td-minimum td-min",
                align: "center",
              },
            ].filter(Boolean),
    };
  },
  computed: {
    computedAddItems() {
      if (this.readonly) return false;
      else if (this.typeTaskList === 0) {
        return [
          { text: "Создать новую задачу", key: "createNew" },
          { text: "Добавить существующую", key: "addExisting" },
        ];
      } else if (this.typeTaskList === 1) {
        return [
          { text: "Создать новую задачу", key: "createNew" },
          { text: "Добавить существующую", key: "addExisting" },
          {
            text: "Добавить задачу из проверки",
            key: "add",
            hide: !this.isFromTask,
          },
        ];
      }
      return false;
    },
  },
  methods: {
    checkIsOverdue(period) {
      if (period.DateIn || period.DateOut) {
        if (period.DateOut) {
          return DataHelper.today() > DataHelper.toDate(period.DateOut);
        }
        return DataHelper.today() > DataHelper.toDate(period.DateIn);
      }
    },
    getTextQuestions(Question, arr) {
      if (Question?.Children?.length) {
        arr.push(Question.Num + " " + Question.Content);
        return this.getTextQuestions(Question.Children[0], arr);
      }
      arr.push(Question?.Num + " " + Question?.Content);
      return arr;
    },
    customHandle(e) {
      if (this.edit) this.edit(e);
      else this.editTask(e);
    },
    async createTask() {
      const _id = -Math.round(Math.random() * 100000);
      const lastFrame = this.$store.getters["frame/GET_DATA"]();
      const taskLevel =
        lastFrame?.meta.taskLevel >= 0 ? lastFrame?.meta.taskLevel + 1 : 1;
      const object = this.afterAddTask ? await this.afterAddTask({}) : null;
      this.$store.dispatch("frame/RUN_COMPONENT", {
        componentName: "task/TaskEdit",
        params: {
          _objectId: _id,
          _object: object,
          _type: "create",
          _apiService: this.apiService,
          simple: true,
          title: "Создание задачи",
          persistent: true,
          typeParent: this.typeParent,
        },
        meta: {
          taskLevel,
        },
        callback: () => {
          const task = this.$store.getters["frame/GET_DATA"]().object;
          const meta = this.$store.getters["frame/GET_DATA"]().meta;
          if (meta.valid !== undefined && !meta.valid) return;
          task.Id = _id;
          this.$emit("update:Tasks", [...this.Tasks, task]);
          this.$store.dispatch("frame/SHOW_DIALOG", { show: false });
        },
      });
    },
    editTask(task) {
      this.$store.dispatch("frame/RUN_COMPONENT", {
        componentName: "task/TaskEdit",
        params: {
          _objectId: task.Id,
          _type: "edit",
          _object: task,
          _apiService: this.apiService,
          simple: true,
        },
        callback: () => {
          const editedTask = this.$store.getters["frame/GET_DATA"]().object;
          const meta = this.$store.getters["frame/GET_DATA"]().meta;

          if (!editedTask.Name) return;

          const index = this.Tasks.findIndex((t) => t.Id === editedTask.Id);
          if (index >= 0) this.$set(this.Tasks, index, editedTask);
          else {
            DataHelper.treeListTraverse(this.Tasks, (item) => {
              const index = item.Children.findIndex(
                (t) => t.Id === editedTask.Id
              );
              if (index >= 0) {
                // Удаление подзадачи, если она стала самостоятельной
                if (meta._needDelete)
                  item.Children = item.Children.filter(
                    (e) => e.Id !== editedTask.Id
                  );
                else this.$set(item.Children, index, editedTask);
              }
            });
          }

          this.$store.dispatch("frame/SHOW_DIALOG", { show: false });
        },
      });
    },
    setPlannedDates(value, item) {
      if (!value || value.length === 0) {
        item.PlannedStart = null;
        item.PlannedEnd = null;
      } else if (value.length === 1) {
        item.PlannedStart = value[0];
        item.PlannedEnd = null;
      } else {
        item.PlannedStart = value[0];
        item.PlannedEnd = value[1];
      }
    },
    PlannedDates(item) {
      let result = [];
      if (item) {
        const start = item.PlannedStart;
        const end = item.PlannedEnd;
        if (start && end) result = [start, end];
        else if (end) result = [end];
        else if (start) result = [start];
      }
      return result;
    },
    addInParent(event, item) {
      const newTask = { ...defaultTask() };
      newTask.AuditOperation = item.AuditOperation;
      newTask.AuditOperationNum = item.AuditOperationNum;
      newTask.Reason = item.Reason;
      newTask.CheckListName = item.CheckListName;
      newTask.Executors = item.Executors;
      newTask.Indicator = item.Indicator;

      item.Children.push(newTask);
      this.$store.commit("itemEdited/setIdEdit", newTask.Id);
    },
    customAdd(key) {
      switch (key) {
        case "createNew":
          if (this.create) this.create();
          else this.createTask();
          break;
        case "addExisting":
          this.dialogTask = true;
          this.$emit("show-tasks", true);
          break;
        case "add":
          this.$emit("show-audit-operations", true);
          break;
        default:
          this.$emit("customAdd", true);
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.taskList {
  table {
    td,
    th {
      padding: 0 8px !important;
    }
    .text-end {
      width: 1%;
    }
    .td-min {
      min-width: 10rem;
    }
    .td-mid {
      min-width: 188px;
    }
    .td-large {
      min-width: 218px;
    }
    .td-small {
      min-width: 90px;
    }
    .td-3 {
      min-width: 316px;
    }
    .td-4 {
      min-width: 239px;
    }
    .td-period {
      min-width: 90px;
      max-width: 90px;
    }
  }
}
</style>
