<template>
  <v-app>
    <v-main class="main">
      <v-container fluid style="height: 100%">
        <login />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Login from "./Login.vue";

export default {
  components: { Login },
};
</script>
<style>
:root {
  --v-beautify-lighten2: rgba(134, 234, 227, 70%);
  --v-beautify-lighten3: rgba(0, 160, 160, 50%);
  --v-text-lighten1: rgba(33, 33, 33, 0.7);
  --v-text-lighten2: rgba(33, 33, 33, 0.3);
  --v-text-lighten3: rgba(33, 33, 33, 0.15);
  --v-overlay-base: rgba(26, 41, 91, 0.3);
  --v-error-lighten4: rgba(249, 80, 80, 0.5);
  --v-warning-lighten2: rgba(255, 200, 5, 0.5);
  --v-warning-lighten3: rgba(255, 200, 5, 0.2);
  --v-success-lighten3: rgba(12, 168, 93, 0.5);
  --v-success-lighten4: rgba(0, 200, 128, 0.5);
  --v-grey-lighten2: rgba(138, 144, 164, 0.5);
  --v-grey-lighten3: rgba(138, 144, 164, 0.3);
}
</style>
<style lang="sass">

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,500;0,600;0,800;1,500&display=swap')

$body-font-family: 'Noto Sans'
$title-font: 'Noto Sans'

.v-application
  background-color: var(--v-background-base) !important

  // Текста
  font-family: $body-font-family, sans-serif !important
  label, [class*='text-'], [class*='__label'], a
    color: var(--v-text-primary) !important

  .title, header [class*='__title']  // To pin point specific classes of some components
    color: var(--v-text-primary)
    font-weight: 600
    line-height: 158%
    font-size: 18px
    letter-spacing: -0.01em
  table thead th span
    color: var(--v-blue-grey-base)
    font-weight: 500
    font-size: 14px
    line-height: 158%
    letter-spacing: -0.01em
  .caption
    font-family: $body-font-family, sans-serif !important
    color: var(--v-blue-grey-base) !important
  .subtitle
    font-style: normal
    font-weight: 600
    font-size: 0.875rem
    color: var(--v-blue-grey-base)

// Кнопки
.v-btn:not(.v-btn--fab):not(.v-btn--icon):not(.v-btn--rounded):not(.btn-normale)
  border-radius:10px!important
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg
  color: var(--v-blue-grey-base) !important
  background-color: var(--v-blue-grey-lighten3) !important
.v-btn
  text-transform: none !important // Отключен капс

.block__content
  border: 1px solid var(--v-blue-grey-lighten3) !important
  border-radius: 10px!important
  background: var(--v-white-lighten1) !important
.block__content>.v-data-table__wrapper
  border-radius: 10px!important

// Нижняя линия у полей для ввода и outlined inputs
div.v-input:not(.v-input--is-focused):not(.error--text) fieldset, div.v-input:not(.v-input--is-focused):not(.error--text) .v-input__slot::before
  border-color: var(--v-blue-grey-lighten3) !important

// Отступы
.v-input__slot
  margin-bottom: 1px !important
.v-text-field
  margin-top: 2px !important

  // Global ScrollBar
/* width */
::-webkit-scrollbar
  width: 5px
/* Track */
::-webkit-scrollbar-track
  background: var(--v-blue-grey-lighten2)
/* Handle */
::-webkit-scrollbar-thumb
  background: var(--v-grey-lighten1)
  border-radius: 2.5px
/* Handle on hover */
::-webkit-scrollbar-thumb:hover
  background: var(--v-primary-base)
</style>
