<template>
  <v-app-bar
    app
    :color="bg"
    flat
    class="menu-tabs"
    :key="keyForUpdate"
    height="50px"
  >
    <v-tabs v-model="activeTab" class="menu-tabs__content" ref="tabs">
      <v-tooltip bottom v-for="tab in $store.state.pageTabs.tabs" :key="tab.id">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <template v-slot:activator="{ on, attrs }">
          <v-tab
            :to="`${tab.route}`"
            class="menu-tabs__item"
            :class="{ 'menu-tabs__item--main': tab.id === 1 }"
            ref="tab"
            v-bind="attrs"
            v-on="on"
          >
            <div
              :class="{
                'menu-tabs__icon-wrap': true,
                'menu-tabs__logo': tab.id === 1,
              }"
            >
              <m-icon
                v-if="tab.id !== 1"
                size="16"
                :icon="UtilGetIcon(tab.nameComponent, tab.pageType)"
                :active="tab.route === $route.meta.normalizeFullPath"
              />
              <img v-else :src="require('../assets/logo.svg')" />
            </div>
            <div class="tab-text">
              <template v-if="tab.tabText">{{ tab.tabText }}</template>
              <template v-else>
                {{ tab.isUnsaved ? "*" : "" }}
                {{
                  localizePage(tab) +
                  (tab.objectText ||
                  (tab.objectId > 0 && tab.name !== "Instructions")
                    ? ":"
                    : "")
                }}
                {{
                  tab.objectText
                    ? truncWithEllipsis(tab.objectText, 45)
                    : tab.objectId > 0 && tab.name !== "Instructions"
                    ? "№" + tab.objectId
                    : ""
                }}</template
              >
            </div>
            <v-btn
              v-if="tab.id !== 1"
              v-bind="{
                'x-small': $store.state.pageTabs.tabs.length >= 10,
                small: $store.state.pageTabs.tabs.length < 10,
              }"
              @click="removeTab($event, { tab })"
              icon
              :class="{
                'menu-tabs__btn-close': true,
                'menu-tabs__btn--hide': tab.route !== activeTab,
              }"
            >
              <m-icon icon="mdi-close" small />
            </v-btn>
          </v-tab>
        </template>

        <template v-if="tab.tabText">{{ tab.tabText }}</template>
        <template v-else>
          {{ tab.isUnsaved ? "*" : "" }}
          {{
            localizePage(tab) +
            (tab.objectText || (tab.objectId > 0 && tab.name !== "Instructions")
              ? ":"
              : "")
          }}
          {{
            tab.objectText
              ? truncWithEllipsis(tab.objectText, 60)
              : tab.objectId > 0 && tab.name !== "Instructions"
              ? "№" + tab.objectId
              : ""
          }}
        </template>
      </v-tooltip>
    </v-tabs>
    <v-btn
      v-if="
        GET_INDEX_CURRENT_TAB >= 0 &&
        $store.state.pageTabs.tabs[GET_INDEX_CURRENT_TAB].id !== 1
      "
      icon
      small
      @click="toggleFavorite($store.state.pageTabs.tabs[GET_INDEX_CURRENT_TAB])"
    >
      <m-icon
        :icon="booleanFavorit ? 'mdi-star' : 'mdi-star-outline'"
        :color="booleanFavorit ? 'var(--v-warning-base)' : ''"
      />
    </v-btn>

    <v-menu bottom left offset-y class="menu-tabs__list">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small v-bind="attrs" v-on="on" style="margin-right: -56px">
          <m-icon icon="mdi-dots-vertical" />
        </v-btn>
      </template>
      <v-btn block depressed tile @click="removeTab($event, { all: true })">
        Закрыть все вкладки
      </v-btn>
      <v-btn
        block
        depressed
        tile
        @click="removeTab($event, { exceptCurrent: true })"
      >
        Закрыть все вкладки кроме текущей
      </v-btn>
      <v-divider></v-divider>
      <v-list class="menu-tabs__list-wrap">
        <v-list-item
          v-for="tab in $store.state.pageTabs.tabs"
          :key="tab.id"
          :to="tab.route"
          class="menu-tabs__list-item"
        >
          <div
            class="menu-tabs__list-icon"
            :class="{ 'menu-tabs__list-logo': tab.id === 1 }"
          >
            <m-icon
              v-if="tab.id !== 1"
              s16
              :icon="UtilGetIcon(tab.nameComponent, tab.pageType)"
              :active="tab.route === $route.meta.normalizeFullPath"
            />
            <img v-else :src="require('../assets/logo.svg')" />
          </div>
          <div class="tab-text tab-text--ellipsis">
            <template v-if="tab.tabText">{{ tab.tabText }}</template>
            <template v-else>
              {{ tab.isUnsaved ? "*" : "" }}
              {{
                localizePage(tab) +
                (tab.objectText || tab.objectId > 0 ? ":" : "")
              }}
              {{
                tab.objectText
                  ? truncWithEllipsis(tab.objectText, 45)
                  : tab.objectId > 0
                  ? "№" + tab.objectId
                  : ""
              }}
            </template>
          </div>
          <v-btn
            v-if="tab.id !== 1"
            icon
            @click.stop.prevent="toggleFavorite(tab)"
            class="menu-tabs__btn--hide"
          >
            <m-icon
              :icon="
                GET_FAVORITE_TAB.find((e) => e.route === tab.route)
                  ? 'mdi-star'
                  : 'mdi-star-outline'
              "
              :color="
                GET_FAVORITE_TAB.find((e) => e.route === tab.route)
                  ? 'var(--v-warning-base)'
                  : ''
              "
            />
          </v-btn>
          <v-btn
            v-if="tab.id !== 1"
            icon
            @click.stop="removeTab($event, { tab })"
            class="menu-tabs__btn--hide"
          >
            <m-icon icon="mdi-close" small />
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { localizePageTabs } from "../data/localization";

export default {
  data: () => {
    return {
      bg: "transparent",
    };
  },
  computed: {
    ...mapGetters(["IS_AUTH"]),
    ...mapGetters("pageTabs", ["GET_INDEX_CURRENT_TAB"]),
    ...mapState("pageTabs", ["keyForUpdate"]),
    ...mapGetters("favoriteTab", ["GET_FAVORITE_TAB"]),
    activeTab: {
      get() {
        return this.$store.state.pageTabs.activeTab;
      },
      set(value) {
        this.$store.commit("pageTabs/SET_ACTIVE_TAB", value);
      },
    },
    localizePageTabs() {
      return localizePageTabs;
    },
    booleanFavorit() {
      return this.GET_FAVORITE_TAB.find(
        (e) =>
          e.route ===
          this.$store.state.pageTabs.tabs[this.GET_INDEX_CURRENT_TAB].route
      );
    },
  },
  watch: {
    "$store.state.pageTabs.tabs": function (val) {
      this.changeTypeSizeTubs();
    },
    activeTab() {
      // После перехода по табам или добавлении нового, пересчитывать длину полоски с низу
      setTimeout(() => {
        this.$refs.tabs.callSlider();
      }, 1000);
    },
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };

    window.addEventListener("resize", () => {
      this.changeTypeSizeTubs();
    });
  },
  methods: {
    localizePage(tab) {
      if (typeof this.localizePageTabs[tab.name] === "object") {
        return this.localizePageTabs[tab.name][tab.$route.params.type];
      } else return this.localizePageTabs[tab.name];
    },
    async removeTab(e, { tab, all = false, exceptCurrent = false }) {
      e.preventDefault();
      const tabs = this.$store.state.pageTabs.tabs;
      const currentTab = tabs[this.GET_INDEX_CURRENT_TAB];

      if (all || exceptCurrent) {
        for (let j = tabs.length - 1; j > 0; j--) {
          if (currentTab.id !== tabs[j].id) {
            await this.$store.dispatch("pageTabs/CLOSE_TAB", {
              tab: tabs[j],
              _router: this.$router,
            });
          }
        }
      }

      const res = all ? currentTab : tab;
      if (!exceptCurrent) {
        await this.$store.dispatch("pageTabs/CLOSE_TAB", {
          tab: res,
          _router: this.$router,
        });
      }
    },
    changeColor() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        this.bg = "var(--v-white-lighten1)";
      } else {
        this.bg = "transparent";
      }
    },
    truncWithEllipsis(s, maxLength) {
      if (s && s.length > maxLength) return s.substr(0, maxLength - 3) + "...";
      return s;
    },

    changeTypeSizeTubs() {
      setTimeout(() => {
        if (this.$refs?.tab?.length < 2) return;
        if (this.$refs.tab[1].$el.offsetWidth < 65) {
          this.$refs.tabs.$el.classList.add("menu-tabs--mini");
        } else {
          this.$refs.tabs.$el.classList.remove("menu-tabs--mini");
        }
      }, 500);
    },
    toggleFavorite(tab) {
      this.$store.dispatch("favoriteTab/TOGGLE_FAVORITE", tab);
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-tabs {
  box-shadow: 0 11px 15px var(--v-text-lighten3) !important;

  &__content {
    border-radius: 5px;
    align-self: flex-end;
  }

  &__item {
    position: relative;
    width: 100%;
    min-width: 0;
    max-width: 200px;
    padding: 0 10px;
    justify-content: space-between;
    line-height: 1;
    text-align: start;
    &--main {
      max-width: 125px !important;
    }

    &:hover .menu-tabs__btn--hide {
      display: block;
    }
  }

  &__icon-wrap {
    margin-right: 10px;
  }

  &__logo {
    margin-bottom: 4px;
    margin-right: 12px;
  }

  &__btn--hide {
    display: none;
  }

  &__list-icon {
    margin-left: 10px;
    margin-right: 10px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  &__list-logo {
    margin-left: 13px;
    margin-right: 11px;
    margin-top: 2px;
  }

  &__list-wrap {
    width: 400px;
    padding: 0;
  }

  &__list-item:hover .menu-tabs__btn--hide {
    display: block;
  }

  &--mini {
    .tab-text {
      display: none;
    }

    .menu-tabs__btn-close {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: none;
    }

    .menu-tabs__item {
      justify-content: center;

      .menu-tabs__icon-wrap {
        margin-right: 0;
      }

      &:hover .menu-tabs__icon-wrap:not(.menu-tabs__logo) {
        display: none;
      }

      &:hover .menu-tabs__btn--hide {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      &:hover .menu-tabs__btn-close {
        display: block;
      }
    }
  }
}

.tab-text {
  width: 100%;
  letter-spacing: normal;
  font-size: 14px;
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
  &--ellipsis {
    text-overflow: ellipsis;
  }
}
</style>

<style lang="scss">
// отображение активной вкладки при наведении как неактивной
.theme--light.v-tabs .v-tab--active:hover::before,
.theme--light.v-tabs .v-tab--active::before {
  opacity: 0.04 !important;
}

.theme--light.v-tabs .v-tab--active::before {
  opacity: 0 !important;
}

.v-tabs .v-tab:hover {
  background-color: var(--v-blue-grey-lighten4) !important;
}

.menu-tabs {
  .v-toolbar__content {
    padding-right: 60px;
  }

  &__content {
    .v-slide-group__content {
      width: 100%;
    }
  }
}
</style>
