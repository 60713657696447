<template>
  <div class="hero__news news">
    <v-skeleton-loader
      v-if="loading"
      type="heading"
      height="auto"
    ></v-skeleton-loader>
    <header class="pl-3" v-else>
      <h2 class="text-uppercase news__title primary--text">
        Новости от медэксперта
      </h2>
    </header>
    <v-list class="tile flat news__list">
      <v-skeleton-loader
        type="list-item, list-item, list-item"
        v-if="loading"
      ></v-skeleton-loader>
      <v-list-item
        v-for="item in newsList"
        :key="item.id"
        :class="`d-flex align-start ${!item.Read ? 'font-weight-bold' : ''}`"
        @click="showNews(item)"
        v-else
      >
        <span class="mr-5">{{ item.Created | DateOnly }}</span>
        <p>
          {{ item.Title }}
        </p>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
export default {
  name: "NewsCard",
  props: {
    news: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      newsList: [],
    };
  },
  watch: {
    news() {
      this.loading = true;
      this.init();
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      try {
        const { data } = await this.news.request;
        this.newsList = data;
        this.loading = false;
      } catch (e) {
        console.debug(e);
      }
    },
    showNews(item) {
      if (!item.Read) this.$set(item, "Read", true);
      this.$store.dispatch("frame/RUN_COMPONENT", {
        componentName: "news/NewsEdit",
        params: {
          _objectId: item.Id,
          persistent: true,
          color: "#FBFBFB",
          dialogHeight: "auto",
        },
      });
    },
  },
};
</script>

<style lang="scss">
.news {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 20px;
  width: calc(100% * 1 / 3);
  height: auto;

  &.v-sheet,
  & .theme--light.v-list {
    background: none;
  }

  &__list {
    padding: 0;
    font-size: 14px;
    overflow-y: auto;
    overscroll-behavior: contain;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      text-wrap: balance;
    }

    & .v-list-item {
      padding: 12px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      min-height: unset;

      &--link::before {
        background-color: unset;
      }

      &:hover {
        background-color: var(--v-blue-grey-lighten2);
        cursor: pointer;
      }
    }
  }

  @media (width <= 1440px) {
    width: 100%;
    height: 180px;
    padding: 0 4px;
  }
}
</style>
