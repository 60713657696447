<template>
  <base-popup
    :title="dialogTitle"
    :icon="UtilGetIcon(getPageIcon)"
    @save="save"
    v-model="dialog"
    :width="width"
    :height="height"
    v-bind="$attrs"
  >
    <template v-slot:activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <v-container class="d-flex" fill-height>
      <v-row class="d-flex fill-height">
        <v-col v-if="selectType !== 'position'" class="fill-height">
          <base-panel title="Подразделения" class="fill-height">
            <base-search slot="action" v-model="search[0]" />
            <div
              class="v-treeview theme--light block__content fill-height d-flex flex-column"
            >
              <div class="v-treeview-node" v-if="selectType === 'partition'">
                <div class="v-treeview-node__root">
                  <v-simple-checkbox
                    :value="selectedAll"
                    :ripple="false"
                    @input="
                      ($event) => {
                        selectedAll = $event;
                        selectPartitions = $event ? partitions : [];
                      }
                    "
                    color="var(--v-accent-base)"
                    style="margin-left: 30px; margin-right: 0"
                  />
                  <div class="v-treeview-node__content">
                    <div class="v-treeview-node__label">Все подразделения</div>
                  </div>
                </div>
              </div>

              <v-treeview
                :value="selectPartitions"
                @input="setSelectPartitions"
                :items="partitions"
                :search="search[0]"
                :selection-type="
                  selectType === 'positionPartition' || needIndependent
                    ? 'independent'
                    : null
                "
                item-key="Id"
                item-text="Name"
                item-children="Children"
                selectable
                return-object
                class="fill-height"
                style="overflow-y: auto"
                ><template slot="label" slot-scope="{ item }">
                  <span
                    v-if="
                      selectType !== 'positionPartition' ||
                      !item.selectPositions.length
                    "
                  >
                    {{ item.Name }}
                  </span>
                  <span
                    v-if="
                      selectType === 'positionPartition' &&
                      item.selectPositions.length
                    "
                    class="font-weight-bold"
                  >
                    {{ item.Name + " (" + item.selectPositions.length + ")" }}
                  </span>
                </template>
              </v-treeview>
            </div>
          </base-panel>
        </v-col>
        <v-col
          v-if="
            selectType === 'position' ||
            selectType === 'employee' ||
            selectType === 'positionPartition'
          "
          class="fill-height"
        >
          <base-panel title="Должности" class="fill-height">
            <base-search slot="action" v-model="search[1]" />
            <base-table
              v-model="selectPositions"
              :items="
                selectType === 'positionPartition' ? positions : filterPositions
              "
              :search-text="search[1]"
              :headers="positionHeaders"
              hide-default-footer
              :showSettings="false"
            >
              <template v-slot:body.append="{ headers }">
                <tr v-if="!positionsLoading">
                  <td
                    :colspan="headers.length"
                    v-if="
                      selectType === 'positionPartition' &&
                      (!selectPartitions.length ||
                        (selectPartitions.length && positions.length === 0))
                    "
                    class="font-weight-bold"
                  >
                    {{
                      !selectPartitions.length
                        ? "Выберите подразделение!"
                        : "В подразделении нет должностей!"
                    }}
                  </td>
                </tr>
              </template>
            </base-table>
          </base-panel>
        </v-col>
        <v-col v-if="selectType === 'employee'" class="fill-height">
          <base-panel title="Сотрудники" class="fill-height">
            <base-search slot="action" v-model="search[2]" />
            <base-table
              v-model="selectEmployees"
              :search-text="search[2]"
              :items="filteredEmployees"
              :headers="employeeHeaders"
              hide-default-footer
              :showSettings="false"
            >
              <template slot="item.Name" slot-scope="{ item }">
                {{ item.Name ? item.Name : "" }}
              </template>
            </base-table>
          </base-panel>
        </v-col>
      </v-row>
    </v-container>
  </base-popup>
</template>

<script>
import PositionService from "../../../services/PositionService";
import BasePopup from "../../../layouts/BasePopup.vue";
import { mapActions } from "vuex";
import BasePanel from "../../../layouts/BasePanel.vue";
import BaseTable from "../../base/BaseTable.vue";
import BaseSearch from "../../base/BaseSearch.vue";
import DataHelper from "../../../utils/DataHelper";
import { numWord } from "../../../utils/Functions";

export default {
  components: { BasePopup, BasePanel, BaseTable, BaseSearch },
  props: {
    value: { type: Array, default: () => [] },
    width: String,
    height: String,
    dialogTitle: {
      type: String,
      default: "Выбор сотрудников",
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    selectType: {
      type: String,
      default: "employee",
      validator: function (value) {
        return [
          "employee",
          "position",
          "partition",
          "positionPartition",
        ].includes(value);
      },
    },
    sourceEmployees: {
      type: Array,
      default: null,
    },
    filterEmployees: {
      type: Function,
      default: null,
    },
    needIndependent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedAll: false,
      search: ["", "", ""],
      partitions: [],
      positions: [],
      employees: [],
      selectEmployees: [],
      selectPositions: [],
      selectPartitions: [],
      positionHeaders: [{ text: "Должности", value: "Name" }],
      employeeHeaders: [
        {
          text: "Сотрудники",
          value: "Name",
        },
      ],
      dialog: false,
      positionsLoading: false,
    };
  },
  computed: {
    filteredEmployees() {
      let tmp = this.employees.filter((e) => {
        return (
          (e?.User?.IsActive ||
            this.selectEmployees.some((e2) => e2.Id === e?.Id)) &&
          (!this.selectPartitions?.length ||
            this.selectEmployees.some((e2) => e2.Id === e?.Id) ||
            this.selectPartitions.some((e2) => e2.Id === e?.Place?.Id) ||
            e.WorkPlaces?.some((w) =>
              this.selectPartitions.some((p) => p.Id === w?.Place?.Id)
            ))
        );
      });

      tmp = tmp.filter((e) => {
        return (
          !this.selectPositions?.length ||
          this.selectEmployees.some((e2) => e2.Id === e?.Id) ||
          this.selectPositions.some((e2) => e2.Id === e?.Position?.Id) ||
          e.WorkPlaces?.some((w) =>
            this.selectPositions.some((p) => p.Id === w?.Position?.Id)
          )
        );
      });
      return tmp;
    },
    filteredEmployeesOfPartitions() {
      const tmp = this.employees.filter((e) => {
        return e.WorkPlaces?.some((w) =>
          this.selectPartitions.some((e2) => e2.Id === w?.Place?.Id)
        );
      });
      return tmp;
    },
    filterPositions() {
      const filteredPositions = this.positions.filter((p) => {
        return (
          !this.selectPartitions?.length ||
          this.filteredEmployeesOfPartitions.some((p2) =>
            p2.WorkPlaces?.some(
              (w) =>
                w?.Position?.Id === p.Id &&
                this.selectPartitions.some((p3) => p3.Id === w?.Place?.Id)
            )
          )
        );
      });
      return filteredPositions;
    },
    getPageIcon() {
      return this.selectType === "employee"
        ? "view-Employees"
        : this.selectType === "position"
        ? "view-Positions"
        : this.selectType === "partition" ||
          this.selectType === "positionPartition"
        ? "view-Partitions"
        : null;
    },
  },
  watch: {
    showDialog(val) {
      this.dialog = val;
    },
    selectPartitions: {
      async handler(e) {
        this.positionsLoading = true;

        if (!this.selectPartitions.length) {
          this.selectPositions = [];
          if (this.selectType === "positionPartition") this.positions = [];
        }
        if (this.selectType === "positionPartition" && e.length) {
          this.positions = (await PositionService.getByPartition(e[0].Id)).data;
          if (!e[0].selectPositions) {
            e[0].selectPositions = [];
          } else {
            this.selectPositions = e[0].selectPositions;
          }
        }
        this.positionsLoading = false;
      },
    },
    selectPositions: {
      handler(e) {
        if (
          this.selectType === "positionPartition" &&
          this.selectPartitions.length
        ) {
          this.selectPartitions[0].selectPositions = e;
        }
      },
    },
    async sourceEmployees() {
      await this.buildEmployees();
      this.updateEmployees();
    },
    async value() {
      await this.buildEmployees();
      this.updateEmployees();
      this.updateSelect();
    },
    dialog(val) {
      // Сбрасывать всё выбранное после открытия диалога
      if (val) {
        this.selectEmployees = [];
        this.selectPositions = [];
        this.selectPartitions = [];
        this.updateSelect();
      }
      this.$emit("update:showDialog", val);
    },
    selectType: {
      immediate: true,
      async handler(val) {
        this.selectEmployees = [];
        this.selectPositions = [];
        this.selectPartitions = [];

        this.updateSelect();

        if (val === "partition" || val === "employee" || val === "position") {
          this.positions = (await PositionService.get(true)).data;
        }
      },
    },
  },
  async mounted() {
    this.partitions = await this.LOAD_PARTITIONS();

    await this.buildEmployees();
    this.updateEmployees();
  },
  methods: {
    ...mapActions(["LOAD_EMPLOYEES", "LOAD_PARTITIONS"]),
    async buildEmployees() {
      const employees = await this.LOAD_EMPLOYEES();
      if (this.sourceEmployees) {
        this.employees = this.sourceEmployees.map((e) =>
          employees.find((e2) => e2.Id === e.Id)
        );
      } else {
        if (this.filterEmployees) {
          this.employees = this._.cloneDeep(employees).filter(
            this.filterEmployees
          );
        } else this.employees = this._.cloneDeep(employees);
      }
    },
    updateEmployees() {
      // Добавление формата отображения
      for (const employee of this.employees) {
        employee.Name =
          this.$options.filters.PersonShortName(employee) +
          (employee?.WorkPlaces?.length
            ? "; " +
              employee.WorkPlaces.map((workPlace) => workPlace?.Position?.Name)
                .filter((name, index, self) => {
                  return self.indexOf(name) === index;
                })
                .sort((a, b) => a.localeCompare(b, { sensitivity: "base" }))
                .join(", ")
            : "");
      }
      // Сортировка сотрудников
      this.employees.sort((a, b) => {
        const selectA = this.selectEmployees.find((e) => e.Id === a.Id);
        const selectB = this.selectEmployees.find((e) => e.Id === b.Id);
        const res = selectA && !selectB ? -1 : !selectA && selectB ? 1 : 0;

        return res !== 0 ? res : a.Name.localeCompare(b.Name);
      });
    },
    setSelectPartitions(array) {
      if (this.selectType === "positionPartition" && array.length) {
        this.selectPartitions = array.filter((e) => e.Id === array.at(-1).Id);
      } else {
        this.selectPartitions = array;
      }
    },
    updateSelect() {
      if (this.selectType === "employee") {
        this.selectEmployees = this.value
          .filter((e) => e.Employee)
          .map((e) => e.Employee);
      } else if (this.selectType === "position") {
        this.selectPositions = this.value
          .filter((e) => e.Position && !e.Partition)
          .map((e) => e.Position);
      } else if (this.selectType === "partition") {
        this.selectPartitions = this.value
          .filter((e) => e.Partition && !e.Position)
          .map((e) => e.Partition);
      } else if (this.selectType === "positionPartition") {
        DataHelper.treeListTraverse(this.partitions, (e) => {
          e.selectPositions = this.value
            .filter(
              (e2) => e2.Partition && e2.Position && e2.Partition.Id === e.Id
            )
            .map((e2) => e2.Position);
        });
      }
    },
    async save() {
      if (this.selectType === "employee") {
        const removeEmployees = this.value.filter(
          (e) =>
            e.Employee &&
            !this.selectEmployees.find((e2) => e2.UserId === e.Employee.UserId)
        );

        if (
          removeEmployees.length &&
          !(await this.$confirm(
            "<b>Вы хотите исключить " +
              removeEmployees.length +
              numWord(removeEmployees.length, [
                "сотрудника",
                "сотрудников",
                "сотрудников",
              ]) +
              removeEmployees
                .map((e) => this.$options.filters.PersonShortName(e.Employee))
                .map((e) => "</br>- " + e),
            { buttonTrueText: "Исключить", center: false, extionSticky: true }
          ))
        )
          return;
        this.$emit("input", this.selectEmployees);
      } else if (this.selectType === "position") {
        this.$emit("input", this.selectPositions);
      } else if (this.selectType === "partition") {
        this.$emit("input", this.selectPartitions);
      } else if (this.selectType === "positionPartition") {
        const tmpArr = [];
        DataHelper.treeListTraverse(this.partitions, (e) => {
          if (e.selectPositions) {
            tmpArr.push(e);
          }
        });
        this.$emit("input", tmpArr);
      }
      this.selectPositions = [];
      this.selectPartitions = [];
      this.dialog = false;
    },
  },
};
</script>

<style></style>
