<template>
  <base-list-view
    title="Статистические показатели"
    @onRefresh="init"
    @onDelete="del"
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    :selectedItems.sync="selected"
    pageEditName="StatisticIndicatorEdit"
    id="statistic-indicator-values"
    ref="table"
    :funcHandleClickId="(item) => (item.ParentId ? item.ParentId : item.Id)"
    fixedWidthActions
    :showCompleted.sync="showCompleted"
    showCompletedText="Показать архивные показатели"
    :exclude-settings-actions="['group']"
    :showFilters="true"
  >
    <template slot="actionBefore" slot-scope="{ isShowSearch }">
      <div v-if="!isShowSearch" class="d-flex align-center">
        <span class="mr-2" style="font-size: 14px">Итоги за период: </span>
        <base-date-picker-range
          :value="dates"
          @input="updateAllPeriods"
          :label="''"
          outlined
          hide-details
          dense
        />
      </div>
    </template>
    <template slot="table" slot-scope="{ attrs, listeners }">
      <base-tree-table
        v-model="selected"
        v-bind="attrs"
        v-on="listeners"
        :items="dataSource"
        options="ObjectsSummary"
        tree-table-contents
        :item-class="(e) => e.IsNotified && 'font-weight-bold'"
      >
        <template slot="item.DiffValue" slot-scope="scope">
          <template v-if="scope.item.DiffValue === undefined">-</template>
          <template v-else>
            <v-chip
              :class="
                StatisticIndicatorHelper.getColorDiff(
                  scope.item.DiffValue,
                  scope.item.RatingType
                )
              "
            >
              {{
                (scope.item.DiffValue > 0 ? "+" : "") +
                (scope.item.DiffValue
                  ? Number(scope.item.DiffValue.toFixed(2))
                  : "-")
              }}</v-chip
            ><span class="statistic-indicator-values__period">
              {{ "за " + periods[scope.item.Period] }}
            </span>
          </template>
        </template>
        <template slot="item.Value" slot-scope="{ item }">
          <v-chip
            class="statistic-indicator-values__chip-group"
            :class="
              StatisticIndicatorHelper.getColorByRefs(
                [item.RefValue1, item.RefValue2, item.RefValue3],
                item.Value,
                item.RatingType
              )
            "
          >
            {{ item.Value === null ? "-" : Number(item.Value.toFixed(2)) }}
          </v-chip>
        </template>
        <template slot="item.Date" slot-scope="{ item }">
          {{ item.Date }}
        </template>
        <template slot="item.PeriodValue" slot-scope="{ item }">
          <div v-if="!item.Level">
            <span>
              {{
                item.PeriodValue
                  ? parseFloat(parseFloat(item.PeriodValue).toFixed(2))
                  : "-"
              }}
            </span>
          </div>
        </template>
        <template slot="item.Period" slot-scope="{ item }">
          <div
            v-if="!item.Level"
            class="d-flex align-center"
            style="width: 220px"
          >
            <base-date-picker-range
              :value="[item.PeriodDate.DateIn, item.PeriodDate.DateOut]"
              @input="updatePeriod($event, item)"
              :label="''"
              hide-details
              dense
              left
              :rightered="'true'"
              :hideUnderline="'true'"
              :append-icon="'mdi-chevron-down'"
              :small="'true'"
            />
          </div>
          <div v-else class="d-flex align-center" style="width: 220px"></div>
        </template>
        <template slot="item.Owner" slot-scope="{ item }">
          {{
            item.Owner.Id === $store.getters.GET_ORGANIZATION.OwnerId
              ? "-"
              : item.Owner.Name
          }}
        </template>
        <template #item.data-table-expand="{ item, expand, isExpanded }">
          <td v-if="item.Children && item.Children.length" class="text-start">
            <v-btn
              icon
              @click.stop="expand(!isExpanded)"
              class="v-data-table__expand-icon"
              :class="{ 'v-data-table__expand-icon--active': isExpanded }"
            >
              <m-icon icon="mdi-chevron-down"></m-icon>
            </v-btn>
          </td>
        </template>
      </base-tree-table>
    </template>
  </base-list-view>
</template>
<script>
import ListEditMixin from "@/mixins/ListEditMixin";
import StatisticIndicatorService from "../../services/StatisticIndicatorService";
import BaseListView from "../../layouts/BaseListView.vue";
import StatisticIndicatorHelper from "./StatisticIndicatorHelper";
import baseTreeTable from "../../components/base/BaseTreeTable.vue";
import BaseDatePickerRange from "../../components/base/BaseDatePickerRange";
import DataHelper from "../../utils/DataHelper";

export default {
  name: "view-StatisticIndicators",
  components: {
    BaseListView,
    baseTreeTable,
    BaseDatePickerRange,
  },
  mixins: [ListEditMixin],
  data() {
    return {
      apiService: StatisticIndicatorService,
      loading: true,
      items: [{ title: "План мероприятий по устранению несоответсвий" }],
      headers: [
        {
          text: "Название статистического показателя",
          value: "Name",
        },
        {
          text: "Ед. изм.",
          value: "Units",
          align: "center",
          cellClass: "td-minimum td1",
          title: "Ед. измерения",
        },
        {
          text: "Дата сбора",
          value: "Date",
          dataType: "Date",
          align: "center",
          cellClass: "td-minimum td2",
          title: "Дата сбора данных",
        },
        {
          text: "Значение",
          value: "Value",
          align: "center",
          width: "1%",
          cellClass: "td-minimum td3",
          notGroup: true,
        },
        {
          text: "Динамика",
          value: "DiffValue",
          width: "1%",
          cellClass: "td-minimum td4",
          notGroup: true,
        },
        {
          text: "Итоги за период",
          value: "PeriodValue",
          align: "center",
          width: "1%",
          sortable: false,
          cellClass: "td-minimum td5",
          notGroup: true,
        },
        {
          text: "Выбор периода",
          value: "Period",
          align: "center",
          width: "1%",
          sortable: false,
          cellClass: "td-minimum td6",
          notGroup: true,
        },

        this.$store.getters.GET_ORGANIZATION.OwnerType === 2
          ? {
              text: "Другие МО",
              value: "ResponsibleOwnersCount",
              align: "center",
              width: "1%",
              sortable: false,
              cellClass: "td-minimum td8",
              notGroup: true,
            }
          : this.$store.getters.GET_ORGANIZATION.OwnerType === 1
          ? {
              text: "Головная организация",
              value: "Owner",
              dataType: "object",
              align: "center",
              displayText: (item) => item.Name,
              width: "1%",
              sortable: false,
              cellClass: "td-minimum td7",
              notGroup: true,
            }
          : null,
      ],
      dataSource: [],
      groups: {},
      periods: ["", "неделю", "месяц", "квартал", "полгода", "год", "день"],
      selected: [],
      dates: [],
      showCompleted: "notdone",
    };
  },
  computed: {
    StatisticIndicatorHelper() {
      return StatisticIndicatorHelper;
    },
  },
  watch: {
    dataSource: function () {
      setTimeout(() => {
        this.collapseAll();
      }, 1000);
    },
  },
  async created() {
    await this.init();
    this.collapseAll(true);
  },
  methods: {
    async init() {
      this.dates = [];
      await this.baseInit();
    },
    collapseAll(first) {
      Object.keys(this.$refs).forEach((k) => {
        if (
          this.$refs[k] &&
          this.$refs[k].$attrs["data-open"] &&
          (this.$refs[k].$attrs["data-targetType"] === 0 || first === true)
        ) {
          this.$refs[k].$el.click();
        }
      });
    },
    DataFromServerNormalize(data) {
      for (let i = 0; i < data.length; i++) {
        data[i].Date = data[i]?.LastValueDate?.substr(0, 10) || "-";
        data[i].Value = data[i].LastValue;
        for (let j = 0; j < data[i]?.ObjectsSummary?.length; j++) {
          data[i].ObjectsSummary[j].Name =
            data[i].ObjectsSummary[j].ObjectDescription;
          data[i].ObjectsSummary[j].ParentId = data[i].Id;
          data[i].ObjectsSummary[j].Level = 1;
          data[i].ObjectsSummary[j].RefValue1 = data[i].RefValue1;
          data[i].ObjectsSummary[j].RefValue2 = data[i].RefValue2;
          data[i].ObjectsSummary[j].RefValue3 = data[i].RefValue3;
          data[i].ObjectsSummary[j].RatingType = data[i].RatingType;
          data[i].ObjectsSummary[j].Date = data[i].ObjectsSummary[
            j
          ]?.Date?.substr(0, 10);
        }
      }
      return data;
    },
    async updateAllPeriods(value) {
      if (value?.length === 2) {
        for (const indicator of this.dataSource) {
          indicator.PeriodDate.DateIn = value[0];
          indicator.PeriodDate.DateOut = value[1];
          indicator.PeriodValue = (
            await StatisticIndicatorService.getValueSum(
              indicator.Id,
              DataHelper.toDate(value[0]),
              DataHelper.toDate(value[1])
            )
          ).data;
        }
      }
    },
    async updatePeriod(value, indicator) {
      if (value?.length === 2) {
        const sum = (
          await StatisticIndicatorService.getValueSum(
            indicator.Id,
            DataHelper.toDate(value[0]),
            DataHelper.toDate(value[1])
          )
        ).data;

        const index = this.dataSource.findIndex((s) => s.Id === indicator.Id);
        if (index !== -1) {
          this.dataSource[index].PeriodValue = sum;
          this.dataSource[index].PeriodDate.DateIn = DataHelper.toDate(
            value[0]
          );
          this.dataSource[index].PeriodDate.DateOut = DataHelper.toDate(
            value[1]
          );
        }
      }
    },
  },
};
</script>
<style lang="scss">
#statistic-indicator-values {
  tr.v-row-group__header {
    td:nth-child(2) {
      padding-left: 0.5rem;
      padding-right: 0;
    }
    td:nth-child(7) {
      text-align: center;
    }
  }
  .td1 {
    width: 151px;
  }
  .td2 {
    width: 182px;
  }
  .td3 {
    width: 118px;
  }
  .td4 {
    width: 160px;
    min-width: 160px;
    white-space: nowrap;
  }
  .td5 {
    width: 168px;
    min-width: 168px;
    white-space: nowrap;
  }
}
</style>
