var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"title":''}},[_c('div',{class:{
      'block__content-header_fixed': _vm.fixedHeader,
      'base-panel__header-fill': _vm.fillHeader,
    }},[(!_vm.headerHidden)?_c('v-toolbar',{staticClass:"block__content-header",class:{
        'header--together': _vm.headerTogether,
      },attrs:{"flat":"","height":_vm.fixedHeader ? 50 : _vm.headerHeight}},[_vm._t("header",function(){return [_vm._t("icon",function(){return [(_vm.logo.length > 1)?_c('img',{staticClass:"mr-4",attrs:{"src":_vm.logo}}):(!(_vm.icon === null))?_c('m-icon',{staticClass:"mr-4",attrs:{"icon":_vm.icon,"active":""}}):_vm._e()]}),_vm._t("title",function(){return [_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_vm._t("subTitle",function(){return [(_vm.subTitle)?_c('span',{staticClass:"caption ml-5"},[_vm._v(_vm._s(_vm.subTitle))]):_vm._e()]})],2)]})]}),_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","bottom":"","height":"2"}}),(!_vm.notSpacerAction)?_c('v-spacer'):_vm._e(),_vm._t("action")],2):_vm._e(),(_vm.tabs)?_c('div',[_c('m-tabs',{attrs:{"value":_vm.tab},on:{"change":function($event){return _vm.$emit('update:tab', $event)}}},_vm._l((_vm.tabs),function(tab,i){return _c('v-tab',{key:i},[_vm._v(_vm._s(tab))])}),1)],1):_vm._e()],1),_c('div',{staticClass:"base-panel__body pb-2 fill-height",class:{
      'pt-2': !_vm.headerTogether && !_vm.headerHidden,
      'header--together': _vm.headerTogether,
    }},[_vm._t("default",function(){return [_vm._v(" No slot content defined. ")]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }