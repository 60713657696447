// {
//   serverName - По название словарь грузит с сервера
//   name - Название словаря
// }

export default {
  AuditIndicators: {
    items: "auditIndicators",
    action: "LOAD_AUDIT_INDICATORS",
    componentEdit: "common/AuditIndicatorEdit",
  },
  Employees: {
    items: "employees",
    action: "LOAD_EMPLOYEES",
    componentEdit: "organization/EmployeeEdit",
    itemText: (vm, item) =>
      vm.$options.filters.PersonShortName(item) +
      (item.Position ? ", " + item.Position.Name : ""),
  },
  Position: {
    items: "positions",
    action: "LOAD_POSITIONS",
  },
  Partition: {
    items: "partitions",
    action: "LOAD_PARTITIONS",
    componentEdit: "organization/PartitionEdit",
  },
  CheckList: {
    items: "CheckLists",
    action: "LOAD_CHECK_LISTS",
    componentEdit: "CheckList/CheckListEdit",
  },
  Role: {
    items: "roles",
    action: "LOAD_ROLES",
    componentEdit: null,
  },
  Project: {
    items: "",
    action: "",
    componentEdit: "task/ProjectEdit",
  },
  СourseCategories: {
    items: "loaderDataSources.courseCategories",
    action: "loaderDataSources/LOAD_COURSE_CATEGORIES",
    componentEdit: "KnowledgeBaseSubjects",
    componentEditPage: true,
  },
  AuditIndicatorCategories: {
    items: "auditIndicatorCategories",
    action: "LOAD_AUDIT_INDICATOR_CATEGORIES",
    componentEdit: "AuditIndicatorCategories",
    componentEditPage: true,
  },
  Organizations: {
    items: "loaderDataSources.organizations",
    action: "loaderDataSources/LOAD_ORGANIZATIONS",
    componentEdit: null,
  },
  DocumentTemplateAll: {
    items: "loaderDataSources.documentTemplateAll",
    action: "loaderDataSources/LOAD_DOCUMENT_TEMPLATE_ALL",
    componentEdit: "document/DocumentTemplateEdit",
  },
};
