<template>
  <v-layout>
    <base-detail-view
      :title="title"
      :sub-title="subtitle"
      :loading="loading"
      :isUnsaved="isUnsaved && valid"
      @onSave="save"
      @onRefresh="init"
      :tabs="[
        'Карточка плана',
        'Мероприятия (' +
          (dataSource && dataSource.Total ? dataSource.Total : 0) +
          ')',
        'Диаграмма Ганта (' +
          (dataSource && dataSource.Total ? dataSource.Total : 0) +
          ')',
      ]"
      :tab.sync="tab"
    >
      <report-activator
        v-if="dataSource.Type === 1"
        slot="action"
        :report="['ProjectPlanReport']"
        :params="{
          objectId: { name: 'pPlanId', value: $route.params.objectId },
        }"
      />
      <v-tabs-items :value="tab">
        <v-tab-item :key="0">
          <v-form class="block__content" v-model="valid" :readonly="readonly">
            <v-container>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="dataSource.Name"
                    label="Название"
                    maxlength="200"
                    required
                    :rules="[rules.required]"
                    :readonly="readonly"
                    :clearable="!readonly"
                    v-up-first-symbol
                    auto-grow
                    rows="1"
                  ></v-textarea>
                  <v-textarea
                    v-model="dataSource.Content"
                    label="Описание"
                    maxlength="1000"
                    auto-grow
                    rows="3"
                    :readonly="readonly"
                    :clearable="!readonly"
                    v-up-first-symbol
                  ></v-textarea>
                  <m-autocomplete
                    v-model="dataSource.Responsible"
                    :label="
                      this.dataSource && this.dataSource.Type === 0
                        ? 'Руководитель'
                        : 'Ответственный'
                    "
                    :items="
                      IsCurrentOwner ? employees : [dataSource.Responsible]
                    "
                    :readonly="readonly"
                    :clearable="!readonly"
                    filter="EmployeeActive"
                    :dict="{ name: 'Employees', notItems: true }"
                    :textCustomAdd="'Создать нового сотрудника'"
                    return-object
                  ></m-autocomplete>
                  <m-autocomplete
                    v-model="dataSource.Authors"
                    label="Авторы"
                    multiple
                    deletable-chips
                    required
                    :items="IsCurrentOwner ? employees : dataSource.Authors"
                    :readonly="readonly"
                    :clearable="!readonly"
                    :rules="[rules.required]"
                    filter="EmployeeActive"
                    :dict="{ name: 'Employees', notItems: true }"
                    :textCustomAdd="'Создать нового сотрудника'"
                    return-object
                  ></m-autocomplete>
                  <m-autocomplete
                    v-model="dataSource.Notify"
                    label="Участники"
                    multiple
                    deletable-chips
                    :items="IsCurrentOwner ? employees : dataSource.Notify"
                    :readonly="readonly"
                    :clearable="!readonly"
                    filter="EmployeeActive"
                    :dict="{ name: 'Employees', notItems: true }"
                    :textCustomAdd="'Создать нового сотрудника'"
                    return-object
                  ></m-autocomplete>
                </v-col>
                <v-col>
                  <base-date-picker
                    v-model="dataSource.Period.DateIn"
                    label="Дата начала"
                    :rules="[rules.required]"
                    :readonly="readonly"
                    :clearable="!readonly"
                    required
                  ></base-date-picker>
                  <base-date-picker
                    v-model="dataSource.Period.DateOut"
                    label="Дата окончания"
                    :readonly="readonly"
                    :clearable="!readonly"
                  ></base-date-picker>
                  <base-date-picker
                    v-model="dataSource.Done"
                    label="Дата фактического завершения"
                    :readonly="isActualCompletionDate"
                    :clearable="!readonly"
                  ></base-date-picker>
                  <m-autocomplete
                    v-model="dataSource.AuditOperations"
                    :items="audits"
                    :item-text="
                      (item) =>
                        `Проверка №${item.Num}, ${$options.filters.DateShort(
                          item.Period.DateIn
                        )} - ${$options.filters.DateShort(item.Period.DateOut)}`
                    "
                    @change="changeAudits"
                    label="Проверки"
                    deletable-chips
                    multiple
                    title="Проверки"
                    :readonly="readonly"
                    :clearable="!readonly"
                    return-object
                  ></m-autocomplete>
                  <input-load-file
                    v-if="!readonly"
                    :files="dataSource.Files"
                    :multiple="true"
                    accept="image/*"
                    label="Прилагаемые файлы"
                    prepend-icon=""
                  >
                  </input-load-file>
                  <v-checkbox
                    v-if="projectExternal && IsCurrentOwner"
                    class="mt-5 && pb-5"
                    v-model="dataSource.Shared"
                    hide-details
                    :label="'Сделать доступным для родительской организации'"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-tab-item>
        <v-tab-item
          :key="1"
          v-if="dataSource.Type === 0 || dataSource.Type === 1"
          ><task-list
            class="mt-2"
            :Tasks="dataSource.Tasks"
            :project-id="dataSource.Id"
            :dialog="dialog"
            :readonly="readonly"
            :edit="editTask"
            :create="createTask"
            :hideEdit="true"
            :customInplaceEdit="customInplaceEdit"
            :apiService="TaskService.project"
            typeParent="project"
            @show-audit-operations="dialogAuditOperations = true"
            @show-tasks="dialog = true"
            @remove="removeTaskfromProject"
            @destroy="removeTask"
            @afterDelete="originObject.Tasks = [...dataSource.Tasks]"
            :typeTaskList="dataSource.Type"
            :hideExecutors="dataSource.OnlyRead"
          ></task-list>
        </v-tab-item>
        <v-tab-item>
          <task-ganta class="mt-2" :tasks.sync="dataSource.Tasks"></task-ganta>
        </v-tab-item>
      </v-tabs-items>

      <v-container center class="d-flex justify-center pt-10">
        <v-btn
          v-if="tab === 0"
          large
          color="primary"
          @click="tab = 1"
          width="30%"
        >
          Мероприятия ({{
            dataSource && dataSource.Total ? dataSource.Total : 0
          }})
        </v-btn>
      </v-container>
      <!-- ! Здесь 2х срабатывание диалогового окна на планах-->
      <!-- ! Добавлена проверка для исключения этого эффекта -->
      <task-add
        v-if="currentRouter.name === 'ProjectEdit'"
        v-model="dialog"
        :Tasks="dataSource.Tasks"
        :width="'80vw'"
        :height="'80vh'"
        typeParent="project"
      />
      <audit-operations-popup
        v-model="dialogAuditOperations"
        :width="'80vw'"
        :height="'80vh'"
        @add-tasks="addTasks"
      >
      </audit-operations-popup>
    </base-detail-view>
  </v-layout>
</template>

<script>
import ProjectService from "@/services/ProjectService";
import AuditOperationService from "@/services/AuditOperationService";
import TaskService from "@/services/TaskService";
import { mapActions } from "vuex";
import Permissions from "@/data/permissions";
import BaseDetailView from "@/layouts/BaseDetailView";
import UnsavedMixin from "@/mixins/UnsavedMixin.js";
import CreateAndEditMixin from "@/mixins/CreateAndEditMixin.js";
import BaseDatePicker from "@/components/base/BaseDatePicker.vue";
import mAutocomplete from "@/components/base/inputs/mAutocomplete";
import { defaultProject } from "@/data/defaultObjects";
import TaskList from "@/components/task/TaskList";
import TaskAdd from "@/components/task/TaskAdd";
import AuditOperationsPopup from "@/components/task/AuditOperationsPopup";
import InputLoadFile from "@/components/loadFiles/InputLoadFile.vue";
import DataHelper from "@/utils/DataHelper";
import ReportActivator from "@/components/report/ReportActivator.vue";
import TaskGanta from "../../components/task/TaskGanta.vue";
import moment from "moment";
import { sortBy } from "@/utils/Functions";

export default {
  name: "view-ProjectEdit",

  components: {
    BaseDetailView,
    BaseDatePicker,
    TaskList,
    TaskAdd,
    mAutocomplete,
    InputLoadFile,
    AuditOperationsPopup,
    ReportActivator,
    TaskGanta,
  },

  mixins: [CreateAndEditMixin, UnsavedMixin],

  data() {
    return {
      rules: {
        required: (value) => (!!value && !!value.length) || "Укажите значение.",
      },
      valid: true,
      // Api
      apiService: ProjectService,
      getDefaultObject: this.getDefaultProjectWithType,
      getObjectText: (obj, isNew) => (isNew ? "новая" : obj.Name),

      loading: true,
      dialog: false,
      dialogAuditOperations: false,

      // from dateSources
      employees: [],
      dataSource: this.getDefaultProjectWithType(),
      files: [],
      audits: [],
      tab: 0,
    };
  },

  computed: {
    title() {
      return this.type === "create" && this.dataSource.Type === 0
        ? "Создание проекта"
        : this.type === "create" && this.dataSource.Type === 1
        ? "Создание плана мероприятий"
        : this.dataSource.Type === 0
        ? "Проект"
        : "План мероприятий";
    },
    subtitle() {
      if (this.pageType === "plans") {
        if (this.$store.state.Organization.OwnerType === 2) {
          return this.dataSource.Shared ? this.dataSource.Owner.Name : "";
        }

        if (this.$store.state.Organization.OwnerType === 1) {
          if (this.dataSource.Shared) {
            const orgName = this.dataSource.AuditOperations.find(
              (el) => el.ExternalShared
            ).Owner.OrgName;
            return orgName ? `Доступен для: ${orgName}` : "";
          }
          return "";
        }
      }

      return this.dataSource.Shared
        ? "Доступен для родительской организации"
        : "";
    },
    pageType() {
      if (this.currentRouter.query?.type === "create") {
        return this.currentRouter.params.pageType;
      } else {
        return this.dataSource.Type === 0 ? "projects" : "plans";
      }
    },
    isActualCompletionDate() {
      const isOpen = this.dataSource.Tasks.some((element) => {
        return element.Status !== 5;
      });

      return this.readonly || isOpen;
    },
    TaskService() {
      return TaskService;
    },
    readonly() {
      return (
        (!this.hasPermission(Permissions.ProjectsEdit) &&
          !this.hasPermission(Permissions.AllProjectsEdit)) ||
        this.dataSource.OnlyRead
      );
    },
    projectExternal() {
      return this.dataSource?.AuditOperations.some((e) => e.External);
    },
    IsCurrentOwner() {
      return this.dataSource.OwnerId
        ? this.$store.state.Organization.OwnerId === this.dataSource.OwnerId
        : true;
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async customInplaceEdit(value) {
      if (!value) return;
      setTimeout(() => {
        this.save();
      }, 1);
      return value;
    },
    DataFromServerNormalize(data) {
      data.Tasks = this.taskTransform(data.Tasks);
      return data;
    },
    taskTransform(tasks) {
      for (const task of tasks) {
        if (task.QuestionResult) {
          task.AuditOperationNum = task.QuestionResult.Num;

          // TODO: На текущий момент номер требования входит в состав QuestionResult.Requirement
          // TODO: Если поменяется QuestionResult.Requirement (не будет номера), использовать код ниже

          // if (task.QuestionResult.Question) {
          //   let num = "";
          //   DataHelper.treeListTraverse(
          //     task.QuestionResult.Question,
          //     (item, _, parent) => {
          //       if (parent) {
          //         num = `${num}.${item.IntNum}`;
          //       } else {
          //         num = item.IntNum;
          //       }
          //     }
          //   );
          //   task.QuestionRequirementNum = num;
          // }

          task.QuestionRequirementNum = "";
          if (task.QuestionResult.Requirement) {
            task.QuestionRequirementNum = task.QuestionResult.Requirement.slice(
              0,
              task.QuestionResult.Requirement.indexOf(" ")
            );
          }

          // Создан признак QuestionRequirementFullPath для сортировки
          // списка задач по умолчанию по номеру проверки + имя чек-листа + номер вопроса требования
          task.QuestionRequirementFullPath = [
            task.AuditOperationNum,
            task.QuestionResult.CheckList.Name,
            task.QuestionRequirementNum,
          ].join(" ");
        } else {
          // Сделано для того, чтобы задачи без QuestionResult были в списке ниже задач по проверкам
          task.QuestionRequirementFullPath = "999999999999";
        }
        const start = this.$options.filters.DateShort(task.PlannedStart);
        const end = this.$options.filters.DateShort(task.PlannedEnd);
        if (start && end) task.Period = `${start} - ${end}`;
        else if (end) task.Period = `${end}`;
        else if (start) task.Period = `${start}`;

        if (task.Children.length) {
          task.Children = this.taskTransform(task.Children);
        }
      }

      return tasks.sort((a, b) =>
        a.QuestionRequirementFullPath.localeCompare(
          b.QuestionRequirementFullPath,
          undefined,
          {
            numeric: true,
          }
        )
      );
    },
    ...mapActions(["LOAD_EMPLOYEES"]),
    async init() {
      this.baseInit();
      // if (!this.nested) this.$store.dispatch("frame/DESTROY_COMPONENT");
      try {
        this.employees = await this.LOAD_EMPLOYEES();
        this.audits = (
          await AuditOperationService.get(null, null, [], 3, false, true, null)
        ).data;

        sortBy(
          this.audits,
          (obj) => {
            return moment(obj.Period.DateIn, "DD-MM-YYYY HH:mm:ss").unix();
          },
          true
        );

        if (!this.dataSource.AuditOperations) {
          this.dataSource.AuditOperations = this.audits.filter((a) =>
            this.dataSource.Tasks.some((t) => t.AuditOperation?.Id === a.Id)
          );
        }

        if (this.$route.query.AuditOperationId) {
          const item = this.audits.find(
            (e) => e.Id === parseInt(this.$route.query.AuditOperationId)
          );
          if (item) {
            this.dataSource.AuditOperations.push(item);
            this.changeAudits(this.dataSource.AuditOperations);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async save() {
      if ((await this.baseSave()) === false) return;
      await this.init();
    },

    editTask(task) {
      console.log("editTask ProjectEdit");
      this.$store.dispatch("frame/RUN_COMPONENT", {
        componentName: "task/TaskEdit",
        params: {
          _objectId: task.Id,
          _type: "edit",
          _object: task,
          simple: true,
          typeParent: "project",
        },
        callback: () => {
          const editedTask = this.$store.getters["frame/GET_DATA"]().object;
          const meta = this.$store.getters["frame/GET_DATA"]().meta;
          if (!editedTask.Name) return;

          const index = this.dataSource.Tasks.findIndex(
            (t) => t.Id === editedTask.Id
          );
          if (index >= 0) this.$set(this.dataSource.Tasks, index, editedTask);
          else {
            DataHelper.treeListTraverse(this.dataSource.Tasks, (item) => {
              const index = item.Children.findIndex(
                (t) => t.Id === editedTask.Id
              );
              if (index >= 0) {
                // Удаление подзадачи, если она стала самостоятельной
                if (meta._needDelete)
                  item.Children = item.Children.filter(
                    (e) => e.Id !== editedTask.Id
                  );
                else this.$set(item.Children, index, editedTask);
              }
            });
          }

          this.$store.dispatch("frame/SHOW_DIALOG", { show: false });
          this.save();
        },
      });
    },

    createTask() {
      const _id = -Math.round(Math.random() * 100000);
      this.$store.dispatch("frame/RUN_COMPONENT", {
        componentName: "task/TaskEdit",
        params: {
          _objectId: _id,
          _type: "create",
          simple: true,
          title: "Создание задачи",
          persistent: true,
          typeParent: "project",
        },
        callback: () => {
          const task = this.$store.getters["frame/GET_DATA"]().object;
          if (!task.Name) return;
          task.Id = _id;
          this.dataSource.Tasks.push(task);
          this.$store.dispatch("frame/SHOW_DIALOG", { show: false });
          this.save();
        },
      });
    },

    addTasks(tasks) {
      tasks = this.taskTransform(tasks);
      for (const task of tasks) {
        const foundTask = this.dataSource.Tasks.find((t) => t.Id === task.Id);
        if (!foundTask) {
          this.dataSource.Tasks.push(task);
        }
      }
    },

    getDefaultProjectWithType() {
      const type = this.pageType === "projects" ? 0 : 1;
      return defaultProject(type);
    },

    remove(audit) {
      this.dataSource.Tasks = this.dataSource.Tasks.filter(
        (t) => t.AuditOperation && t.AuditOperation.Id !== audit.Id
      );
    },
    async getTasksByAudit(ids, planId = 0) {
      try {
        return (await TaskService.getTasksByAuditOperations(ids, planId)).data;
      } catch (error) {
        console.log(error);
      }
    },
    async changeAudits(audits) {
      if (!audits.length)
        this.dataSource.Tasks = this.dataSource.Tasks.filter(
          (t) => !t.AuditOperation
        );
      else {
        const auditIds = audits.map((e) => e.Id);
        const tasksByAudit = this.taskTransform(
          await this.getTasksByAudit(auditIds, this.dataSource.Id)
        );
        const otherTasks = this.dataSource.Tasks.filter(
          (t) => !t.AuditOperation
        );
        this.dataSource.Tasks = [...otherTasks, ...tasksByAudit];
      }
    },
    async removeTask(task) {
      if (task)
        if (
          await this.$confirm(
            `Вы действительно хотите безвозвратно удалить задачу?`
          )
        ) {
          await TaskService.delete(task.Id);
          await this.init();
        }
    },
    removeTaskfromProject(task) {
      if (task) {
        if (this.dataSource.Tasks.find((e) => e.Id === task.Id)) {
          this.dataSource.Tasks = this.dataSource.Tasks.filter(
            (t) => t.Id !== task.Id
          );
        } else {
          // find by child
          for (let i = 0; i < this.dataSource.Tasks.length; i++) {
            const index2 = this.dataSource.Tasks[i].Children.findIndex(
              (t) => t.Id === task.Id
            );
            if (index2 >= 0) {
              this.dataSource.Tasks[i].Children = this.dataSource.Tasks[
                i
              ].Children.filter((e) => e.Id !== task.Id);
              return;
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="sass">
.m__combobox__chip__text
  max-width: 100vw
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden

.project-edit__help-panel
  text-align: right
</style>
