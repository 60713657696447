<template>
  <div class="base-input-year">
    <div
      v-if="value > periodStartYear"
      class="base-input-year__item"
      @click="input(value - 1)"
    >
      {{ value - 1 }}
    </div>
    <div class="base-input-year__item active" @click="input(value)">
      {{ value }}
    </div>
    <div
      v-if="value < currentYear && (value < periodEndYear || !periodEndYear)"
      class="base-input-year__item"
      @click="input(value + 1)"
    >
      {{ value + 1 }}
    </div>

    <div class="base-input-year__more" :style="moreLeft ? 'order: 0;' : ''">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <m-icon icon="mdi-dots-horizontal" v-on="on" v-bind="attrs" />
        </template>
        <v-date-picker
          :value="value + '-01-01'"
          ref="picker"
          no-title
          scrollable
          @click:year="saveYear"
          :max="maxYear"
          :min="minYear"
        >
        </v-date-picker>
      </v-menu>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  props: {
    value: {
      type: Number,
      default: moment().year(Number),
    },
    moreLeft: {
      type: Boolean,
      default: false,
    },
    periodStartYear: {
      type: Number,
    },
    periodEndYear: {
      type: Number,
    },
    currentYear: {
      type: Number,
    },
  },
  data() {
    return {
      menu: false,
      year: null,
    };
  },
  computed: {
    maxYear() {
      if (this.periodEndYear) {
        return String(this.periodEndYear);
      }
      if (this.currentYear) {
        return String(this.currentYear);
      }
      return "";
    },
    minYear() {
      if (this.periodEndYear) {
        return String(this.periodStartYear);
      }
      return "2019";
    },
  },
  watch: {
    menu(val) {
      val &&
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.picker.internalActivePicker = "YEAR";
          }, 1);
        });
    },
  },
  methods: {
    saveYear(year) {
      // Reset activePicker to type YEAR
      this.$refs.picker.internalActivePicker = "YEAR";
      this.input(year);
      // Close the menu/datepicker
      this.menu = false;
    },
    input(year) {
      this.$emit("input", year);
    },
  },
};
</script>
<style lang="scss">
.base-input-year {
  position: relative;
  display: flex;
  width: 224px;
  justify-content: space-around;

  & > div {
    font-size: 12px;
    font-weight: 600;
    place-self: center;
    order: 1;
  }

  &__item {
    cursor: pointer;
    &.active {
      text-decoration-line: underline;
      color: var(--v-primary-base);
    }
  }
  &__more {
    cursor: pointer;
  }
}
</style>
