<template>
  <v-layout>
    <base-list-view
      @onRefresh="init"
      @onDelete="del"
      :dataSource.sync="_dataSource"
      :headers="headers"
      :loading="loading"
      :disabledAdd="readonly"
      :showDelete="!readonly"
      :selectedItems.sync="selected"
      @toggle-select-all="selectAllToggle"
      pageEditName="TaskEdit"
      :title="title"
      label-text-btn-create="Добавить задачу"
      label-text-search="Поиск задачи"
      group-by="PlannedPeriodState"
      :item-class="
        (item) => {
          return {
            'text-decoration-line-through': item.Status === 5,
            disabled: item.Status === 5 || item.Attribute.Disabled,
          };
        }
      "
      :showFilters="true"
      :options.sync="options"
      server-options
      show-groups
    >
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          :value="isSelected"
          :ripple="false"
          :disabled="item.Attribute.Disabled"
          @input="select($event)"
        ></v-simple-checkbox>
      </template>
      <v-btn
        slot="action"
        v-if="showUpdate"
        @click="dialog = true"
        :disabled="!isSelected"
        icon
        title="Изменить"
      >
        <m-icon icon="tool-edit" s16></m-icon>
      </v-btn>
      <div row slot="action" class="align-center d-inline-flex">
        <label
          class="tasks__action-show-all v-label blue-grey--text mr-1"
          style="font-size: 14px"
        >
          Показать завершенные
        </label>
        <v-checkbox
          v-model="showCompleted"
          @change="init"
          hide-details
        ></v-checkbox>
      </div>
      <template #group.header="{ group, items }">
        <span class="subtitle-2">
          {{ groupName(group) }} ({{ groupTotal(items, group) }})
        </span>
      </template>
      <!--! Не отображается из-за убранного пропса show-expand в корневом элементе -->
      <!--! Если необходимо, вернуть пропс show-expand -->
      <template v-slot:expanded-item="{ headers, item }">
        <task-table-expanded :headers="headers" :Task="item" />
      </template>
      <template slot="item.PlannedPeriod" slot-scope="{ item }">
        <span :class="{ 'error--text': item.groupKey === 1 }">
          {{
            item.PlannedPeriod.DateIn | PeriodFormat(item.PlannedPeriod.DateOut)
          }}
        </span>
      </template>
    </base-list-view>
    <base-popup
      title="Установить"
      :icon="UtilGetIcon('view-Tasks')"
      :width="'40vw'"
      :height="'20vw'"
      @save="save"
      v-model="dialog"
      buttonSaveText="Применить"
    >
      <v-container>
        <v-row>
          <v-col>
            <base-date-time-picker
              v-model="dateDone"
              clearable
              label="Дата завершения"
            ></base-date-time-picker>
            <m-autocomplete
              v-model="executor"
              :items="employees"
              label="Исполнитель"
              return-object
              clearable
              filter="EmployeeActive"
              dict="Employees"
            ></m-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </base-popup>
  </v-layout>
</template>

<script>
import { sortBy } from "@/utils/Functions";
import TaskService from "@/services/TaskService";
import Permissions from "../../data/permissions";
import ListEditMixin from "../../mixins/ListEditMixin";
import PaginationListMixin from "../../mixins/PaginationListMixin";
import BaseListView from "../../layouts/BaseListView.vue";
import {
  TaskPriority,
  TaskPeriod,
  TaskStatus,
  PlannedPeriodState,
} from "../../data/enums";
import DataHelper from "../../utils/DataHelper";
import TaskTableExpanded from "@/components/task/TaskTableExpanded";
import BasePopup from "../../layouts/BasePopup.vue";
import BaseDateTimePicker from "../../components/base/BaseDateTimePicker.vue";
import mAutocomplete from "../../components/base/inputs/mAutocomplete.vue";
import Vue from "vue";
import { mapActions } from "vuex";

export default {
  name: "view-Tasks",
  components: {
    BaseListView,
    TaskTableExpanded,
    BasePopup,
    BaseDateTimePicker,
    mAutocomplete,
  },
  filters: {
    formatDate: function (value) {
      return DataHelper.toDate(value)?.toLocaleDateString();
    },
  },
  mixins: [ListEditMixin, PaginationListMixin],
  data: function () {
    return {
      loading: true,
      apiService: TaskService,
      headers: [
        {
          text: "Срок выполнения",
          value: "PlannedPeriodState",
          width: "1%",
          dataType: "enum",
          options: PlannedPeriodState,
          disabled: true,
        },

        { text: "Номер", value: "Num", width: "1%", notGroup: true },
        {
          text: "Название",
          value: "Name",
          notGroup: true,
          cellClass: "text-break",
        },
        {
          text: "Исполнители",
          value: "Executors",
          dataType: "array",
          displayText: (e) => e.Name,
          width: "10%",
          dict: { serverName: "Task.Executors" }, // Для фильтра. Загрузка словаря с сервера, с пагинацией
        },
        {
          text: "Срок",
          value: "PlannedPeriod",
          dataType: "Period",
          align: "center",
          width: "15%",
          notGroup: true,
          displayText: (e) => Vue.filter("PeriodFormat")(e.DateIn, e.DateOut),
        },
        {
          text: "Статус",
          value: "Status",
          dataType: "enum",
          options: TaskStatus,
          align: "center",
          width: "10%",
        },
        {
          text: "Проект",
          value: "Project.Name",
          dataType: "object",
          displayText: (p) => p,
          notGroup: true,
          dict: { serverName: "Task.Project.Name" }, // Для фильтра. Загрузка словаря с сервера, с пагинацией
        },
        {
          text: "Завершена",
          value: "Done",
          dataType: "Date",
          width: "1%",
          disabled: true,
          notGroup: true,
          displayText: Vue.filter("DateShort"),
        },
        {
          text: "Приоритет",
          value: "Priority",
          dataType: "enum",
          options: TaskPriority,
          width: "1%",
          align: "center",
        },
        {
          text: "Показатель качества",
          value: "Indicator.Name",
          dataType: "object",
          displayText: (e) => e,
          disabled: true,
          notGroup: true,
          dict: { serverName: "Task.Indicator.Name" }, // Для фильтра. Загрузка словаря с сервера, с пагинацией
        },
        {
          text: "Регулярная задача",
          value: "Periodicity",
          dataType: "enum",
          options: TaskPeriod,
          disabled: true,
          notGroup: true,
        },
        {
          text: "Контроль",
          value: "Notify",
          dataType: "array",
          displayText: (e) => e.Name,
          disabled: true,
          notGroup: true,
          dict: { serverName: "Task.Notify" }, // Для фильтра. Загрузка словаря с сервера, с пагинацией
        },
        {
          text: "Проверка",
          value: "AuditOperation",
          displayText: (e) => e?.Name,
          dict: { serverName: "Task.AuditOperation.Num" }, // Для фильтра. Загрузка словаря с сервера, с пагинацией
        },
        {
          text: "Причина",
          value: "Reason",
          disabled: true,
          notGroup: true,
        },
        {
          text: "Неж. событие",
          value: "UnwishedEvent.Content",
          notGroup: true,
        },
      ],
      myTasks: false,
      showCompleted: false,
      dataSource: [],
      readonly: !this.hasPermission(Permissions.TasksEdit),
      showUpdate:
        this.hasPermission(Permissions.TasksEdit) ||
        this.hasPermission(Permissions.AllTasksEdit),
      selected: [],
      dateDone: null,
      executor: null,
      employees: [],
      dialog: false,
    };
  },
  computed: {
    _apiServiceParams() {
      // Параметры запроса
      const params = {};
      switch (this.pageType) {
        case "my":
          params.executor = true;
          break;
        case "control":
          params.notify = true;
          break;
        case "all":
          break;
        default:
          params.executor = true;
          break;
      }
      if (!this.showCompleted) params.done = false;
      return params;
    },
    // Используется для синхранизации детального и списочного представления
    _dataSource: {
      get() {
        if (!this.dataSource?.length) return [];
        if (this.pageType === "control" && !this.showCompleted) {
          return this.dataSource.filter((e) => e.Done === null);
        } else if (this.pageType === "my" && !this.showCompleted) {
          return this.dataSource.filter((e) => {
            return (
              e.Done === null &&
              e.Executors.find(
                (e) => e.Id === this.$store.state?.user?.Employee.Id
              )
            );
          });
        } else return this.dataSource;
      },
      set(val) {
        this.dataSource = val;
      },
    },
    title() {
      return this.pageType === "my"
        ? "Мои задачи"
        : this.pageType === "all"
        ? "Все задачи"
        : this.pageType === "control"
        ? "Задачи на контроле"
        : "Задачи";
    },
    isSelected() {
      return this.selected.length > 0;
    },
    pageType() {
      return this.currentRouter.params?.type ?? "my";
    },
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.executor = null;
        this.dateDone = null;
      }
    },
  },
  async mounted() {
    this.employees = await this.LOAD_EMPLOYEES();
  },
  methods: {
    ...mapActions(["LOAD_EMPLOYEES"]),
    selectAllToggle() {
      const selectedCount = this.selected.length;
      const selectableCount = this.dataSource.filter(
        (item) => !item.Attribute.Disabled
      ).length;

      if (selectedCount !== selectableCount) {
        this.selected = this.dataSource.filter(
          (item) => !item.Attribute.Disabled
        );
      } else {
        this.selected = [];
      }
    },
    customConfirmDelete(ids) {
      let tmp = false;
      for (let i = 0; i < ids.length; i++) {
        const task = this.dataSource.find((e) => e.Id === ids[i]);
        if (task.ChildrenCount === 0) {
          tmp = true;
          break;
        }
      }
      if (tmp)
        return this.$confirm(
          `Вы действительно хотите удалить ${ids.length} объектов?`
        );
      return true;
    },
    async loadingDataSource() {
      let res;
      try {
        this.loading = true;

        res = this.DataFromServerNormalize(
          await this.groupInit(this._apiServiceParams)
        );

        if (this.virtualPage !== 1) {
          this.dataSource = [...this.dataSource, ...res];
        } else this.dataSource = res;

        this.loading = false;
      } catch (error) {
        throw new Error(error);
      }
      return res;
    },
    init() {
      this.resetPagination();
      this.loadingDataSource();
    },
    DataFromServerNormalize(data) {
      // Группировка по сроку
      const today = DataHelper.today();
      const tomorrow = DataHelper.today().setDate(today.getDate() + 1);
      const weekEnd = DataHelper.today().setDate(
        today.getDate() + 7 - today.getDay()
      ); // до воскресенья
      data.forEach((item) => {
        // Группировка по сроку завершения
        const date = DataHelper.toDate(item.PlannedPeriod.DateIn);
        const dateEnd = DataHelper.toDate(item.PlannedPeriod.DateOut);
        // в случае, если указана одна дата
        // if (dateStart && !date) date = dateStart;

        // сегодня
        if (
          date <= tomorrow &&
          (date?.getTime() === today?.getTime() || dateEnd >= today)
        )
          item.groupKey = 2;
        // завершенные
        else if (item.Done) item.groupKey = 6;
        // свыше недели
        else if (date > weekEnd) item.groupKey = 4;
        // без срока
        else if (!date) item.groupKey = 5;
        // просроченные
        else if (date < today) item.groupKey = 1;
        // до конца недели
        else if (date <= weekEnd) item.groupKey = 3;

        item.Periodicity = item?.Periodicity?.Period ?? 0;

        // Format Num
        item.AuditNum = item?.AuditOperation?.Num;

        item.Executors = sortBy(item.Executors, (e) => {
          return this.$options.filters.PersonShortName(e);
        });
      });
      data = sortBy(data, (e) => {
        return e.Executors.map((e2) =>
          this.$options.filters.PersonShortName(e2)
        ).join(", ");
      });
      const unfinishedTasks = data.filter((t) => !t.Done);
      const completedTasks = data.filter((t) => t.Done);

      // незавершенные задачи сортируется по возрастанию, а завершенные - по убыванию
      unfinishedTasks.sort((task1, task2) => {
        let t1 = DataHelper.toDateTime(task1.PlannedEnd)?.getTime();
        let t2 = DataHelper.toDateTime(task2.PlannedEnd)?.getTime();
        if (!t1) t1 = 0;
        if (!t2) t2 = 0;
        return t1 - t2;
      });
      completedTasks.sort((task1, task2) => {
        let t1 = DataHelper.toDateTime(task1.PlannedEnd)?.getTime();
        let t2 = DataHelper.toDateTime(task2.PlannedEnd)?.getTime();
        if (!t1) t1 = 0;
        if (!t2) t2 = 0;
        return t2 - t1;
      });
      return [...unfinishedTasks, ...completedTasks];
    },
    // Возвращает название группы задач
    groupName: function (groupKey) {
      switch (groupKey) {
        case 1:
          return "Просроченные";
        case 2:
          return "Сегодня";
        case 3:
          return "На неделе";
        case 4:
          return "Свыше недели";
        case 5:
          return "Без срока";
        case 6:
          return "Завершенные";
      }
      return "";
    },
    // Возвращает общее количество в группе задач
    groupTotal: function (items, groupKey) {
      return items.filter((item) => item.groupKey === groupKey).length;
    },
    async save() {
      await this.apiService.updateMultiple(
        this.executor?.Id,
        this.dateDone,
        this.selected.map((s) => s.Id)
      );
      this.dialog = false;
    },
  },
};
</script>
<style lang="sass" scoped>
.tasks__action-show-all
  font-size: 0.875rem
  font-weight: 500
  letter-spacing: 0.0892857143em
</style>
