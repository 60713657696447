<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="420"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <a v-bind="attrs" v-on="on" class="text-decoration-underline">
        <slot>Документы ({{ documents.length }})</slot>
      </a>
    </template>
    <v-card class="mx-auto">
      <v-toolbar>
        <m-icon icon="medexpert-docs" active class="mr-4"></m-icon>

        <v-toolbar-title>Документы</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="menu = false">
          <m-icon icon="mdi-close"></m-icon>
        </v-btn>
      </v-toolbar>

      <v-list two-line>
        <v-list-item
          v-for="item in documents"
          :key="item.Id"
          @click="hadlerClick(item)"
        >
          <v-list-item-avatar>
            <m-icon
              :icon="
                item.Status === -1 ? 'medexpert-other-docs' : 'medexpert-docs'
              "
              color="primary"
            ></m-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ item.Type ? item.Type.Name : "" }}
              {{ item.Num ? "№" + item.Num : "" }}
              ({{ item.Status | getEnums("OrganizationDocumentStatus") }})
            </v-list-item-title>
            <v-list-item-subtitle style="color: var(--v-text-base) !important">
              {{ item.Name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  props: {
    documents: { type: Array, default: () => [] },
  },
  data() {
    return {
      menu: false,
    };
  },

  methods: {
    hadlerClick(e) {
      if (e.Status !== -1)
        this.$store.dispatch("frame/RUN_COMPONENT", {
          componentName: "document/LocalDocumentEdit",
          params: {
            _objectId: e.Id,
            _independent: true,
          },
        });
      else
        this.$store.dispatch("frame/RUN_COMPONENT", {
          componentName: "document/DocumentTemplateEdit",
          params: {
            _objectId: e.Id,
            _independent: true,
          },
        });
    },
  },
};
</script>
