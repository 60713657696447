<template>
  <base-detail-view
    :loading="loading"
    title="Медицинское изделие"
    :subTitle="this.type === 'create' ? 'Новое изделие' : dataSource.Name"
    :isUnsaved="isUnsaved && valid"
    @onRefresh="init"
    @onSave="save"
  >
    <v-form v-model="valid" class="block__content">
      <v-container>
        <v-row>
          <v-col cols="2">
            <div class="d-flex justify-center align-center">
              <v-list-item-avatar
                style="
                  width: 215px;
                  height: 235px;
                  margin: 0;
                  border-radius: 10px;
                  background-color: var(--v-blue-grey-lighten4);
                  border: 1px solid var(--v-blue-grey-base);
                "
              >
                <v-img v-if="dataSource.Picture" :src="pictureSrc"></v-img>
                <span
                  v-else
                  style="
                    font-size: 14px;
                    color: var(--v-primary-base);
                    user-select: none;
                    cursor: pointer;
                  "
                  @click="triggerInputPicture"
                  :readonly="readonly"
                >
                  Добавить фото
                </span>
              </v-list-item-avatar>
            </div>
            <div class="d-flex justify-center" :readonly="readonly">
              <v-btn
                title="Изменить"
                color="primary"
                icon
                :disabled="!dataSource.Picture"
                @click="triggerInputPicture"
              >
                <m-icon icon="tool-edit" s16 />
              </v-btn>
              <!-- Скрытый компонент для загрузки изображения -->
              <input-load-file
                ref="inputPicture"
                :files="picture"
                accept="image/*"
                prepend-icon=""
                style="display: none"
              ></input-load-file>
              <v-btn
                title="Скачать"
                icon
                download
                :disabled="!dataSource.Picture"
                :href="pictureSrc"
              >
                <m-icon icon="tool-download" s16 />
              </v-btn>
              <v-btn
                title="Удалить"
                color="error"
                icon
                :disabled="!dataSource.Picture"
                @click="delPicture"
              >
                <m-icon icon="tool-delete" s16></m-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col cols="10">
            <v-row>
              <v-col cols="6" class="pr-7">
                <v-text-field
                  v-model="dataSource.Name"
                  label="Наименование медицинского изделия"
                  maxlength="200"
                  counter
                  :rules="[rules.required]"
                  required
                  :readonly="readonly"
                  :clearable="!readonly"
                  v-up-first-symbol
                ></v-text-field>
                <template>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <m-autocomplete
                        v-model="dataSource.Kind"
                        label="Тип медицинского изделия"
                        item-text="Name"
                        :dict="{ serverName: 'EquipmentKind.Text' }"
                        :itemsCount="20"
                        :filters="{
                          Model: {
                            value: [
                              dataSource.Model ? dataSource.Model.Id : null,
                            ],
                            type: 'int',
                          },
                          License: {
                            value: [
                              dataSource.License ? dataSource.License.Id : null,
                            ],
                            type: 'int',
                          },
                        }"
                        :readonly="readonly"
                        :clearable="!readonly"
                        return-object
                        v-bind="attrs"
                        v-on="dataSource.Kind ? on : null"
                      />
                    </template>
                    <span>
                      {{ (dataSource.Kind && dataSource.Kind.Name) || "" }}
                    </span>
                  </v-tooltip>
                </template>

                <v-row>
                  <v-col cols="8">
                    <template v-if="!dataSource.AnotherModel">
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <m-autocomplete
                            v-model="dataSource.Model"
                            label="Модель"
                            item-text="Name"
                            :dict="{ serverName: 'EquipmentModel.Name' }"
                            :itemsCount="20"
                            :filters="{
                              Kind: {
                                value: [
                                  dataSource.Kind ? dataSource.Kind.Id : null,
                                ],
                                type: 'int',
                              },
                              License: {
                                value: [
                                  dataSource.License
                                    ? dataSource.License.Id
                                    : null,
                                ],
                                type: 'int',
                              },
                            }"
                            :readonly="readonly"
                            :clearable="!readonly"
                            return-object
                            v-bind="attrs"
                            v-on="dataSource.Model ? on : null"
                          />
                        </template>
                        <span>
                          {{
                            (dataSource.Model && dataSource.Model.Name) || ""
                          }}
                        </span>
                      </v-tooltip>
                    </template>
                    <v-text-field
                      v-if="dataSource.AnotherModel"
                      v-model="dataSource.ModelName"
                      label="Модель"
                      maxlength="100"
                      counter
                      :readonly="readonly"
                      :clearable="!readonly"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-checkbox
                      v-model="dataSource.AnotherModel"
                      label="Другая модель"
                      :readonly="readonly"
                    ></v-checkbox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4">
                    <v-checkbox
                      v-model="dataSource.IsServiceRequired"
                      label="Подлежит ТО"
                      :readonly="readonly"
                      class="mt-n2"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="5">
                    <v-checkbox
                      v-model="dataSource.IsValidationRequired"
                      label="Подлежит поверке"
                      :readonly="readonly"
                      class="mt-n2"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="3"> </v-col>
                </v-row>
              </v-col>

              <v-col cols="6" class="pl-7">
                <v-row>
                  <v-col cols="6" class="pr-7">
                    <v-text-field
                      v-model="dataSource.SerialNum"
                      label="Серийный номер"
                      maxlength="50"
                      counter
                      :readonly="readonly"
                      :clearable="!readonly"
                    ></v-text-field>
                    <template>
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            :value="
                              dataSource.Model && dataSource.Model.Producer
                                ? dataSource.Model.Producer.Name
                                : ''
                            "
                            label="Производитель"
                            readonly
                            v-bind="attrs"
                            v-on="
                              dataSource.Model && dataSource.Model.Producer
                                ? on
                                : null
                            "
                          />
                        </template>
                        <span>
                          {{
                            (dataSource.Model &&
                              dataSource.Model.Producer &&
                              dataSource.Model.Producer.Name) ||
                            ""
                          }}
                        </span>
                      </v-tooltip>
                    </template>
                    <base-date-picker
                      v-model="dataSource.IssueDate"
                      label="Дата выпуска"
                      :readonly="readonly"
                      :clearable="!readonly"
                    ></base-date-picker>
                  </v-col>

                  <v-col cols="6" class="pl-7">
                    <v-text-field
                      v-model="dataSource.Num"
                      label="Инвентарный номер"
                      maxlength="50"
                      counter
                      :readonly="readonly"
                      :clearable="!readonly"
                    ></v-text-field>
                    <v-text-field
                      :value="
                        dataSource.Model && dataSource.Model.Producer
                          ? dataSource.Model.Producer.CountryName
                          : ''
                      "
                      label="Страна производитель"
                      readonly
                    ></v-text-field>
                    <v-text-field
                      v-model="dataSource.ServiceLife"
                      label="Срок службы/годности, лет"
                      @keypress="restrictInput"
                      maxlength="3"
                      :readonly="readonly"
                      type="number"
                      min="0"
                      max="100"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-n6">
                  <v-col>
                    <v-text-field
                      v-model="dataSource.Comment"
                      label="Примечание"
                      maxlength="100"
                      counter
                      :readonly="readonly"
                      :clearable="!readonly"
                      v-up-first-symbol
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Нижний ряд -->
              <v-col>
                <v-row>
                  <v-col cols="2" class="pr-0">
                    <div class="pt-5 pb-4">Место размещения</div>
                  </v-col>
                  <v-col cols="4" class="pl-0 pr-10">
                    <m-autocomplete
                      v-model="dataSource.Building"
                      label="Здание"
                      item-text="Name"
                      :items="building"
                      :readonly="readonly"
                      :clearable="!readonly"
                      return-object
                    ></m-autocomplete>
                  </v-col>
                  <v-col cols="4" class="pl-0 pr-10">
                    <m-tree-select
                      v-model="dataSource.Partitions"
                      :items="partitions"
                      @click.prevent.stop
                      allowSelectParents
                      :multiple="true"
                      label="Структурное подразделение"
                      :isAddItem="false"
                      :clearable="!readonly"
                      :readonly="readonly"
                    ></m-tree-select>
                  </v-col>
                  <v-col cols="2" class="pl-2">
                    <m-autocomplete
                      v-model="dataSource.ResponsibleEmployee"
                      :label="'Ответственный'"
                      :items="employees"
                      :readonly="readonly"
                      :clearable="!readonly"
                      filter="EmployeeActive"
                      :dict="{ name: 'Employees', notItems: true }"
                      :itemsCount="20"
                      :textCustomAdd="'Создать нового сотрудника'"
                      return-object
                    ></m-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="mt-0">
                  <v-col cols="2" class="pr-0">
                    <div class="pt-5 pb-4">Рег. удостоверение</div>
                  </v-col>
                  <v-col cols="4" class="pl-0 pr-10">
                    <template>
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <m-autocomplete
                            v-model="dataSource.License"
                            label="Регистрационный номер"
                            item-text="Name"
                            :dict="{ serverName: 'RegistrationLicense.Text' }"
                            :itemsCount="20"
                            :filters="{
                              Kind: {
                                value: [
                                  dataSource.Kind ? dataSource.Kind.Id : null,
                                ],
                                type: 'int',
                              },
                              Model: {
                                value: [
                                  dataSource.Model ? dataSource.Model.Id : null,
                                ],
                                type: 'int',
                              },
                            }"
                            :readonly="readonly"
                            :clearable="!readonly"
                            return-object
                            v-bind="attrs"
                            v-on="dataSource.License ? on : null"
                          />
                        </template>
                        <span>
                          {{
                            (dataSource.License && dataSource.License.Name) ||
                            ""
                          }}
                        </span>
                      </v-tooltip>
                    </template>
                  </v-col>
                  <v-col cols="4" class="pl-0 pr-10">
                    <v-text-field
                      :value="dateInRegistration"
                      label="Дата регистрации"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="pl-2">
                    <v-text-field
                      :value="dateOutRegistration"
                      label="Срок действия"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-form v-model="valid">
      <base-panel class="mt-2" headerHidden>
        <m-tabs v-model="tab" v-if="!loading" ref="mtabs">
          <v-tab key="Documents">Документы</v-tab>
          <v-tab key="Validation" v-show="dataSource.IsValidationRequired">
            Поверка
          </v-tab>
          <v-tab key="Service" v-show="dataSource.IsServiceRequired">
            Техническое обслуживание
          </v-tab>
          <v-tab key="Exploitation" v-show="dataSource">
            Ввод и вывод из эксплуатации
          </v-tab>
          <v-tab key="Standarts">Записи стандартов оснащения</v-tab>
        </m-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="Documents" class="mt-2">
            <applied-documents
              :applied-headers="documentHeaders"
              :documents="dataSource.Documents"
              :objectType="3"
              @edit="editDocuments"
              :readonly="readonly"
            ></applied-documents>
          </v-tab-item>
          <v-tab-item key="Validation">
            <base-panel
              class="mt-2 block__content"
              headerHidden
              v-if="dataSource.IsValidationRequired"
            >
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="dataSource.ValidationPeriod"
                      label="Период поверки, мес."
                      type="number"
                      @keypress="restrictInput"
                      :rules="[rules.required, rules.maxPeriodSymbol]"
                      required
                      :readonly="readonly"
                      maxlength="3"
                      min="0"
                      max="100"
                    ></v-text-field>
                    <m-select
                      v-model="dataSource.MeasuringType"
                      :items="measuringTypes"
                      item-text="Name"
                      label="Тип измерительного средства"
                      return-object
                      :readonly="readonly"
                      :clearable="!readonly"
                    ></m-select>
                    <v-text-field
                      v-model="dataSource.MeasureAccuracy"
                      label="Класс точности"
                      maxlength="50"
                      :readonly="readonly"
                    ></v-text-field>
                    <equipment-document-edit
                      v-model="dataSource.ValidationBid"
                      :documentType="4"
                      label="Договор на поверку"
                      :readonly="readonly"
                    ></equipment-document-edit>
                  </v-col>
                  <v-col>
                    <base-date-picker
                      v-model="dataSource.ValidationDate"
                      label="Дата последней поверки"
                      :readonly="readonly"
                      :clearable="!readonly"
                    ></base-date-picker>
                    <v-text-field
                      v-model="dataSource.MeasureRange"
                      label="Предел измерений (диапазон)"
                      maxlength="50"
                      :readonly="readonly"
                    ></v-text-field>
                    <base-date-picker
                      v-model="dataSource.IssueDate"
                      label="Дата выпуска"
                      :readonly="readonly"
                      :clearable="!readonly"
                    ></base-date-picker>
                  </v-col>
                </v-row>
              </v-container>
            </base-panel>
          </v-tab-item>
          <v-tab-item key="Service">
            <base-panel
              class="mt-2 block__content"
              headerHidden
              v-if="dataSource.IsServiceRequired"
            >
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="dataSource.ServicePeriod"
                      label="Период обслуживания, мес."
                      type="number"
                      @keypress="restrictInput"
                      :rules="[rules.required, rules.maxPeriodSymbol]"
                      required
                      :readonly="readonly"
                      maxlength="3"
                      min="0"
                      max="100"
                    ></v-text-field>
                    <equipment-document-edit
                      v-model="dataSource.ServiceContract"
                      :documentType="3"
                      label="Договор на тех. обслуживание"
                      :readonly="readonly"
                    ></equipment-document-edit>
                  </v-col>
                  <v-col>
                    <base-date-picker
                      v-model="dataSource.ServiceDate"
                      label="Дата последнего обслуживания"
                      :readonly="readonly"
                      :clearable="!readonly"
                    ></base-date-picker>
                  </v-col>
                </v-row>
              </v-container>
            </base-panel>
          </v-tab-item>
          <v-tab-item key="Exploitation">
            <base-panel class="mt-2 block__content" headerHidden>
              <v-container>
                <v-row>
                  <v-col>
                    <base-date-picker
                      v-model="dataSource.CommissionDate"
                      label="Дата ввода в эксплуатацию"
                      :readonly="readonly"
                      :clearable="!readonly"
                    ></base-date-picker>
                    <m-select
                      :value="commissionFile"
                      :items="commissionFile"
                      item-text="Name"
                      multiple
                      label="Акт ввода в эксплуатацию"
                      deletable-chips
                      return-object
                      :clearable="true"
                      @click.stop="triggerInputFile('commissionFile')"
                      @change="handleArrayClear('commissionFile')"
                    >
                      <template
                        v-if="dataSource.CommissionFile"
                        v-slot:selection
                      >
                        <v-chip
                          class="primary--text"
                          color="blue-grey lighten-2"
                          :close="false"
                          @click.stop="openPdf('commissionFile')"
                        >
                          <span>{{ dataSource.CommissionFile.Name }}</span>
                        </v-chip>
                      </template>
                    </m-select>
                    <!-- Скрытый компонент для загрузки Акта ввода -->
                    <input-load-file
                      ref="inputCommissionFile"
                      :files="commissionFile"
                      accept="application/pdf"
                      label="Акт"
                      prepend-icon=""
                      class="mt-1 mb-4"
                      style="display: none"
                    ></input-load-file>
                  </v-col>
                  <v-col>
                    <base-date-picker
                      v-model="dataSource.DecommissionDate"
                      label="Дата вывода из эксплуатации"
                      :readonly="readonly"
                      :clearable="!readonly"
                    ></base-date-picker>
                    <v-text-field
                      v-model="dataSource.DecommissionReason"
                      label="Причина"
                      maxlength="500"
                      counter
                      :readonly="readonly"
                    ></v-text-field>
                    <m-select
                      :value="decommissionFile"
                      :items="decommissionFile"
                      item-text="Name"
                      multiple
                      label="Акт вывода из эксплуатации"
                      deletable-chips
                      return-object
                      :clearable="true"
                      @click.stop="triggerInputFile('decommissionFile')"
                      @change="handleArrayClear('decommissionFile')"
                    >
                      <template
                        v-if="dataSource.DecommissionFile"
                        v-slot:selection
                      >
                        <v-chip
                          class="primary--text"
                          color="blue-grey lighten-2"
                          :close="false"
                          @click.stop="openPdf('decommissionFile')"
                        >
                          <span>{{ dataSource.DecommissionFile.Name }}</span>
                        </v-chip>
                      </template>
                    </m-select>
                    <!-- Скрытый компонент для загрузки Акта вывода -->
                    <input-load-file
                      ref="inputDecommissionFile"
                      :files="decommissionFile"
                      accept="application/pdf"
                      label="Акт"
                      prepend-icon=""
                      class="mt-1 mb-4"
                      style="display: none"
                    ></input-load-file>
                  </v-col>
                </v-row>
              </v-container>
            </base-panel>
          </v-tab-item>
          <v-tab-item key="Standarts" class="mt-2">
            <base-list-view
              :dataSource="dataSource.StandartRecords"
              :headers="standartHeaders"
              isNested
              headerHidden
              hide-default-footer
              notShowSelect
              hide-edit
            />
          </v-tab-item>
        </v-tabs-items>
      </base-panel>
    </v-form>
    <base-popup
      title=""
      :onlyClose="true"
      v-model="dialog"
      :width="'95vw'"
      :height="'95vh'"
    >
      <pdf-viewer :url="pdfView" />
    </base-popup>
  </base-detail-view>
</template>

<script>
import { mapActions } from "vuex";
import EquipmentService from "@/services/EquipmentService";
import PartitionService from "@/services/PartitionService";
import BuildingService from "@/services/BuildingService";
import { defaultEquipment } from "../../data/defaultObjects";
import Permissions from "../../data/permissions";
// import DataHelper from "../../utils/DataHelper";
import CreateAndEditMixin from "../../mixins/CreateAndEditMixin";
import UnsavedMixin from "../../mixins/UnsavedMixin.js";
import BaseDetailView from "../../layouts/BaseDetailView.vue";
import BaseDatePicker from "../../components/base/BaseDatePicker.vue";
import BasePanel from "../../layouts/BasePanel.vue";
import MTreeSelect from "../../components/base/inputs/mTreeSelect.vue";
import MSelect from "../../components/base/inputs/mSelect.vue";
import mAutocomplete from "@/components/base/inputs/mAutocomplete.vue";
import BaseListView from "../../layouts/BaseListView.vue";
import AppliedDocuments from "../../components/organization/AppliedDocuments.vue";
import EquipmentDocumentEdit from "../../components/organization/EquipmentDocumentEdit.vue";
import mTabs from "@/components/base/tabs/mTabs";
import BasePopup from "../../layouts/BasePopup.vue";
import PdfViewer from "@/components/rich/PdfViewer.vue";
import InputLoadFile from "@/components/loadFiles/InputLoadFile.vue";

export default {
  name: "view-EquipmentEdit",
  components: {
    BaseDetailView,
    BaseDatePicker,
    BasePanel,
    MTreeSelect,
    MSelect,
    mAutocomplete,
    BaseListView,
    AppliedDocuments,
    EquipmentDocumentEdit,
    mTabs,
    BasePopup,
    PdfViewer,
    InputLoadFile,
  },
  mixins: [CreateAndEditMixin, UnsavedMixin],
  data: function () {
    return {
      loading: true,
      dialog: false,
      pdfView: null,
      valid: false,
      apiService: EquipmentService,
      getDefaultObject: defaultEquipment,
      getObjectText: (obj, isNew) => (isNew ? "новое" : obj.Name),
      dataSource: {
        ...defaultEquipment(),
      },
      building: [],
      partitions: [],
      measuringTypes: [],
      employees: [],
      picture: [],
      commissionFile: [],
      decommissionFile: [],
      tab: null,
      documentHeaders: [
        { text: "Тип", value: "Type.Name" },
        { text: "Дата", value: "Date" },
        { text: "Дата завершения", value: "DateOut" },
        { text: "Номер", value: "Num" },
        { text: "Контрагент", value: "Contragent.Name" },
        { text: "Примечание", value: "Comment" },
      ],
      standartHeaders: [
        { text: "Стандарт", value: "StandartName" },
        { text: "Количество", value: "Quantity" },
      ],
      rules: {
        required: (value) => !!value || "Укажите значение.",
        maxPeriodSymbol: (value) =>
          String(value).length <= 4 || "Недопустимое значение",
      },
      readonly: !(
        this.hasPermission(Permissions.EquipmentsEditAll) ||
        this.hasPermission(Permissions.EquipmentsEdit)
      ),
    };
  },
  computed: {
    apiUrl() {
      // Полный путь к url должен быть именно в компоненте, а не в шаблоне.
      return process.env.VUE_APP_SERVER_URL;
    },
    pictureSrc() {
      const url = `${this.apiUrl}common/GetFile?externalId=${this.dataSource.Picture?.ExternalId}`;
      return url;
    },
    dateInRegistration() {
      const dateIn = this.dataSource.License?.Period?.DateIn;
      return typeof dateIn === "string" ? dateIn.slice(0, 10) : "";
    },
    dateOutRegistration() {
      const dateOut = this.dataSource.License?.Period?.DateOut;
      return this.dataSource.License
        ? typeof dateOut === "string"
          ? dateOut.slice(0, 10)
          : "бессрочно"
        : "";
    },
  },
  watch: {
    "dataSource.Building": async function (val, old) {
      if (val && old && val.Id === old.Id) return;
      if (val && val.Id) {
        try {
          const response = await PartitionService.get({ buildingId: val.Id });
          this.partitions = response.data;
        } catch (error) {
          console.error("Ошибка при загрузке данных:", error);
        }
      } else {
        try {
          const response = await PartitionService.get();
          this.partitions = response.data;
        } catch (error) {
          console.error("Ошибка при загрузке данных:", error);
        }
      }

      // for (let i = 0; i < this.dataSource.Partitions.length; i++) {
      //   this.dataSource.Partitions[i] = DataHelper.findTreeById(
      //     this.partitions,
      //     this.dataSource.Partitions[i].Id
      //   );
      // }
    },
    "dataSource.Partitions": async function (val, old) {
      if (val && old && val.Id === old.Id) return;
      if (val && val.Id) {
        try {
          const response = await BuildingService.get({ partitionId: val.Id });
          this.building = response.data;
        } catch (error) {
          console.error("Ошибка при загрузке данных:", error);
        }
      } else {
        try {
          const response = await BuildingService.get();
          this.building = response.data;
        } catch (error) {
          console.error("Ошибка при загрузке данных:", error);
        }
      }
    },
    picture: {
      handler(value) {
        if (value.length > 0 && value[0].Base64Content) {
          this.dataSource.Picture = value[0];
          this.save();
        }
      },
      deep: true,
    },
    commissionFile: {
      handler(value) {
        if (value.length > 0 && value[0].Base64Content) {
          this.dataSource.CommissionFile = value.length ? value[0] : null;
          this.save();
        } else if (value.length === 0) {
          this.dataSource.CommissionFile = null;
        }
      },
      deep: true,
    },
    decommissionFile: {
      handler(value) {
        if (value.length > 0 && value[0].Base64Content) {
          this.dataSource.DecommissionFile = value.length ? value[0] : null;
          this.save();
        } else if (value.length === 0) {
          this.dataSource.DecommissionFile = null;
        }
      },
      deep: true,
    },
    dialog(value) {
      if (value === false) {
        this.pdfView = null;
      }
    },
  },
  async mounted() {
    this.building = (await BuildingService.get()).data;
    this.partitions = (await PartitionService.get()).data;
    this.measuringTypes = await this.LOAD_MEASURING_TYPES();
    this.employees = await this.LOAD_EMPLOYEES();
    await this.init();
  },
  methods: {
    ...mapActions(["LOAD_MEASURING_TYPES", "LOAD_EMPLOYEES"]),
    DataFromServerNormalize(data) {
      // Подразделение по умолчанию для нового изделия
      // const partition = Number(this.$router.currentRoute.query.partition);
      // if (
      //   this.type === "create" &&
      //   partition &&
      //   !data.Partitions.some((p) => p.Id === partition)
      // ) {
      //   const partitionDef = DataHelper.findTreeById(
      //     this.partitions,
      //     partition
      //   );
      //   if (partitionDef) data.Partitions.push(partitionDef);
      // }

      // Дополнительные поля записей стандартов оснащения
      for (const record of data.StandartRecords)
        record.StandartName = record.Standart?.Name;

      // Описание специальных документов
      this.setDocumentText(data.ValidationBid);
      this.setDocumentText(data.ServiceContract);

      // Преобразование чисел для строковых редакторов (чтобы не фиксировались изменения при вводе)
      data.ValidationPeriod = data.ValidationPeriod?.toString();
      data.ServicePeriod = data.ServicePeriod?.toString();

      // // Необходимо для работы v-tree-select
      // for (let i = 0; i < data.Partitions.length; i++) {
      //   data.Partitions[i] = DataHelper.findTreeById(
      //     this.partitions,
      //     data.Partitions[i].Id
      //   );
      // }

      this.commissionFile = [];
      this.decommissionFile = [];
      if (data.CommissionFile) {
        this.commissionFile.push({
          Base64Content: data.CommissionFile.Base64Content,
          ExternalId: data.CommissionFile.ExternalId,
          Id: data.CommissionFile.Id,
          Name: data.CommissionFile.Name,
        });
      }
      if (data.DecommissionFile) {
        this.decommissionFile.push({
          Base64Content: data.DecommissionFile.Base64Content,
          ExternalId: data.DecommissionFile.ExternalId,
          Id: data.DecommissionFile.Id,
          Name: data.DecommissionFile.Name,
        });
      }

      return data;
    },
    CopyDataNormalize(data) {
      // Сбрасываем идентификаторы прикрепленных документов и их файлов.
      // Специальные документы (на поверку и ТО) остаются как есть, так как они самостоятельные.
      // Прикрепленные файлы документов копируются автоаматически.
      for (const doc of data.Documents) {
        doc.Id = -Math.round(Math.random() * 100000);
        doc.Equipment = null;
        doc.Equipments = [];
      }
      // Записи стандартов оснащения удаляем, так как они указываются в стандартах подразделения.
      data.StandartRecords = [];
      return data;
    },
    editDocuments(value) {
      if (!value) return;
      const index = this.dataSource.Documents.findIndex(
        (item) => item.Id === value.Id
      );
      if (index !== -1) this.$set(this.dataSource.Documents, index, value);
      else this.dataSource.Documents.push(value);
    },
    setDocumentText(doc) {
      if (doc) doc.Text = `${doc.Num} от ${doc.Date.substr(0, 10)}`;
    },
    updateTabs() {
      this.$refs.mtabs.$refs.tabs.callSlider();
    },
    restrictInput(event) {
      const charCode = event.charCode;
      // Разрешаем только цифры (0-9)
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },
    // todo: исправить
    DataBeforeSave(data) {
      const d = this._.cloneDeep(data);

      // todo: Решает проблему с m-tree-select Children, но создает проблему с Мутацией
      if (!this.dataSource.AnotherModel) {
        d.ModelName = null;
      } else if (this.dataSource.AnotherModel) {
        d.Model = null;

        d.ModelName = d.ModelName ? d.ModelName : "";
      }

      return d;
    },
    triggerInputPicture() {
      this.$refs.inputPicture.$el.querySelector('input[type="file"]').click(); // Открыть окно загрузки
    },

    async delPicture() {
      if (await this.$confirm(`Изображение будет удалено. Вы уверены?`)) {
        this.dataSource.Picture = null;
        if (this.originObject.Picture) {
          await this.save();
        }
      }
    },
    async openPdf(path) {
      const externalId =
        path === "commissionFile"
          ? this.dataSource.CommissionFile?.ExternalId
          : path === "decommissionFile"
          ? this.dataSource.DecommissionFile?.ExternalId
          : null;
      if (externalId) {
        this.pdfView = `${this.apiUrl}common/GetFileStream?externalId=${externalId}`;
        this.dialog = true;
      }
    },
    triggerInputFile(path) {
      if (path === "commissionFile")
        this.$refs.inputCommissionFile.$el
          .querySelector('input[type="file"]')
          .click();
      if (path === "decommissionFile")
        this.$refs.inputDecommissionFile.$el
          .querySelector('input[type="file"]')
          .click();
    },
    handleArrayClear(arrayName) {
      this[arrayName] = [];
    },
  },
};
</script>
