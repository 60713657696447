var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tfoms-panel',_vm._b({staticClass:"analysis-tfoms__progress",staticStyle:{"padding":"20px 20px"},attrs:{"icon-color":_vm.factColor},scopedSlots:_vm._u([{key:"subTitle",fn:function({ subTitle }){return [_vm._v(" "+_vm._s(subTitle)+" "),_c('span',{staticClass:"primary--text ml-3"},[_vm._v(_vm._s(_vm.itemName))])]}}])},'tfoms-panel',_vm.$attrs,false),[_c('base-tree-table',{attrs:{"headers":_vm.onlyNoPayment ? _vm.headers2 : _vm.headers,"items":_vm.data,"hide-default-footer":"","notShowSelect":"","not-filter":"","nested":"","item-class":(item) => {
        return (
          (_vm.showItems.length === 0 || _vm.showItems.includes(item.Id)
            ? ''
            : 'analysis-tfoms__progress--disabled') +
          (_vm.value && _vm.value.Id === item.Id
            ? ' analysis-tfoms__progress--active'
            : '') +
          (_vm.value && _vm.value.Id === item.Id ? ' table-item--active' : '')
        );
      },"items-per-page":_vm.perPage,"title":"","tree-table-contents":"","sort-by":"Value3","sort-desc":true,"exclude-settings-actions":['table', 'group']},on:{"item-expanded":_vm.expended,"click:row":_vm.onClick},scopedSlots:_vm._u([{key:"item.Name",fn:function({ item }){return [_c('div',{staticClass:"td2",attrs:{"title":item.Name}},[_vm._v(" "+_vm._s(item.Name)+" ")])]}},{key:"item.Value1",fn:function(scope){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-progress-linear',_vm._g({attrs:{"value":(scope.item.Value1 / scope.item.MaxValues[0]) * 100,"buffer-value":100,"height":"24","color":_vm.backgroundColor}},on),[_vm._v(" "+_vm._s(_vm._f("numberSplit")(scope.item.Value1))+" ")])]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(scope.item.Labels[0])+": "+_vm._s(_vm._f("numberSplit")(scope.item.Value1))+" "+_vm._s(scope.item.Unit)+" ")])])]}},{key:"item.Value2",fn:function(scope){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-progress-linear',_vm._g({attrs:{"value":(scope.item.Value2 / scope.item.MaxValues[1]) * 100,"buffer-value":100,"height":"24","color":_vm.factColor}},on),[_vm._v(" "+_vm._s(_vm._f("numberSplit")(scope.item.Value2))+" ")])]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(scope.item.Labels[1])+": "+_vm._s(_vm._f("numberSplit")(scope.item.Value2))+" "+_vm._s(scope.item.Unit)+" ")])])]}},{key:"item.Value3",fn:function(scope){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-progress-linear',_vm._g({attrs:{"value":(scope.item.Value3 / scope.item.MaxValues[2]) * 100,"buffer-value":100,"height":"24","color":_vm.colors[2]}},on),[_vm._v(" "+_vm._s(_vm._f("numberSplit")(scope.item.Value3))+" ")])]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(scope.item.Labels[2])+": "+_vm._s(_vm._f("numberSplit")(scope.item.Value3))+" "+_vm._s(scope.item.Unit)+" ")])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }