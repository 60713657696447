<template>
  <div>
    <base-panel
      :title="pageType === 'all' ? 'Новости' : 'История обновлений'"
      :icon="UtilGetIcon('view-news', pageType)"
      class="news"
      :loading="loading"
    >
      <v-btn slot="action" @click="init" class="ml-4" title="Обновить" icon>
        <m-icon icon="tool-refresh" s16></m-icon>
      </v-btn>
      <v-list-item-group v-model="panel">
        <v-list-item
          v-for="item in data"
          :key="item.Id"
          @click="clickNew(item)"
          class="block__content"
        >
          <v-list-item-content>
            <v-list-item-title class="news__item-title">
              <div class="news__header">
                <strong>{{ item.Title }}</strong>
                <span>
                  {{ item.Version ? "Версия " + item.Version : "" }} от
                  {{ item.Period.DateIn.substr(0, 10) }}
                </span>
              </div>
              <span v-if="!item.Read" class="text-right mr-5 green--text">
                <b>Новая</b>
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </base-panel>
  </div>
</template>
<script>
import BasePanel from "@/layouts/BasePanel";
import NewsService from "@/services/NewsService";
import ListEditMixin from "../../mixins/ListEditMixin";

export default {
  name: "view-news",
  components: {
    BasePanel,
  },
  mixins: [ListEditMixin],
  data() {
    return {
      data: [],
      panel: null,
      loading: true,
    };
  },
  computed: {
    pageType() {
      return this.currentRouter.params?.type;
    },
  },
  created() {
    this.init();
  },
  methods: {
    clickNew(item) {
      if (!item.Read) this.$set(item, "Read", true);

      this.$store.dispatch("frame/RUN_COMPONENT", {
        componentName: "news/NewsEdit",
        params: {
          _objectId: item.Id,
          persistent: true,
          color: "#F7F7FA",
        },
      });
    },
    async init() {
      this.loading = true;
      let types = [1];
      if (this.pageType === "all") {
        types = [1];
      } else types = [2, 3];

      this.data = (await NewsService.get(types, false, false)).data;
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
.news {
  .v-expansion-panel:not(:first-child)::after {
    border-top: unset;
  }
  &__header {
    strong {
      margin-right: 2rem;
      font-weight: 600;
      font-size: 18px;
      line-height: 158%;
      letter-spacing: -0.01em;
    }
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 158%;
      letter-spacing: -0.01em;
      color: var(--v-text-base);
    }
  }

  .v-item-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
</style>
