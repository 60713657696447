<template>
  <base-popup
    v-model="dialog"
    :title="'Выбор чек-листов'"
    :icon="UtilGetIcon('view-CheckLists')"
    :width="'80vw'"
    :height="'90vh'"
    v-bind="$attrs"
    @save="save"
  >
    <template v-slot:activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <v-container class="d-flex" fill-height>
      <v-row class="d-flex fill-height">
        <v-col cols="6" class="fill-height">
          <base-panel title="Показатель оценки качества" class="fill-height">
            <base-search slot="action" v-model="searchAuditIndicators" />
            <base-table
              v-model="selectedAuditIndicators"
              :items="AuditIndicators"
              :search-text="searchAuditIndicators"
              :headers="[{ text: 'Название', value: 'Name' }]"
              hide-default-footer
              :showSettings="false"
            ></base-table>
          </base-panel>
        </v-col>
        <v-col cols="6" class="fill-height">
          <base-panel title="Чек-листы" class="fill-height">
            <base-search slot="action" v-model="searchCheckLists" />
            <base-table
              v-model="selectedCheckLists"
              :items="checkLists"
              :search-text="searchCheckLists"
              :headers="[{ text: 'Название', value: 'Name' }]"
              hide-default-footer
              :showSettings="false"
              :sort-by.sync="sortBy"
            ></base-table>
          </base-panel>
        </v-col>
      </v-row>
    </v-container>
  </base-popup>
</template>

<script>
import { mapActions } from "vuex";

import CheckListService from "@/services/CheckListService";

import BasePopup from "@/layouts/BasePopup.vue";

import BasePanel from "@/layouts/BasePanel.vue";
import BaseTable from "@/components/base/BaseTable.vue";
import BaseSearch from "@/components/base/BaseSearch.vue";

export default {
  name: "selectCheckLists",
  components: {
    BasePopup,

    BasePanel,
    BaseTable,
    BaseSearch,
  },
  props: {
    selectedList: { type: Array, default: () => [] },
  },

  data() {
    return {
      dialog: false,

      indicators: [],
      AuditIndicators: [],
      selectedAuditIndicators: [],
      searchAuditIndicators: "",

      checkLists: [],
      checkListsBase: [],
      selectedCheckLists: [],
      searchCheckLists: "",

      sortBy: ["Name"],
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.selectedCheckLists = this.selectedList;
        if (this.selectedList.length <= 0) {
          this.selectedAuditIndicators = [];
        }
      }
    },
    selectedAuditIndicators(indicators) {
      if (indicators.length > 0) {
        this.updateCheckLists(indicators.map((el) => el.Id));
      } else {
        this.checkLists = this.checkListsBase;
      }
    },
  },
  async created() {
    this.AuditIndicators = await this.LOAD_AUDIT_INDICATORS();
    const { data } = await CheckListService.get([], [], false, true);
    this.checkListsBase = data;
    this.checkLists = this.checkListsBase;
  },
  methods: {
    ...mapActions(["LOAD_AUDIT_INDICATORS"]),
    test(val) {
      console.log("selected yes", val);
    },

    updateCheckLists(indicatorsIds) {
      const res = [];
      for (const id of indicatorsIds) {
        for (const checkList of this.checkListsBase) {
          if (checkList.Indicators?.some((el) => el.Id === id)) {
            res.push(checkList);
          }
        }
      }
      this.checkLists = Array.from(new Set(res));
    },
    save() {
      this.$emit("input", this.selectedCheckLists);

      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
