<template>
  <div>
    <draggable-treeview
      v-bind="$attrs"
      v-on="$listeners"
      :item-text="itemText"
      :value="items"
      :opens.sync="open"
      open-on-click
      return-object
      class="block__content tree-view"
      expand-icon="$ToolArrowDown"
      :disabled="readOnly"
      :updateItems="updateItems"
      :pageEditName="pageEditName"
    >
      <template
        v-for="slot in Object.keys($scopedSlots).filter((e) => e !== 'label')"
        :slot="slot"
        slot-scope="scope"
      >
        <slot :name="slot" v-bind="scope" />
      </template>
      <template slot="label" slot-scope="scope">
        <div style="display: flex">
          <slot
            v-if="nodeEditing && nodeEditing.Id === scope.item.Id"
            name="input-edit"
            v-bind="{
              nodeEditing: nodeEditing,
              caption: caption,
              closeEdit: closeEdit,
              itemText: itemText,
              item: scope.item,
            }"
          >
            <v-text-field
              v-model="nodeEditing[itemText]"
              @click.stop
              :placeholder="'Введите' + ' ' + caption"
              v-focusInput:tree="closeEdit"
              dense
              background-color="transparent"
              class="base-tree-list-edit__field"
              hide-details
              solo
            ></v-text-field>
          </slot>
          <template v-else-if="!$scopedSlots['label']">
            {{ scope.item[itemText] }}
          </template>
          <slot v-else name="label" v-bind="scope" />

          <slot
            name="afterLabel"
            v-bind="scope"
            v-bind:nodeEditing="nodeEditing"
          />
        </div>
      </template>

      <template v-if="!readOnly" v-slot:append="{ item }">
        <div class="tree-view__actions-main">
          <div
            v-show="!nodeEditing || item.Id === nodeEditing.Id"
            class="tree-view__actions"
            :class="{
              'tree-view__actions_active':
                nodeEditing && nodeEditing.Id === item.Id,
            }"
          >
            <v-btn
              v-if="nodeEditing && nodeEditing.Id === item.Id && cancelEnabled"
              title="Отмена"
              icon
              @click.stop.prevent="nodeEditing = null"
            >
              <m-icon small icon="mdi-close"></m-icon>
            </v-btn>
            <v-btn
              v-if="showActions.indexOf('add') !== -1"
              v-show="!nodeEditing || nodeEditing.Id !== item.Id"
              @click.stop.prevent="add(item)"
              color="blue-grey"
              icon
              title="Добавить в узел"
            >
              <m-icon s16 icon="tool-add-viol"></m-icon>
            </v-btn>
            <v-btn
              v-if="showActions.indexOf('edit') !== -1"
              @click.stop.prevent="edit(item)"
              :color="
                nodeEditing && nodeEditing.Id === item.Id
                  ? 'success'
                  : 'primary'
              "
              icon
              title="Редактировать"
            >
              <m-icon
                s16
                small
                :icon="
                  nodeEditing && nodeEditing.Id === item.Id
                    ? 'mdi-check'
                    : 'tool-edit'
                "
              ></m-icon>
            </v-btn>
            <v-btn
              v-if="showActions.indexOf('delete') !== -1"
              @click.stop.prevent="del(item)"
              icon
              color="error"
              title="Удалить"
            >
              <m-icon s16 icon="tool-delete"></m-icon>
            </v-btn>
          </div>
        </div>
      </template>
    </draggable-treeview>
    <div style="height: 50px">
      <v-btn
        v-if="showAdd"
        @click="add(null)"
        color="primary"
        large
        fab
        :title="'Добавить ' + caption"
        dark
        style="position: fixed; bottom: 16px; right: 16px"
      >
        <m-icon icon="mdi-plus"></m-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import DraggableTreeview from "../../modules/DraggableTreeview";
import DataHelper from "../../utils/DataHelper";

export default {
  components: { DraggableTreeview },
  props: {
    caption: {
      type: String,
      default: "",
    },
    itemText: {
      type: String,
      default: "Text",
    },
    showActions: {
      type: Array,
      default: () => ["add", "edit", "delete"],
    },
    showAdd: Boolean,
    showEditPopup: Boolean,
    pageEditName: String,
    items: Array,

    readOnly: {
      type: Boolean,
      default: false,
    },
    customInplaceEdit: [Function],
    newObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    nodeEditing: null,
    nodeEditingOld: null,
    open: new Set(),
    cancelEnabled: true,
  }),
  watch: {
    items() {
      this.nodeEditing = null;
    },
  },
  methods: {
    async closeEdit(type) {
      if (type === 1) {
        this.edit(this.nodeEditing);
        return;
      }

      const d =
        this.nodeEditing[this.itemText] !== this.nodeEditingOld[this.itemText];
      if (!d) {
        this.nodeEditing = null;
      } else {
        if (
          await this.$confirm(`Вы внесли изменения. Сохранить ?`, {
            buttonFalseText: "Отмена",
            buttonTrueText: "Сохранить",
            buttonTrueColor: "primary",
          })
        ) {
          this.edit(this.nodeEditing);
        }
      }
    },

    // Синхронизации списочно с табличном представлением
    // Может обновлять только уже имеющиеся элемент
    updateItems(items) {
      items = this._.cloneDeep(items);
      if (items.length > 1) {
        throw new Error("Можно изменить только один элемент");
      }
      const item = items[0];
      if (item) {
        const oldItem = DataHelper.findTreeById(this.items, item.Id);
        let itemIndex = -1;
        const parent =
          oldItem.ParentId === 0
            ? this.items
            : DataHelper.findTreeById(this.items, oldItem.ParentId);
        const parentIsRoot = !parent.Children;

        if (parentIsRoot) {
          itemIndex = parent.findIndex((e) => e.Id === oldItem.Id);
          this.$set(parent, itemIndex, item);
        } else {
          itemIndex = parent.Children.findIndex((e) => e.Id === oldItem.Id);
          this.$set(parent.Children, itemIndex, item);
        }
      }
    },
    async del(item) {
      if (this.$listeners.customDelete) this.$listeners.customDelete(item);
      else {
        const parent = item.ParentId
          ? DataHelper.findTreeById(this.items, item.ParentId)?.Children
          : this.items;

        if (parent) {
          const index = parent.findIndex((e) => e.Id === item.Id);
          parent.splice(index, 1);
        }
        if (this.nodeEditing && this.nodeEditing.Id === item.Id)
          this.nodeEditing = null;
      }

      this.nodeEditing = null;
    },
    editById(id, cancelEnabled = true) {
      this.$nextTick(() => {
        this.cancelEnabled = cancelEnabled;
        const item = DataHelper.findTreeById(this.items, id);
        if (item) {
          this.nodeEditing = { ...item };
          this.nodeEditingOld = item;
        }
      });
    },
    async edit(item) {
      if (this.showEditPopup) {
        this.$emit("showEdit", item);
        return;
      }
      this.cancelEnabled = true;
      const itemEdit = {
        ...item,
      };
      if (!this.nodeEditing) {
        this.nodeEditing = itemEdit;
        this.nodeEditingOld = item;
      } else {
        if (this.customInplaceEdit) {
          const res = await this.customInplaceEdit(this.nodeEditing);

          if (!res) return;
          else {
            this.nodeEditing.Id = res.Id;
          }
        }

        const p = Object.keys(item);
        for (const i of p) {
          if (this.nodeEditingOld[i] !== this.nodeEditing[i]) {
            this.nodeEditingOld[i] = this.nodeEditing[i];
          }
        }

        this.nodeEditing = null;
      }
    },
    add(item) {
      this.cancelEnabled = false;
      const newItem = {
        Id: -Math.round(Math.random() * 100000),
        IntNum: (item ? item.Children.length : this.items.length) + 1,
        Children: [],
        ...this.newObject,
      };
      newItem[this.itemText] = "";
      newItem.ParentId = item?.Id;
      if (this.showEditPopup) {
        newItem.isNew = true;
        this.$emit("showEdit", newItem);
        return;
      }

      // Open
      this.open.add(newItem.ParentId);

      if (item) item.Children.push(newItem);
      else this.items.push(newItem);
      this.$nextTick(() => {
        this.nodeEditing = newItem;
        this.nodeEditingOld = newItem;
      });
    },
  },
};
</script>

<style lang="sass">
.tree-view
  border-bottom: none !important
  border-bottom-left-radius: 0 !important
  border-bottom-right-radius: 0 !important

  &__actions:not(&__actions_active)
    visibility: hidden
  &__add
    border-top-left-radius: 0 !important
    border-top-right-radius: 0 !important
    border-top: none !important
  &__actions-main
    height:36px

.v-treeview-node__root:hover .tree-view__actions
  visibility: visible

.base-tree-list-edit__field .v-input__slot
  padding-left: 0 !important
</style>
