<template>
  <v-layout>
    <base-list-view
      :title="'Шаблоны документов'"
      label-text-btn-create="Создать шаблон"
      label-text-search="Поиск шаблона документа"
      @onRefresh="init"
      @onDelete="del"
      :dataSource="dataSource"
      :headers="headers"
      :loading="loading"
      pageEditName="DocumentTemplateEdit"
      :exclude-settings-actions="['group']"
      :selectedItems.sync="selected"
      @toggle-select-all="selectAllToggle"
      :sort-by.sync="sortBy"
      :item-class="
        (item) => {
          return {
            disabled: item.IsSystem || item.Attribute.Disabled,
          };
        }
      "
      :showFilters="true"
      :options.sync="options"
      :server-items-length="totalCount"
      group-by="ActivitySection.Name"
      server-options
      show-groups
      :paramsHandleClick="{ type: 'template' }"
      :notShowActions="readonly ? ['add'] : []"
      :showDelete="!readonly"
      :notShowSelect="readonly"
    >
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          :value="isSelected"
          :ripple="false"
          :disabled="item.IsSystem || item.Attribute.Disabled"
          @input="select($event)"
        ></v-simple-checkbox>
      </template>
      <div row slot="action" class="align-center d-inline-flex">
        <label
          class="tasks__action-show-all v-label blue-grey--text mr-1"
          style="font-size: 14px"
        >
          Показать архивные
        </label>
        <v-checkbox
          v-model="showCompleted"
          @change="init"
          hide-details
        ></v-checkbox>
      </div>
    </base-list-view>
  </v-layout>
</template>

<script>
import Permissions from "../../data/permissions";
import ListEditMixin from "../../mixins/ListEditMixin";
import PaginationListMixin from "../../mixins/PaginationListMixin";
import DocumentTemplateService from "@/services/document/DocumentTemplateService";
import BaseListView from "../../layouts/BaseListView.vue";

export default {
  name: "view-DocumentTemplates",
  components: {
    BaseListView,
  },
  mixins: [ListEditMixin, PaginationListMixin],
  data: function () {
    return {
      apiService: DocumentTemplateService,
      showCompleted: false,
      loading: true,
      sortBy: ["Name"],
      headers: [
        {
          text: "Название",
          value: "Name",
          notGroup: true,
        },
        {
          text: "Тип",
          value: "DocumentType.Name",
          displayText: (e) => e,
          dataType: "object",
          width: "20%",
          dict: { serverName: "DocumentTemplate.DocumentType.Name" }, // Для фильтра. Загрузка словаря с сервера, с пагинацией
        },
        {
          text: "Раздел деятельности",
          value: "ActivitySection.Name",
          displayText: (e) => e,
          dataType: "object",
          dict: { serverName: "DocumentTemplate.Section.Name" }, // Для фильтра. Загрузка словаря с сервера, с пагинацией
        },
      ],
      dataSource: [],
      selected: [],
    };
  },
  computed: {
    _apiServiceParams() {
      return {
        type: "template",
        archive: this.showCompleted,
      };
    },
    pageType() {
      return this.currentRouter.params?.type;
    },
    readonly() {
      return !this.hasPermission(Permissions.DocumentTemplatesEdit);
    },
  },
  watch: {
    showCompleted: function (val) {
      if (val && !this.headers.find((e) => e.value === "Archived")) {
        this.headers.push({
          text: "Добавлено в архив",
          value: "Archived",
          dataType: "Date",
          notGroup: true,
        });
      } else if (!val && this.headers.find((e) => e.value === "Archived")) {
        this.headers = this.headers.filter((e) => e.value !== "Archived");
      }
    },
  },
  mounted() {},
  methods: {
    selectAllToggle() {
      const selectedCount = this.selected.length;
      const selectableCount = this.dataSource.filter(
        (item) => !item.IsSystem && !item.Attribute.Disabled
      ).length;

      if (selectedCount !== selectableCount) {
        this.selected = this.dataSource.filter(
          (item) => !item.IsSystem && !item.Attribute.Disabled
        );
      } else {
        this.selected = [];
      }
    },
    DataFromServerNormalize(data) {
      return data;
    },
    async loadingDataSource() {
      let res;
      try {
        this.loading = true;

        res = this.DataFromServerNormalize(
          await this.groupInit(this._apiServiceParams)
        );
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
      if (this.virtualPage !== 1) {
        this.dataSource = [...this.dataSource, ...res];
      } else this.dataSource = res;
    },
    async init() {
      this.resetPagination();
      this.loadingDataSource();
    },
  },
};
</script>
<style scoped></style>
