<template>
  <base-panel v-bind="$attrs" class="home__card-chart">
    <template v-if="loading || !isLoadData" slot="header">
      <v-toolbar-title style="width: 100%">
        <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
      </v-toolbar-title>
    </template>
    <div class="block__content home__card-body">
      <v-row v-if="loading || !isLoadData">
        <v-col>
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-col>
        <v-col>
          <v-skeleton-loader type="paragraph, chip"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <div style="width: 200px; margin: auto">
            <doughnut-chart
              :chart-data="doughnutData"
              :options="doughnutOptions"
              :common-percent="
                (data && data.Value ? Math.round(data.Value) : 0) + '%'
              "
              :title="`Оценено`"
            ></doughnut-chart>
          </div>
        </v-col>
        <v-col class="home__card-chart__content">
          <div class="home__card-chart__text">
            {{ data ? data.Description : "" }}
          </div>
        </v-col>
      </v-row>
    </div>
  </base-panel>
</template>
<script>
import BasePanel from "@/layouts/BasePanel";
import DoughnutChart from "@/views/analysis/DoughnutChart.js";
import DataHelper from "../../../utils/DataHelper";
import StatisticIndicatorHelper from "../../statisticIndicator/StatisticIndicatorHelper";
export default {
  name: "home-card-chart",
  components: {
    BasePanel,
    DoughnutChart,
  },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      data: null,
      loading: true,
      isLoadData: false,
      doughnutData: {
        datasets: [
          {
            data: [99, 1],
            backgroundColor: ["#BBC6F0", "#FFC805"],
            hoverOffset: 4,
          },
        ],
      },
      doughnutOptions: {
        tooltips: {
          enabled: false,
        },
      },
    };
  },
  watch: {
    loading: {
      immediate: true,
      handler() {
        if (!this.loading) this.init();
      },
    },
    "$parent.$parent.isInit": {
      immediate: true,
      handler(val) {
        if (!val) this.loading = true;
        else {
          this.loading = false;
        }
      },
    },
  },
  methods: {
    async init() {
      this.isLoadData = false;
      const year = DataHelper.today().getFullYear();
      const month = DataHelper.today().getMonth();
      const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
      const dates = [new Date(year, month, lastDayOfMonth)];

      this.data = (await this.params.load.get(dates)).data[0];
      this.doughnutData.datasets[0].data = [
        this.data.Value,
        100 - this.data.Value,
      ];

      this.doughnutData.datasets[0].backgroundColor = [
        StatisticIndicatorHelper.getColorHexByRefs(
          this.data.RefValues,
          this.data.Value,
          true
        ),
        "#BBC6F0",
      ];
      this.isLoadData = true;
    },
    handleMore() {
      this.$router.push({
        name: "AnalysisIndicators",
      });
    },
  },
};
</script>
<style lang="scss">
.home__card-chart {
  flex: 50%;
  padding: 6px 12px;

  &__content {
    align-self: center;
  }
  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 158%;
    /* or 22px */

    letter-spacing: -0.01em;

    color: var(--v-text-lighten1);
  }
  header .v-toolbar__title {
    font-weight: 700;
    font-size: 14px;
    line-height: 158%;

    color: var(--v-text-base);
  }
  &-body {
    padding: 0.8rem 1.5rem;
    background: var(--v-white-base);
  }
  .v-skeleton-loader__list-item,
  .v-skeleton-loader__actions,
  .v-skeleton-loader__list-item-avatar {
    background: var(--v-white-lighten1) !important;
  }
}
</style>
