import Vue from "vue";
import moment from "moment";
import EnumsHelper from "../utils/EnumsHelper";

Vue.filter("DateNear", (value) => {
  let date = null;
  const currentDate = moment();
  if (typeof value === "string") {
    date = moment(value, "DD.MM.YYYY HH:mm:ss");
  }

  if (date.clone().format("DD") === currentDate.clone().format("DD"))
    return date.format("Сегодня HH:mm");
  else if (
    date.clone().format("DD") ===
    currentDate.clone().subtract(1, "days").format("DD")
  ) {
    return date.format("Вчера HH:mm");
  }

  return date.format("DD.MM.YYYY HH:mm");
});

Vue.filter("EmployeePosition", (employee, position) => {
  if (position) return position.Name;
  if (employee && employee instanceof Object) {
    if (!employee.WorkPlaces.length && employee.Position) {
      return employee?.Position?.Name;
    }

    return employee.WorkPlaces.length
      ? employee.WorkPlaces[0]?.Position?.Name
      : "";
  } else return null;
});

Vue.filter("EmployeePlace", (employee, place) => {
  if (place) return place.Name;
  if (employee && employee instanceof Object) {
    if (!employee.WorkPlaces.length && employee.Place) {
      return employee?.Place?.Name;
    }

    return employee.WorkPlaces.length
      ? employee.WorkPlaces[0]?.Place?.Name
      : "";
  } else return null;
});

Vue.filter("PersonShortName", (person) => {
  if (person && person instanceof Object && person.length === undefined) {
    return (
      person.LastName +
      " " +
      (person.FirstName ? person.FirstName.charAt(0) + "." : "") +
      (person.MiddleName ? person.MiddleName.charAt(0) + "." : "")
    );
  } else return null;
});

Vue.filter("PersonFirstAndMiddleName", (person) => {
  if (person && person instanceof Object && person.length === undefined) {
    return (
      (person.FirstName ? person.FirstName : "") +
      " " +
      (person.MiddleName ? person.MiddleName : "")
    );
  } else return null;
});

Vue.filter("PersonInitials", (person) => {
  if (person && person instanceof Object && person.length === undefined) {
    return (
      (person.LastName ? person.LastName.charAt(0) : "") +
      (person.FirstName ? person.FirstName.charAt(0) : "")
    );
  } else return null;
});

Vue.filter("DateShort", (value) => {
  if (typeof value === "string" && value.length >= 10) {
    const dateParts = value.substr(0, 10).split(".");
    return new Date(
      +dateParts[2],
      dateParts[1] - 1,
      +dateParts[0]
    ).toLocaleDateString();
  }
  if (typeof value === "number") return new Date(value).toLocaleDateString();
  return value?.toLocaleDateString();
});

Vue.filter("DateTime", (value) => {
  if (typeof value === "string" && value.length >= 16) {
    const dateTimeParts = value.split(" ");
    const dateParts = dateTimeParts[0].split(".");
    const timeParts = dateTimeParts[1].split(":");
    return (
      new Date(
        +dateParts[2],
        dateParts[1] - 1,
        +dateParts[0],
        +timeParts[0],
        +timeParts[1]
      ).toLocaleDateString() +
      " " +
      new Date(
        +dateParts[2],
        dateParts[1] - 1,
        +dateParts[0],
        +timeParts[0],
        +timeParts[1]
      ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
    );
  }
  if (typeof value === "number") return new Date(value).toLocaleString();
  return value?.toLocaleString();
});

Vue.filter("DateOnly", (value) => {
  if (typeof value === "string") {
    return value.substring(0, 10);
  }
  return value;
});

Vue.filter("DateShort_MYYYY", (value) => {
  if (typeof value === "string" && value.length >= 10) {
    const dateParts = value.substr(0, 10).split(".");
    const date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return `${date.toLocaleDateString("default", {
      month: "long",
    })} ${dateParts[2]}`;
  } else return null;
});

// Разные
Vue.filter("EmployeeActive", function (employees) {
  if (!employees) return [];
  else {
    let value = this.$attrs?.value || [];
    if (
      typeof value === "object" &&
      !(value === null) &&
      !Array.isArray(value)
    ) {
      value = [value];
    }

    return employees.filter((el) => {
      return (
        el?.User?.IsActive ||
        value?.some((valueEl) => {
          return el.Id === valueEl.Id;
        })
      );
    });
  }
});

Vue.filter("PeriodFormat", (DateIn, DateOut) => {
  DateIn = DateIn ? DateIn.substr(0, 10) : "";
  DateOut = DateOut ? DateOut.substr(0, 10) : "";
  const PeriodFormat = DateIn + (DateOut ? " - " : "") + DateOut;
  return PeriodFormat;
});

// Convert: 1000 - 1 000
Vue.filter("numberSplit", (str, noText = "Нет данных") => {
  if (!str && str !== 0) return noText;
  str += "";

  const f = str.split(".");
  const s = f[0].length;
  const chars = f[0].split("");
  const strWithSpaces = chars.reduceRight((acc, char, i) => {
    const spaceOrNothing = (s - i) % 3 === 0 ? " " : "";
    return spaceOrNothing + char + acc;
  }, "");
  let r = strWithSpaces[0] === " " ? strWithSpaces.slice(1) : strWithSpaces;
  r += f.length === 2 ? "." + f[1] : "";
  return r;
});

Vue.filter("getEnums", (value, name) => {
  return EnumsHelper.getEnums(value, name);
});

Vue.filter("getEnumsValues", (name) => {
  return EnumsHelper.getEnumsValues(name);
});
