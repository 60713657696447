<template>
  <v-card
    :class="`d-flex flex-column flex-grow-1 analytic-card py-4 px-8 ${
      small ? 'analytic-card--small' : ''
    }`"
    elevation="0"
  >
    <v-skeleton-loader type="heading" v-if="loading" />
    <header class="d-flex align-center mb-1" v-else>
      <m-icon v-if="icon !== null" class="mr-4" :icon="icon" active />
      <h3 class="text-uppercase analytic-card__title">{{ title }}</h3>
    </header>

    <template v-if="cardType === 'list'">
      <template v-if="loading">
        <template v-if="small">
          <v-skeleton-loader type="list-item" v-for="i in 2" :key="i" />
        </template>
        <template v-else>
          <v-skeleton-loader type="list-item" v-for="i in 6" :key="i" />
        </template>
      </template>
      <ul class="pt-6 px-0 d-flex flex-column" v-else>
        <li
          v-for="(field, key) in cardData"
          :key="key"
          :class="{
            'd-flex justify-space-between align-start': true,
            'error--text text--lighten-1':
              ['Expired', 'TasksExpired', 'Negative', 'ExpiredValue'].indexOf(
                key
              ) !== -1 && field.count > 0,
            'success--text text--lighten-1':
              ['Done', 'TasksDone', 'Positive', 'Percent'].indexOf(key) !==
                -1 && field.count > 0,
            'pt-4':
              ['NonCompliances', 'TasksTotal', 'ExpiredValue'].indexOf(key) !==
              -1,
          }"
        >
          <span>{{ field.title }}</span>
          <span>{{ key === "Percent" ? `${field.count}%` : field.count }}</span>
        </li>
      </ul>
    </template>
    <template v-if="cardType === 'chart'">
      <template v-if="loading">
        <v-skeleton-loader type="list-item" />
        <div class="d-flex align-center" style="gap: 20px">
          <v-skeleton-loader type="image" width="50%" />
          <v-skeleton-loader type="paragraph" width="50%" />
        </div>
      </template>
      <div v-else>
        <p class="d-flex flex-column mb-0 pt-2">
          На {{ params.date[0] | DateOnly }}
        </p>
        <div class="pt-6 analytic-card__chart">
          <div style="width: 150px; height: auto">
            <doughnut-chart
              :chart-data="doughnutData"
              :options="doughnutOptions"
              :common-percent="
                (cardData && cardData.Value ? Math.round(cardData.Value) : 0) +
                '%'
              "
              :font-size="80"
            />
          </div>
          <p
            class="mb-0 text-center font-weight-bold analytic-card__description"
          >
            {{ cardData.Description }}
          </p>
        </div>
      </div>
    </template>
  </v-card>
</template>

<script>
import DoughnutChart from "@/views/analysis/DoughnutChart.js";

export default {
  name: "AnalyticCard",
  components: {
    DoughnutChart,
  },
  props: {
    cardType: {
      type: String,
      required: true,
      validator(value) {
        return ["list", "chart"].indexOf(value) !== -1;
      },
    },
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
      validator: function (value) {
        return typeof value === "string" && value.length > 1;
      },
    },
    params: {
      type: Object,
      default: () => {},
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      cardData: {},
      doughnutData: {
        datasets: [
          {
            data: [99, 1],
            backgroundColor: ["#0CA85D", "#FB0404"],
            hoverOffset: 4,
          },
        ],
      },
      doughnutOptions: {
        tooltips: {
          enabled: false,
        },
        cutoutPercentage: 75,
        rotation: 0.25 * Math.PI,
      },
    };
  },
  watch: {
    params() {
      this.loading = true;
      this.init();
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      try {
        const { data } = await this.params.request;

        if (this.cardType === "list") {
          this.cardData = this.generateCardListData(data, this.params.name);
        }

        if (this.cardType === "chart") {
          this.cardData = data[0];
          this.doughnutData.datasets[0].data = [
            this.cardData.Value,
            100 - this.cardData.Value,
          ];
        }
        this.loading = false;
      } catch (e) {
        console.debug(e);
      }
    },

    generateCardListData(data, listType) {
      const res = {};
      const listOfTitles = this.generateListTitles(listType);
      for (const title of Object.keys(listOfTitles)) {
        res[title] = { title: listOfTitles[title], count: data[title] };
      }
      return res;
    },
    generateListTitles(type) {
      switch (type) {
        case "planned":
          return {
            Total: "Запланировано проверок",
            Done: "Проведено плановых проверок",
            Expired: "Просроченные проверки",
            NonCompliances: "Выявлено несоответствий",
            TasksTotal: "Разработано мероприятий",
            TasksDone: "Выполнено мероприятий",
            TasksExpired: "Просрочено выполнение мероприятий",
          };
        case "target":
          return {
            Done: "Проведено целевых проверок",
            NonCompliances: "Выявлено несоответствий",
            TasksTotal: "Разработано мероприятий",
            TasksDone: "Выполнено мероприятий",
            TasksExpired: "Просрочено выполнение мероприятий",
          };
        case "unwished":
          return {
            Total: "Зарегистрировано НС",
            Done: "Разобраны и завершены НС",
            TasksTotal: "Создано мероприятий",
            TasksDone: "Выполнено мероприятий",
            TasksExpired: "Просрочено выполнение мероприятий",
          };
        case "statistic":
          return {
            Total: "Количество собираемых показателей",
            Positive: "Положительная динамика",
            Negative: "Отрицательная динамика",
            Unchanged: "Без динамики",
            ExpiredValue: "Не введены в срок значения показателей",
          };
        case "documents":
          return {
            Total: "Создано документов",
          };
        case "courses":
          return {
            Total: "Создано учебных курсов",
            Percent: "Ознакомились",
          };
        default:
          return {
            Default: "Нет данных",
          };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.analytic-card {
  grid-column: span 4;
  grid-row: span 2;
  border-radius: 10px;
  border: 1px solid var(--v-blue-grey-lighten3);

  &--small {
    grid-column: 1 / span 4;
    grid-row: 3;
    & + & {
      grid-column: 1 / span 4;
      grid-row: 4;

      @media (width <= 1775px) {
        grid-column: 5 / span 4;
        grid-row: 5;
      }

      @media (width <= 1280px) {
        grid-column: 1 / span 4;
        grid-row: 11;
      }
    }

    @media (width <= 1775px) {
      grid-column: 5 / span 4;
      grid-row: 6;
    }

    @media (width <= 1280px) {
      grid-column: 1 / span 4;
      grid-row: 12;
    }
  }

  .control-activities &__title {
    font-weight: 500;
    font-size: 16px;
  }

  ul {
    gap: 5px;
    list-style-type: none;
    color: var(--v-text-lighten1);
  }

  &__description {
    width: 240px;

    @media (width <= 1775px) {
      width: 400px;
    }
  }

  &__chart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    @media (width <= 1280px) {
      justify-content: center;
    }
  }

  @media (width <= 1280px) {
    grid-column: 1 / span 4;
  }
}
</style>
