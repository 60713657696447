var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-popup',{attrs:{"title":"Настройка группировки","persistent":false,"onlyClose":""},scopedSlots:_vm._u([{key:"activator",fn:function(scope){return [_vm._t("activator",null,null,scope)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('template',{slot:"actions"},[_c('v-btn',{attrs:{"dark":"","color":"primary","outlined":""},on:{"click":_vm.resetHeaders}},[_vm._v(" Сбросить группировку ")])],1),(_vm.items)?_c('base-table',{staticClass:"mt-3",attrs:{"item-key":"value","hide-default-footer":"","headers":_vm.headers,"items":_vm.items.filter((e) => e.value !== 'actions' && !e.notGroup),"showSettings":false,"notShowSelect":""},scopedSlots:_vm._u([{key:"body",fn:function(scope){return [_c('tbody',_vm._l((scope.items),function(item){return _c('tr',{key:item.value},[_c('td',[_c('v-simple-checkbox',{attrs:{"value":_vm.selected &&
                _vm.selected.value !== null &&
                item.value === _vm.selected.value,"ripple":false},on:{"input":function($event){$event ? (_vm.selected = item) : (_vm.selected = null)}}})],1),_c('td',[_vm._v(_vm._s(item.text))]),_c('td',{staticClass:"text-no-wrap"},[(
                _vm.selected &&
                _vm.selected.value !== null &&
                item.value === _vm.selected.value
              )?_c('v-switch',{attrs:{"label":_vm.selected.showGroupName ? 'Показывать' : 'Не показывать'},model:{value:(_vm.selected.showGroupName),callback:function ($$v) {_vm.$set(_vm.selected, "showGroupName", $$v)},expression:"selected.showGroupName"}}):_c('v-switch',{attrs:{"disabled":""}})],1)])}),0)]}}],null,false,54160157)}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }