var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"todo-list pt-13"},[_c('v-tabs',[_c('h2',{staticClass:"tabs-title pl-4 pr-11"},[_vm._v("СПИСОК ДЕЛ")]),_c('v-tab',{on:{"click":function($event){_vm.getParams.viewMy = true}}},[_vm._v("Мои")]),_c('v-tab',{on:{"click":function($event){_vm.getParams.viewMy = false}}},[_vm._v("Все")])],1),_c('div',{staticClass:"d-flex flex-wrap justify-space-between pt-5 todo-list__wrapper"},[(_vm.isShow(4))?_c('card',{attrs:{"title":"ПРОВЕРКИ","icon":_vm.UtilGetIcon('view-AuditOperations'),"params":_vm.auditConfig,"getParams":_vm.hasPermission(_vm.Permissions.AuditOperationViewAll)
          ? _vm.getParams
          : { viewMy: true }},scopedSlots:_vm._u([{key:"item.Status",fn:function({ item }){return [_c('span',[_c('span',{class:_vm.auditStatus(item.Status)},[_vm._v(" "+_vm._s(_vm._f("getEnums")(item.Status,"AuditOperationStatus"))+" ")])])]}}],null,false,1853627689)}):_vm._e(),(_vm.isShow(1))?_c('card',{attrs:{"title":"ЗАДАЧИ","icon":_vm.UtilGetIcon('view-Tasks'),"params":_vm.taskConfig,"getParams":_vm.hasPermission(_vm.Permissions.TasksViewAll) ? _vm.getParams : { viewMy: true }},scopedSlots:_vm._u([{key:"item.PlannedPeriod.DateOut",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({domProps:{"innerHTML":_vm._s(_vm.taskDate(item))}},'span',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.taskStatus(item)))])])]}}],null,false,408733359)}):_vm._e(),(_vm.isShow(2))?_c('card',{attrs:{"title":"ПЛАНЫ И ПРОЕКТЫ","icon":_vm.UtilGetIcon('view-Projects', 'all'),"params":_vm.data.project,"getParams":_vm.getParams},scopedSlots:_vm._u([{key:"item.DoneTaskPercent",fn:function({ item }){return [_c('div',{class:{
            'd-flex justify-end': true,
            'text-success': item.DoneTaskPercent === 100,
          }},[_vm._v(" "+_vm._s(parseFloat(parseFloat(item.DoneTaskPercent).toFixed(2)) + "%")+" ("+_vm._s(item.Completed)+" из "+_vm._s(item.Total)+") ")])]}}],null,false,1694453368)},[_c('template',{slot:"cardAction"},[_c('v-row',{staticClass:"mt-1"},[_c('v-col',[_c('v-btn',{attrs:{"block":"","depressed":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'Plans',
                  params: { type: 'plan' },
                })}}},[_vm._v(" Планы ")])],1),_c('v-col',[_c('v-btn',{attrs:{"block":"","depressed":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'Projects',
                  params: { type: 'project' },
                })}}},[_vm._v(" Проекты ")])],1)],1)],1)],2):_vm._e(),(_vm.isShow(6))?_c('card',{attrs:{"title":"НЕЖЕЛАТЕЛЬНЫЕ СОБЫТИЯ","icon":_vm.UtilGetIcon('view-UnwishedEvents'),"params":_vm.data.unwishedEvent,"getParams":_vm.getParams},scopedSlots:_vm._u([{key:"item.Status",fn:function({ item }){return [_c('span',{class:item.Done ? '' : 'red--text'},[_vm._v(" "+_vm._s(item.Done ? "Закрыто" : "Новое")+" ")])]}}],null,false,41823771)}):_vm._e(),(_vm.isShow(3))?_c('card',{attrs:{"title":"ДОКУМЕНТЫ","icon":_vm.UtilGetIcon('view-LocalDocuments'),"params":_vm.documentConfig,"getParams":_vm.hasPermission(_vm.Permissions.OrganizationDocumentViewAll)
          ? _vm.getParams
          : { viewMy: true }},scopedSlots:_vm._u([{key:"item.Status",fn:function({ item }){return [_c('span',{class:_vm.documentStatus(item.Status)},[_vm._v(" "+_vm._s(_vm._f("getEnums")(item.Status,"OrganizationDocumentStatus"))+" ")])]}}],null,false,1772844636)}):_vm._e(),(_vm.isShow(7))?_c('card',{attrs:{"title":"ОБУЧЕНИЕ","icon":_vm.UtilGetIcon(
          _vm.getParams.viewMy ? 'knowledgebase-my' : 'knowledgebase-all'
        ),"params":_vm.courseConfig,"getParams":_vm.hasPermission(_vm.Permissions.KnowledgeBaseAllCourseReady)
          ? _vm.getParams
          : { viewMy: true }},scopedSlots:_vm._u([{key:"item.DoneTaskPercent",fn:function({ item }){return [_c('div',{class:{
            'd-flex justify-end': true,
            'text-success': item.Status === 3,
          }},[_vm._v(" "+_vm._s(!item.Total ? "0%" : Math.round((item.Completed / item.Total) * 100) + "%")+" ("+_vm._s(item.Completed)+" из "+_vm._s(item.Total)+") ")])]}},{key:"item.DoneEmployeesCount",fn:function({ item }){return [(item.AllEmployeesCount > 0)?_c('div',{class:{
            'd-flex justify-end': true,
            'text-success': item.Status === 4,
          }},[_vm._v(" Прошли "+_vm._s(item.DoneEmployeesCount)+" из "+_vm._s(item.AllEmployeesCount)+" ")]):_vm._e()]}}],null,false,2711771962)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }